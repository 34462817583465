<div class="well"> {{'Tu môžete prideliť ďalším už registrovaným používateľom služby VinLink prístup k dátam z tohto vinohradu. Dodatočne pridaní používatelia nebudú môcť zadávať očakávané fenofázy a uskutočnené postreky.' | translate}} <br>
  <b>{{"Do textového poľa napíšte emailovú adresu používateľa, ktorý je už zaregistrovaný v aplikácii VinLink. Potom kliknite na tlačidlo 'Pridať používateľa'" | translate}}</b>
</div>
<div class="col-9" style="padding-left: 0;">
  <app-loading [@inOutAnimation] *ngIf="vineyardService.isLoading">{{'Loading Vineyards' | translate}}</app-loading>
  <!-- <span *ngFor="let reader of userGroup | keyvalue">{{reader.key}} - {{reader.value}},</span> -->
  <mat-form-field class="example-chip-list" appearance="fill">
    <mat-label>{{'Users' | translate}}</mat-label>
    <mat-chip-list #chipList>
      <mat-chip *ngFor="let reader of userGroup | keyvalue" [removable]="true" (removed)="remove(reader.key)" id="{{reader.key}}">
        <b *ngIf="reader.key === user.uid" style="margin-right: 7px;">ADMIN: </b>{{reader.value}} <button matChipRemove style="position:relative;" *ngIf="reader.key !== user.uid">
          <mat-icon style="position: absolute;top: -5px;right: -6px;">cancel</mat-icon>
        </button>
      </mat-chip>
      <input matInput placeholder="{{'E-mail address' | translate}}" type="email" name="email" [(ngModel)]="newEmail" [matChipInputFor]="chipList" />
    </mat-chip-list>
  </mat-form-field>
  <mat-spinner *ngIf="loading" [diameter]="50">
  </mat-spinner>
  <button class="btn btn-primary" type="button" (click)="addUser(newEmail)">{{'Add User' | translate}}</button>
</div>
<!-- <button class="btn btn-primary" type="button" (click)="getUserByEmail(newEmail)">get user data from email</button> -->