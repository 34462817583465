import { Component, OnInit } from "@angular/core";
import { trigger, transition, style, animate } from "@angular/animations";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { VineyardService } from "app/_services/vineyard.service";
import { Vineyard } from "app/_classes/vineyard";
import { MapService } from "app/_services/map.service";
import { TranslateService } from "@ngx-translate/core";
import { untilDestroyed } from "ngx-take-until-destroy";
import { getYear } from "date-fns";
import { AngularFireAuth } from '@angular/fire/auth';
import { WeatherService } from 'app/_services/weather.service';
import { GalatiService } from 'app/_services/galati.service';
declare var L: any;
declare var SMap: any;
@Component({
  selector: "app-vineyard-detail",
  templateUrl: "./vineyard-detail.component.html",
  styleUrls: ["./vineyard-detail.component.scss"],
  animations: [
    trigger("inOutAnimation", [
      transition(":enter", [
        style({ opacity: 0 }),
        animate(".25s ease-out", style({ opacity: 1 })),
      ]),
      transition(":leave", [
        style({ opacity: 1 }),
        animate(".25s ease-in", style({ opacity: 0 })),
      ]),
    ]),
  ],
})
export class VineyardDetailComponent implements OnInit {
  ActivationKey: any;
  vineyard: Vineyard;
  nav = "situation";
  nav1: string;
  nav2: string;
  nav3: string;
  nav4: string;
  nav5: string;
  nav6: string;
  navTabs = ["situation", "infection", "charts", "current", "info", "calendar", "forecast", "users"];
  map;
  rainYear;

  navTabClick(navHash: string) {
  }

  onMapReady(e) {
    e.setView([this.vineyard.lat, this.vineyard.lng], 18);
    this.mapService.mapInit(e);
    this.map = e;
    this.map.removeControl(this.map.zoomControl);
    const marker = new L.marker([this.vineyard.lat, this.vineyard.lng], {
      icon: this.mapService.stationIcon,
    })
      .addTo(this.map)
      .bindPopup(
        `${this.translate.instant("device.Device")}: ${this.vineyard.Alias}`
      )
      .openPopup();
  }

  constructor(
    public vineyardService: VineyardService,
    private _Activatedroute: ActivatedRoute,
    private router: Router,
    public mapService: MapService,
    public translate: TranslateService,
    public fireAuth: AngularFireAuth,
    public weatherService: WeatherService, public galatiService: GalatiService
  ) {
    this.router.events.subscribe((val) => {
      this.ActivationKey = this._Activatedroute.snapshot.params["ActivationKey"];
      window.scrollTo(0, 0);
      this.vineyardService.myVineyardsBehSub.subscribe(res => {
        if (res) {
          this.vineyard = res.filter(
            (st) => st.ActivationKey === this.ActivationKey
          )[0];
        }
      })
    });
  }

  ngOnInit() {
    this.ActivationKey = this._Activatedroute.snapshot.params["ActivationKey"];
    this._Activatedroute.queryParams.subscribe((queryParam) => {

      if (this.navTabs.includes(queryParam.tab)) {
        this.nav = queryParam.tab;
      } else {
        this.nav = "situation";
      }
    });

    this.vineyardService.myVineyardsBehSub.subscribe(res => {
      if (res) {
        this.vineyard = res.filter(
          (st) => st.ActivationKey === this.ActivationKey
        )[0];

        this.galatiService.getSeason(this.vineyard);
      }
    })
  }
  ngOnDestroy() {
    // To protect you, we'll throw an error if it doesn't exist.
  }
}
