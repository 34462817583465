import {
  Component,
  OnInit,
  ElementRef,
  Output,
  EventEmitter,
  Input,
} from "@angular/core";
import { ROUTES, SidebarComponent } from "../sidebar/sidebar.component";
import { Location } from "@angular/common";
import { Router } from "@angular/router";
import { AuthenticationService } from "../../_services/authentication.service";
import { GlobalService } from "../../_services/global.service";
import { VineyardService } from "../../_services/vineyard.service";
import { untilDestroyed } from "ngx-take-until-destroy";
import { AngularFireAuth } from "@angular/fire/auth";
import { HttpClient } from "@angular/common/http";
import { TranslateService } from "@ngx-translate/core";
import { NotificationsService } from "app/_services/notifications.service";
import { AngularFirestore } from '@angular/fire/firestore';

@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.scss"],
})
export class NavbarComponent implements OnInit {
  @Input() loginPageAction: string;
  @Output() setLoginAction: EventEmitter<string> = new EventEmitter<string>();

  private listTitles: any[];
  location: Location;
  mobile_menu_visible: any = 0;
  private toggleButton: any;
  private sidebarVisible: boolean;
  userID: string;
  isLoggedIn: boolean;
  userMail: string;
  username: string;
  password: string;


  loginActionClick(action) {
    this.setLoginAction.emit(action);
  }

  constructor(
    location: Location,
    private element: ElementRef,
    private router: Router,
    public authService: AuthenticationService,
    public global: GlobalService,
    public ss: VineyardService,
    public afs: AngularFirestore
  ) {
    this.location = location;
    this.sidebarVisible = false;
  }

  ngOnInit() {
    this.authService
      .isLoggedIn()
      .pipe(untilDestroyed(this))
      .subscribe((res) => (this.isLoggedIn = res));

    this.listTitles = ROUTES.filter((listTitle) => listTitle);
    const navbar: HTMLElement = this.element.nativeElement;
    this.toggleButton = navbar.getElementsByClassName("navbar-toggler")[0];
    this.router.events.pipe(untilDestroyed(this)).subscribe((event) => {
      this.sidebarClose();
      const $layer: any = document.getElementsByClassName("close-layer")[0];
      if ($layer) {
        $layer.remove();
        this.mobile_menu_visible = 0;
      }
    });
  }

  sidebarOpen() {
    const toggleButton = this.toggleButton;
    const body = document.getElementsByTagName("body")[0];
    setTimeout(function () {
      toggleButton.classList.add("toggled");
    }, 500);

    body.classList.add("nav-open");

    this.sidebarVisible = true;
  }
  sidebarClose() {
    const body = document.getElementsByTagName("body")[0];
    this.toggleButton.classList.remove("toggled");
    this.sidebarVisible = false;
    body.classList.remove("nav-open");
  }
  sidebarToggle() {
    // const toggleButton = this.toggleButton;
    // const body = document.getElementsByTagName('body')[0];
    const $toggle = document.getElementsByClassName("navbar-toggler")[0];
    const body = document.getElementsByTagName("body")[0];
    const $layer = document.createElement("div");

    if (this.sidebarVisible === false) {
      this.sidebarOpen();
    } else {
      this.sidebarClose();
    }

    if (this.mobile_menu_visible === 1) {
      body.classList.remove("nav-open");
      if ($layer) {
        $layer.remove();
      }
      setTimeout(function () {
        $toggle.classList.remove("toggled");
      }, 400);

      this.mobile_menu_visible = 0;
    } else {
      setTimeout(function () {
        $toggle.classList.add("toggled");
      }, 430);

      $layer.setAttribute("class", "close-layer");

      if (body.querySelectorAll(".main-panel")) {
        document.getElementsByClassName("main-panel")[0].appendChild($layer);
      } else if (body.classList.contains("off-canvas-sidebar")) {
        document
          .getElementsByClassName("wrapper-full-page")[0]
          .appendChild($layer);
      }

      setTimeout(function () {
        $layer.classList.add("visible");
      }, 100);

      $layer.onclick = function () {
        // asign a function
        body.classList.remove("nav-open");
        this.mobile_menu_visible = 0;
        $layer.classList.remove("visible");
        setTimeout(function () {
          $layer.remove();
          $toggle.classList.remove("toggled");
        }, 400);
      }.bind(this);

      body.classList.add("nav-open");
      this.mobile_menu_visible = 1;
    }
  }
  getTitle() {
    let pageTitle = this.location.prepareExternalUrl(this.location.path());
    if (pageTitle.charAt(0) === "#") {
      pageTitle = pageTitle.slice(1);
    }
    pageTitle = pageTitle.split("/").pop();

    for (let item = 0; item < this.listTitles.length; item++) {
      if (this.listTitles[item].path === pageTitle) {
        return this.listTitles[item].title;
      }
    }
    return pageTitle;
  }
  ngOnDestroy() {
    // To protect you, we'll throw an error if it doesn't exist.
  }
}
