<footer class="footer ">
  <div class="container-fluid">
    <!-- <nav class="pull-left">
      <ul>
        <li><a href="#"> VinLink </a></li>
        <li><a href="/about-us"> About Us </a></li>
        <li><a href="#"> Blog </a></li>
        <li><a href="#"> Licenses </a></li>
      </ul>
    </nav> --> <div class="pull-left">
      <div class="copyright">
        {{copyright}}
      </div>
    </div>
    <div class="copyright pull-right">
      <div class="appVersion">
        {{global.appVersion}} / {{firestoreAppVersion}} <mat-icon class="version-icon" *ngIf="global.appVersion == firestoreAppVersion">check</mat-icon>
        <mat-icon *ngIf="global.appVersion < firestoreAppVersion" (click)="refresh()" class="version-icon">refresh</mat-icon>
      </div>
    </div>
  </div>
</footer>