
<div class="timeframe-btns">
  <div class="btn btn-outline submit-btn timeframe-btn" [routerLink]="['/vineyards', vineyard.ActivationKey]" [queryParams]="{tab:'situation',timeFrame:'today'}" [ngClass]="{'active': timeFrame=='today'}" (click)="changeTimeFrame('today')">{{"Dnešná denná predpoveď" | translate}}</div>
  <div class="btn btn-outline submit-btn timeframe-btn" [ngClass]="{'active': timeFrame=='thisWeek'}" [routerLink]="['/vineyards', vineyard.ActivationKey]" [queryParams]="{tab:'situation',timeFrame:'thisWeek'}" (click)="changeTimeFrame('thisWeek')">{{"Tento týždeň" | translate}}</div>
  <div class="btn btn-outline submit-btn timeframe-btn" [ngClass]="{'active': timeFrame=='nextWeek'}" [routerLink]="['/vineyards', vineyard.ActivationKey]" [queryParams]="{tab:'situation',timeFrame:'nextWeek'}" (click)="changeTimeFrame('nextWeek')">{{"Budúci týždeň" | translate}}</div>
  <div class="btn btn-outline submit-btn timeframe-btn" [ngClass]="{'active': timeFrame=='history'}" [routerLink]="['/vineyards', vineyard.ActivationKey]" [queryParams]="{tab:'situation',timeFrame:'history'}" (click)="changeTimeFrame('history')">{{"História" | translate}}</div>
</div>
<h2 *ngIf="timeFrame=='today'">{{'Dnešná denná predpoveď'| translate}}</h2>
<h2 *ngIf="timeFrame=='thisWeek'">{{"Tento týždeň"| translate}}</h2>
<h2 *ngIf="timeFrame=='nextWeek'">{{"Budúci týždeň"| translate}}</h2>
<h2 *ngIf="timeFrame=='history'">{{"História"| translate}}</h2>
<!-- 
 -->

<div *ngIf="timeFrame === 'today'">
  <div *ngIf="vineyard.galatiSeason.season.at(-1).weekNum !== vineyardService.weekOfYear">{{'Zobrazenie infekčného tlaku a odporúčaní je možné až po zadaní fenofázy a vykonaných postrekov za všetky predchádzajúce týždne.' | translate}} <button class="btn btn-primary" type="button" (click)="globalService.phenoDialog.next(vineyard)" *ngIf="vineyard.ownerUID === authService.user.uid">{{"Zadať"|translate}}</button></div>
  <!-- 
                                        \ -->
                                        
  <app-situation-card [week]="dailyWeek" [vineyard]="vineyard" [previousWeek]="dailyWeek.previousWeek" *ngIf="vineyard.galatiSeason.season.at(-1).weekNum == vineyardService.weekOfYear && dailyWeek " [timeFrame]="'today'"></app-situation-card>

</div>
<div *ngIf="timeFrame === 'thisWeek'">
  <div *ngIf="!currentWeekData">{{'Zobrazenie infekčného tlaku a odporúčaní je možné až po zadaní fenofázy a vykonaných postrekov za všetky predchádzajúce týždne.' | translate}} <button class="btn btn-primary" type="button" (click)="globalService.phenoDialog.next(vineyard)" *ngIf="vineyard.ownerUID === authService.user.uid">{{"Zadať"|translate}}</button></div>
  <app-situation-card *ngIf="currentWeekData" [week]="currentWeekData" [vineyard]="vineyard"></app-situation-card>
</div>


<div *ngIf="timeFrame === 'nextWeek'" style="display: flex; flex-direction: column-reverse;">
<div *ngIf="!nextWeek">{{'Zobrazenie infekčného tlaku a odporúčaní je možné až po zadaní fenofázy a vykonaných postrekov za všetky predchádzajúce týždne.' | translate}} <button class="btn btn-primary" type="button" (click)="globalService.phenoDialog.next(vineyard)" *ngIf="vineyard.ownerUID === authService.user.uid">{{"Zadať"|translate}}</button></div>
<app-situation-card *ngIf="nextWeek" [week]="nextWeek" [vineyard]="vineyard" [previousWeek]="nextWeek.previousWeek" [timeFrame]="'nextWeek'" style="margin-top:0;"></app-situation-card>
</div>

<div *ngIf="timeFrame === 'history'">
  <div *ngFor="let historyWeek of vineyard.galatiSeason.season | reverse; let i = index; ">
    <h2 class="tac" style="background: #ddd; padding: 20px 0px; border-radius: 6px;">{{historyWeek.weekMsg}}</h2>
    <app-situation-card [week]="historyWeek" [vineyard]="vineyard" [timeFrame]="'history'" style="margin-top:0;"></app-situation-card>
  </div>
</div>
  