<div class="main-content">
	<div class="page-content">
		<div class="container-fluid">
			<h2 class="page-heading" translate="CROP_SPRAYS"></h2>
		</div>
	</div>
</div>
<h1>
	<!-- {{(juzer | async)?.name }} -->
</h1>
{{Vineyards}}
<div class="placeholder-content-wrapper no-charts">
	<div class="placeholder-content">
		<img src="/assets/img/undraw/postrek.svg" alt="" />
		<div class="placeholder-texts">
			<h2 translate="Page_Coming_Soon"></h2>
			<!-- <p style="margin-top:35px;">Sekciu evidencie postrekov pre Vás pripravujeme pre evidovanie množštva, druhov a frekvencie postrekov vo Vašom vinohrade. <br> Táto evidencia Vám výrazne pomôže zjednodušiť celý byrokratický proces evidencie pri integrovanej a ekologickej produkcii viniča a zjednoduší tak získanie dotácií.</p> -->
			<h5 style="margin-top:35px; line-height: 1.618em;font-size:15px;">Sekciu evidencie postrekov pre Vás pripravujeme pre evidovanie množštva, druhov a frekvencie postrekov vo Vašom vinohrade. <br> Táto evidencia Vám výrazne pomôže zjednodušiť celý byrokratický proces evidencie pri integrovanej a ekologickej produkcii viniča a zjednoduší tak získanie dotácií.</h5>
			<!-- <h5>But first you need to add a station to your account.</h5> -->
		</div>
		<!-- <button class="btn btn-primary" type="button" (click)="wizard = true"> Add a Vineyard </button> -->
	</div>
</div>
<table class="table " *ngIf="wunderNear">
	<tbody>
		<tr>
			<td>station name </td>
			<td *ngFor="let item of wunderNear.stationName">
				{{item}}
			</td>
		</tr>
		<tr>
			<td>station id</td>
			<td *ngFor="let item of wunderNear.stationId">
				{{item}}
			</td>
		</tr>
		<tr>
			<td>distance [km]</td>
			<td *ngFor="let item of wunderNear.distanceKm">
				{{item}}
			</td>
		</tr>
	</tbody>
</table>
<table class="table table-light">
	<tbody>
		<td style="background-color: aliceblue;" *ngFor="let station of wunderStations">
			<tr>
				<td>{{station.stationId}}</td>
			</tr>
			<tr *ngFor="let zaznam of station.zaznamy">
				<td> {{zaznam.date}}</td>
				<td> {{zaznam.metric.tempAvg}} °C</td>
			</tr>
		</td>
	</tbody>
</table>