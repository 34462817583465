import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { HttpClientModule, HttpClient } from "@angular/common/http";
import { RouterModule } from "@angular/router";
import { AppRoutingModule } from "./app.routing";
import { HashLocationStrategy, LocationStrategy } from "@angular/common";
import { ComponentsModule } from "./components/components.module";
import { AppComponent, DialogChoosePhenophase } from "./app.component";
import { IvyCarouselModule } from "angular-responsive-carousel";

import { PdfViewerModule } from 'ng2-pdf-viewer';
import { registerLocaleData } from "@angular/common";
import {
  UserProfileComponent,
  DialogReauthenticate,
} from "./user-profile/user-profile.component";
import { SprayComponent } from "./spray/spray.component";
import {
  CalendarComponent,
  DialogAddEvent,
} from "./calendar/calendar.component";
import {
  VineyardComponent,
  DialogDocAddVineyard,
} from "./vineyards/vineyards.component";
import {
  VineyardCardComponent,
  DialogPhenophasesInfo,
} from "./vineyards/vineyard-card/vineyard-card.component";
import { DeviceCardComponent } from "./devices/device-card/device-card.component";
import { VineyardWizardComponent } from "./vineyards/vineyard-wizard/vineyard-wizard.component";

/*leaflet map */
import { LeafletModule } from "@asymmetrik/ngx-leaflet";
import * as myGlobals from "./global-variables";
import { LoginPageComponent } from "./login-page/login-page.component";
import { AngularFireModule } from "@angular/fire";
import { AngularFireAuthModule } from "@angular/fire/auth";
import { NgxEchartsModule } from "ngx-echarts";
// import { AuthGuard } from "./_services/logged-in.guard";

import { AngularFireAuthGuard } from "@angular/fire/auth-guard";
import { AngularFirestoreModule } from "@angular/fire/firestore";
import { AngularFireFunctionsModule } from "@angular/fire/functions";

/* ngx translate */

import {
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";

/* material design */
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatButtonModule } from "@angular/material/button";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatCardModule } from "@angular/material/card";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MatRippleModule,
  MatNativeDateModule,
  MAT_DATE_LOCALE,
} from "@angular/material/core";

import { MatDatepickerModule } from "@angular/material/datepicker";
import { SatDatepickerModule, SatNativeDateModule } from "saturn-datepicker";
import { MatDialogModule } from "@angular/material/dialog";
import { MatDividerModule } from "@angular/material/divider";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatChipsModule, MAT_CHIPS_DEFAULT_OPTIONS } from "@angular/material/chips";
import { MatMenuModule } from "@angular/material/menu";
import { MatListModule } from '@angular/material/list';
import {
  MatPaginatorModule,
  MatPaginatorIntl,
} from "@angular/material/paginator";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import {
  MatRadioModule,
  MAT_RADIO_DEFAULT_OPTIONS,
} from "@angular/material/radio";
import { MatSelectModule } from "@angular/material/select";
import { MatSliderModule } from "@angular/material/slider";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatSortModule } from "@angular/material/sort";
import { MatStepperModule } from "@angular/material/stepper";
import { MatTableModule } from "@angular/material/table";
import { MatTabsModule } from "@angular/material/tabs";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

import { DragDropModule } from "@angular/cdk/drag-drop";
import { LoadingComponent } from "./loading/loading.component";
import { VineyardDetailComponent } from "./vineyards/vineyard-detail/vineyard-detail.component";
import {
  GalatiComponent,
  DialogDocGalatiChart,
} from "./galati/galati.component";
import { ChartComponent } from "./chart/chart.component";

import localeSK from "@angular/common/locales/sk";
import localeHU from "@angular/common/locales/hu";
import localeCS from "@angular/common/locales/cs";
import { ReversePipe } from "./_pipes/reverse.pipe";
import { FirstWordCap } from "./_pipes/first-word-cap.pipe";
import { ReplacePipe } from "./_pipes/replace-pipe";
import { RainChartComponent } from "./chart/rain-chart/rain-chart.component";
import { DevicesComponent } from "./devices/devices.component";
import { PaginatorIntlService } from "./_services/mat-paginator-i18n.service";
import { DocsComponent } from "./docs/docs.component";
import { CalendarPageComponent } from "./calendar-page/calendar-page.component";
import { ForecastComponent } from "./forecast/forecast.component";
import { UsersComponent } from './users/users.component';
import { GalatiweekComponent } from './galatiweek/galatiweek.component';
import { CurrentMeteoComponent } from './current-meteo/current-meteo.component';
import { SituationComponent } from './situation/situation.component';
import { AdminbarComponent } from './adminbar/adminbar.component';
import { SituationCardComponent } from './situation/situation-card/situation-card.component';
import { ManualComponent } from './docs/manual/manual.component';
import { PhenosComponent } from './docs/phenos/phenos.component';
import { AngularFireAnalyticsModule } from '@angular/fire/analytics';
import { ProductsComponent } from './products/products.component';



const firebase_config = {
  apiKey: "AIzaSyCZmTZe9Ax98xk7tu30A2l0T5S-cTHSMAQ",
  authDomain: "vinlink-firebase.firebaseapp.com",
  projectId: "vinlink-firebase",
  storageBucket: "vinlink-firebase.appspot.com",
  messagingSenderId: "884757144335",
  appId: "1:884757144335:web:8a21c3337625ea9f0d547a",
  measurementId: "G-1PK5J7NF9L"
};

@NgModule({
  imports: [
    PdfViewerModule, 
    AngularFireAnalyticsModule,
    IvyCarouselModule,
    BrowserAnimationsModule,
    BrowserModule,
    HttpClientModule,
    ComponentsModule,
    RouterModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(firebase_config),
    AngularFirestoreModule,
    AngularFireFunctionsModule,
    AngularFireAuthModule,
    NgxEchartsModule,
    MatMenuModule,
    MatButtonModule,
    MatRippleModule,
    MatFormFieldModule,
    MatInputModule, MatListModule,
    MatSelectModule,
    MatTooltipModule,
    MatProgressSpinnerModule,
    MatDatepickerModule,
    SatDatepickerModule,
    MatCheckboxModule,
    SatNativeDateModule,
    MatButtonToggleModule,
    MatSlideToggleModule,
    MatChipsModule,
    MatNativeDateModule,
    MatProgressBarModule,
    MatSliderModule,
    MatExpansionModule,
    MatDividerModule,
    MatIconModule,
    MatStepperModule,
    MatTabsModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatAutocompleteModule,
    FormsModule,
    ReactiveFormsModule,
    DragDropModule,
    MatDialogModule,
    MatSnackBarModule,
    MatCardModule,
    MatRadioModule,
    LeafletModule.forRoot(),

    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  declarations: [
    AppComponent,
    LoginPageComponent,
    UserProfileComponent,
    CalendarComponent,
    SprayComponent,
    VineyardComponent,
    VineyardCardComponent,
    VineyardDetailComponent,
    VineyardWizardComponent,
    LoadingComponent,
    GalatiComponent,
    ChartComponent,
    ReversePipe,
    FirstWordCap,
    ReplacePipe,
    RainChartComponent,
    DevicesComponent,
    DeviceCardComponent,
    DialogReauthenticate,
    DialogPhenophasesInfo,
    DialogChoosePhenophase,
    DialogDocGalatiChart,
    DialogDocAddVineyard,
    DialogAddEvent,
    DocsComponent,
    CalendarPageComponent,
    ForecastComponent,
    UsersComponent,
    GalatiweekComponent,
    CurrentMeteoComponent,
    SituationComponent,
    AdminbarComponent,
    SituationCardComponent,
    ManualComponent,
    PhenosComponent,
    ProductsComponent

    // DialogChoosePhenophase
  ],
  entryComponents: [
    DialogReauthenticate,
    DialogPhenophasesInfo,
    DialogChoosePhenophase,
    DialogDocGalatiChart,
    DialogDocAddVineyard,
    DialogAddEvent,
    // DialogChoosePhenophase
  ],
  providers: [

    { provide: LocationStrategy, useClass: HashLocationStrategy },
    AngularFireAuthGuard,
    {
      provide: MAT_RADIO_DEFAULT_OPTIONS,
      useValue: { color: "primary" },
    },
    {
      provide: MatPaginatorIntl,
      useFactory: (translate) => {
        const service = new PaginatorIntlService();
        service.injectTranslateService(translate);
        return service;
      },
      deps: [TranslateService],
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor() {
    registerLocaleData(localeSK, "sk");
    registerLocaleData(localeCS, "cs");
    registerLocaleData(localeHU, "hu");
  }
}
// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
