import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { Vineyard } from "app/_classes/vineyard";
import { WeatherService } from "app/_services/weather.service";
import { VineyardService } from "app/_services/vineyard.service";
import { MapService } from "app/_services/map.service";
import { trigger, transition, style, animate } from "@angular/animations";
import { TranslateService } from "@ngx-translate/core";
import { Observable } from "rxjs/Observable";
declare var L: any;

declare var SMap: any;
declare var $;
@Component({
  selector: "app-device-card",
  templateUrl: "./device-card.component.html",
  styleUrls: ["./device-card.component.scss"],
  animations: [
    trigger("inOutAnimation", [
      transition(":enter", [
        style({ opacity: 0 }),
        animate(".25s ease-out", style({ opacity: 0.3 })),
      ]),
      transition(":leave", [
        style({ opacity: 0.3 }),
        animate(".25s ease-in", style({ opacity: 0 })),
      ]),
    ]),
  ],
})
export class DeviceCardComponent implements OnInit {
  map;
  @Input() vineyard: Vineyard;
  @Input() ActivationKey: string;
  @Output() vineyardRemoveEmitted: EventEmitter<any> = new EventEmitter();
  odrodyParsed = [{ name: "" }];

  constructor(
    public ws: WeatherService,
    public mapService: MapService, 
    public VineyardService: VineyardService,
    public translate: TranslateService
  ) {
    console.log()

  }

  onVineyardRemove(vineyard) {
    this.vineyardRemoveEmitted.emit(vineyard);
  }
  onMapReady(e) {
    e.setView([this.vineyard.lat, this.vineyard.lng], 13);
    // e.locate({ setView: true, maxZoom: 16 });
    this.mapService.mapInit(e);

    this.map = e;
    this.map.removeControl(this.map.zoomControl);
    const marker = new L.marker([this.vineyard.lat, this.vineyard.lng], {
      icon: this.mapService.stationIcon,
    })
      .addTo(this.map)
      .bindPopup(
        `${this.translate.instant("device.Device")}: ${this.vineyard.Alias}`
      )
      .openPopup();
  }
  ngOnInit() {
    // this.odrodyParsed = JSON.parse(this.vineyard.odrody)
  }

}
