import { Component, OnInit, HostListener } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/auth";
import { AuthenticationService } from "../_services/authentication.service";
import { VineyardService } from "app/_services/vineyard.service";
import { GlobalService } from "app/_services/global.service";
import { Observable } from "rxjs/Observable";
import { Router } from "@angular/router";
import { untilDestroyed } from "ngx-take-until-destroy";
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";
import {
  Validators,
  FormBuilder,
  FormControl,
  FormGroupDirective,
  NgForm,
} from "@angular/forms";
import { ErrorStateMatcher } from "@angular/material/core";

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: FormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    const isSubmitted = form && form.submitted;
    return !!(
      control &&
      control.invalid &&
      (control.dirty || control.touched || isSubmitted)
    );
  }
}

@Component({
  selector: "app-login-page",
  templateUrl: "./login-page.component.html",
  styleUrls: ["./login-page.component.scss"],
})
export class LoginPageComponent implements OnInit {
  email: string;
  name: string;
  user: firebase.User;
  password: string;
  title;
  action;
  isLoggedIn: boolean;
  innerWidth: number;
  isMobile: boolean;
  //!  registration form start
  matcher = new MyErrorStateMatcher();
  registrationForm = this.formBuilder.group({
    password: ["", [Validators.required, Validators.minLength(6)]],
    name: ["", Validators.required],
    email: ["", Validators.required],
  });
  onRegistrationSubmit(formdata) {
    // console.log("formdata", formdata);
    this.authService.userRegistration(
      formdata.email,
      formdata.password,
      formdata.name
    );
  }
  onLogin(formdata) {
    // console.log("formdata", formdata);
    this.authService.userLogin(formdata.email, formdata.password);
  }
  //!  registration form end
  @HostListener("window:resize", ["$event"])
  onResize(event) {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth <= 768) {
      this.isMobile = true;
      console.log("is mobile");
    } else {
      this.isMobile = false;
    }
  }
  constructor(
    public global: GlobalService,
    public authService: AuthenticationService,
    public ss: VineyardService,
    private fireAuth: AngularFireAuth,
    private router: Router,
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer,
    private formBuilder: FormBuilder
  ) {
    iconRegistry.addSvgIcon(
      "google-logo",
      sanitizer.bypassSecurityTrustResourceUrl(
        "../../assets/img/icons/google-logo.svg"
      )
    );
  }

  loginPageAction(): Observable<any> {
    console.log("action", this.action);
    if (this.action) {
      return Observable.of(this.action);
    } else {
      return Observable.of(null);
    }
  }

  ngOnInit() {
    if (window.innerWidth <= 768) {
      this.isMobile = true;
      console.log("is mobile");
    } else {
      this.isMobile = false;
    }
  }
  ngAfterViewInit(): void {
    this.authService
      .isLoggedIn()
      .pipe(untilDestroyed(this))
      .subscribe((res) => {
        this.isLoggedIn = res;
        console.log("login page on init - is logged in = ", res);
        if (res === true) {
          this.authService
            .userObs()
            .pipe(untilDestroyed(this))
            .subscribe((userData) => {
              this.user = userData;
            });
          // User is signed in.
          this.title = "Logged In";
          this.router.navigate(["/vineyards"]);
        } else {
          // No user is signed in.
          this.title = "Login";
          this.action = "Login";
        }
      });
  }
  ngOnDestroy() {
    // To protect you, we'll throw an error if it doesn't exist.
  }
}
