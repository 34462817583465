import { Injectable, ModuleWithProviders } from "@angular/core";
import { NotificationsService } from "./notifications.service";
import { AngularFireAuth } from "@angular/fire/auth";
import "rxjs/add/observable/of";
import { timer, Observable } from "rxjs";
import { mergeMap } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";
import { AuthenticationService } from "./authentication.service";
import { Vineyard } from "../_classes/vineyard";

import { WeatherService } from "./weather.service";
import { Subject, BehaviorSubject } from "rxjs";
import {
  subHours,
  formatDistance,
  format,
  subDays,
  isAfter,
  getDay,
  differenceInDays,
  isToday,
  isYesterday,
  startOfDay,
  startOfYesterday,
} from "date-fns";
import { GalatiService } from "./galati.service";
import { TranslateService } from "@ngx-translate/core";
import { untilDestroyed } from "ngx-take-until-destroy";
import { GlobalService } from "./global.service";

import { AngularFirestore } from '@angular/fire/firestore';

declare var L: any;
@Injectable({
  providedIn: "root",
})
export class VineyardService {
  stationIcon = L.icon({
    iconUrl: "assets/img/icons/station_icon.png",
    iconSize: [34, 53],
    iconAnchor: [17, 48],
    popupAnchor: [0, -50],
    tooltipAnchor: [25, -28],
  });
  vineyardIsRemoved: Subject<string> = new Subject<string>();
  fade: string;
  fadeNot: string;
  success = false;
  myVineyards;
  isLoading = true;
  wizardSubject = new BehaviorSubject<boolean>(false);
  wizardState = this.wizardSubject.asObservable();
  interval;
  vineyardsBehaviorSubject = new BehaviorSubject<any>(null);
  vineyardsState = this.wizardSubject.asObservable();
  weekOfYear;
  myVineyardsBehSub: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  weekOfYearBeh: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  wizardOpen() {
    this.wizardSubject.next(true);
    console.log("wizardOpen");
  }
  wizardClose() {
    this.wizardSubject.next(false);
    this.getMyVineyards();
  }

  constructor(
    private afs: AngularFirestore,
    private weatherService: WeatherService,
    private http: HttpClient,
    public authService: AuthenticationService,
    public galati: GalatiService,
    public ws: WeatherService,
    public global: GlobalService,
    public translate: TranslateService
  ) {
    this.galati.galatiCalendar(new Date()).then((res) => {
      this.weekOfYear = res["weekNum"];
      // this.weekOfYear = 32; // ! fiktivny tyzden v roku, nedat do produkcie 
      this.weekOfYearBeh.next(this.weekOfYear);
    });
  }

  vineyardUpdateData(updatedVineyard) {
    this.myVineyards.filter((obj) => {
      return obj.ActivationKey === updatedVineyard.ActivationKey;
    });
    this.vineyardsBehaviorSubject.next(this.myVineyards);
  }



  getMyVineyards() {
    this.myVineyards = null;
    this.isLoading = true;

    if (this.authService.user) {
      // Call the Cloud Function to check for special access
      this.authService.checkSpecialAccess().pipe(untilDestroyed(this)).subscribe((resAccess) => {
        if (resAccess.hasSpecialAccess) {
          // Fetch all vineyards for users with special access
          this.afs.collection('Vineyards').valueChanges().subscribe((rez) => {

            // console.warn("special access", resAccess.hasSpecialAccess)
            this.handleVineyardsResponse(rez);


          }, err => this.handleVineyardsError(err));
        } else {
          // Fetch only the vineyards associated with the user
          this.afs.collection('Vineyards', ref => ref.where(`users.${this.authService.user.uid}`, ">=", "")).valueChanges().subscribe((rez) => {
            this.handleVineyardsResponse(rez);
            // console.warn("not special access", resAccess.hasSpecialAccess)
          }, err => this.handleVineyardsError(err));
        }
      }, err => {
        console.error("Error checking special access:", err);
        this.isLoading = false;
      });
    }
  }

  // Refactored response and error handling into separate methods
  handleVineyardsResponse(rez) {
    this.myVineyards = rez;
    this.myVineyardsBehSub.next(this.myVineyards);
    this.isLoading = false;
    this.vineyardsInit();
  }

  handleVineyardsError(err) {
    console.log("afs get vineyards err", err);
    this.isLoading = false;
  }




  //   getMyVineyards() {
  //     this.myVineyards = null;
  // if(this.authService.user)
  //     this.authService
  //       .userObs()
  //       .pipe(untilDestroyed(this))
  //       .subscribe((userData) => {
  //         this.authService.user = userData;
  //         this.afs.collection('Vineyards', ref => ref.where(`users.${this.authService.user.uid}`, ">=", "")).valueChanges().subscribe((rez) => {
  //           this.myVineyards = rez;
  //           this.myVineyardsBehSub.next(this.myVineyards);
  //           this.isLoading = false;
  //           this.vineyardsInit();
  //         }, err => {
  //           console.log("afs get vineyards err", err);
  //           this.isLoading = false;
  //         })
  //       });
  //   }



  vineInitGalati(vineyard) {
    this.galati
      .getSeason(vineyard)
      .then((data) => {
        vineyard.galatiSeason = data;
      })
      .catch((err) => {
        console.log(`${vineyard.Alias} (${vineyard.ActivationKey}) galati error for galatiKey: ${vineyard.galatiKey}: ${err}`);
      })
      .finally(() => {
        this.isLoading = false;
        this.myVineyardsBehSub.next(this.myVineyards);
      });
  }



  vineInitMeteo(vineyard) {
    this.weatherService.getMeteoData(this.global.nowSubDays(5), vineyard.StationID).toPromise().then(res => {
      vineyard.meteoData = res;
      vineyard.currentMeteoData = vineyard.meteoData.slice(-1)[0];

      // Attempt to fetch weather data from localStorage
      const weatherCacheKey = `weather_${vineyard.ActivationKey}`;
      const cachedWeather = localStorage.getItem(weatherCacheKey);
      let shouldFetchWeather = true;

      if (cachedWeather) {
        const { lastFetch, data } = JSON.parse(cachedWeather);
        const timeSinceLastFetch = (new Date().getTime() - new Date(lastFetch).getTime()) / (1000 * 60); // Convert to minutes
        if (timeSinceLastFetch < 30) {
          vineyard.currentOWM = data;
          console.log("cached current OWM available", vineyard.currentOWM)
          vineyard.currentOWMicon = this.weatherService.weatherIcon(vineyard.currentOWM.weather[0].id);
          shouldFetchWeather = false; // Data is fresh, no need to fetch
        }
      }

      if (shouldFetchWeather) {
        this.http.get(
          `https://api.openweathermap.org/data/2.5/weather?lat=${vineyard.lat}&lon=${vineyard.lng}&lang=en&units=metric&APPID=${this.global.owmApiKey}`).toPromise().then((result) => {
            vineyard.currentOWM = result;
            vineyard.currentOWMicon = this.weatherService.weatherIcon(vineyard.currentOWM.weather[0].id);

            // Save the fetched data to localStorage
            const cacheValue = {
              lastFetch: new Date().toISOString(),
              data: result
            };
            localStorage.setItem(weatherCacheKey, JSON.stringify(cacheValue));
          });
      }
      if (vineyard.currentMeteoData.insert_datetime) {

        const yesterday = startOfYesterday(); // get yesterday's date without milliseconds
        const today = new Date();

        const yesterdayMeteo = vineyard.meteoData.filter((data) => isYesterday(new Date(data.insert_datetime)));
        const todayMeteo = vineyard.meteoData.filter((data) => isToday(new Date(data.insert_datetime)));

        vineyard.rainYesterday = yesterdayMeteo.reduce((acc, data) => acc + Number(data.rain), 0);
        vineyard.rainToday = todayMeteo.reduce((acc, data) => acc + Number(data.rain), 0);
      }
    }).catch((err) => {
      console.log("get meteo err", err);
    }).finally(() => {
      this.myVineyardsBehSub.next(this.myVineyards);
    });
  }






  vineyardsInit() {
    this.myVineyards.forEach((vineyard) => {
      if (vineyard.galatiKey) {
        this.vineInitGalati(vineyard);
      }
      this.authService.checkSpecialAccess().pipe(untilDestroyed(this)).subscribe((resAccess) => {
        if (!resAccess.hasSpecialAccess) {
          this.vineInitMeteo(vineyard);
          // console.warn("resAccess.hasSpecialAccess= ", resAccess.hasSpecialAccess)
        }
      });

    })
  }

  ngOnDestroy() {
    // To protect you, we'll throw an error if it doesn't exist.
    if (this.interval) {
      clearInterval(this.interval);
    }
  }
}
