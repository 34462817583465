import { Component, OnInit } from "@angular/core";
import { AuthenticationService } from "../../_services/authentication.service";
import { VineyardService } from "../../_services/vineyard.service";
import { GlobalService } from "app/_services/global.service";
import { TranslateService } from "@ngx-translate/core";
import { AngularFireAuth } from "@angular/fire/auth";
import { NotificationsService } from "app/_services/notifications.service";
import { HttpClient } from "@angular/common/http";
import { untilDestroyed } from 'ngx-take-until-destroy';
import { AngularFirestore } from '@angular/fire/firestore';
import { NavigationEnd, Router, ActivatedRoute } from '@angular/router';
// declare const $: any;
declare interface RouteInfo {
  path: string;
  title: string;
  icon: string;
  svgPath?: string;
  icon_code?: string;
  class: string;
  data?: any;
}
export const ROUTES: RouteInfo[] = [
  {
    path: "/vineyards",
    title: "VINEYARDS",
    icon: "ownfont",
    icon_code: "flaticon-rural",
    class: "",
  },
  {
    path: "/devices",
    title: "METEO_STATIONS",
    /*icon: "settings_remote",
    icon: "settings_input_antenna",
    icon: "svg",
    svgPath: "/assets/img/icons/station-symbol2.svg",*/
    icon: "ownfont",
    icon_code: "flaticon-antenna-3",
    class: "",
  },
  {
    path: "/user-profile",
    title: "Môj profil",
    icon: "ownfont",
    icon_code: "flaticon-farmer-3",
    class: "",
  },
  // {
  //   path: "/spray",
  //   title: "CROP_SPRAYS",
  //   // icon: "eco",

  //   icon: "ownfont",
  //   icon_code: "flaticon-clean",
  //   class: "",
  //   // class: 'active-pro'
  // },
  // {
  //   path: "/calendar-page",
  //   title: "calendar",
  //   icon: "event_note",
  //   // icon: "ownfont",
  //   // icon_code: "flaticon-clean",
  //   class: "",
  //   // class: 'active-pro'
  // },
  {
    path: "/docs",
    title: "dokumentácia",
    icon: "help_outline",
    // icon: "ownfont",
    // icon_code: "flaticon-clean",
    class: "",
    // class: 'active-pro'
  },
];

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.scss"],
})
export class SidebarComponent implements OnInit {
  menuItems: any[];
  public innerWidth: any;

  menuNew: any;
  currentRoute: any;



  constructor(
    public authService: AuthenticationService,
    public ss: VineyardService,
    public global: GlobalService,
    private vineyardService: VineyardService,
    public afs: AngularFirestore,
    private router: Router,
    private route: ActivatedRoute,
    public notif: NotificationsService,
    public translate: TranslateService
  ) {
    router.events.filter(event => event instanceof NavigationEnd)
      .subscribe(event => {
        this.currentRoute = event;
      }); 
  }
  refresh() {
    window.location.reload();
  }


  ngOnInit() {


    this.menuItems = ROUTES.filter((menuItem) => menuItem);
    this.menuNew = [
      {
        path: "/vineyards",
        title: "VINEYARDS",
        icon: "ownfont",
        icon_code: "flaticon-rural",
        class: ""
      },
      // {
      //   path: "/devices",
      //   title: "METEO_STATIONS",
      //   icon: "ownfont",
      //   icon_code: "flaticon-antenna-3",
      //   class: ""
      // },
      {
        path: "/user-profile",
        title: "Môj profil",
        icon: "ownfont",
        icon_code: "flaticon-farmer-3",
        class: ""
      },
      {
        path: "/manual",
        title: "dokumentácia",
        icon: "help_outline",
        class: "",
        sub: [
          {
            path: "/manual",
            title: "Manuál",
            icon: "school_outline",
            class: ""
          },
          {
            path: "/phenos",
            title: "Phenophases",
            icon: "eco_outline",
            class: ""
          },

        ]
      },
    ];
    this.menuNew.filter(obj => {
      if (obj.title === "VINEYARDS") {
        this.vineyardService
          .myVineyardsBehSub
          .pipe(untilDestroyed(this))
          .subscribe(
            (data) => {
              obj.vineyards = data;
            }, err => {
          })
      }
    })


  }


  isMobileMenu() {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth > 991) {
      return false;
    }
    return true;
  }
  ngOnDestroy() {
    // To protect you, we'll throw an error if it doesn't exist.
  }
}
