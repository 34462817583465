import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class StripeService {

  constructor(private firestore: AngularFirestore) { }

  getProducts(): Observable<any[]> {
    return this.firestore.collection('products').snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data: Object = a.payload.doc.data();
        const id = a.payload.doc.id;
        console.log(actions)
        return { id, ...data };
      }))
    );
  }

  getPrices(productId: string): Observable<any[]> {
    return this.firestore.collection(`products/${productId}/prices`).snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data: Object = a.payload.doc.data();
        const id = a.payload.doc.id;
        return { id, ...data };
      }))
    );
  }
}
