<h3 mat-dialog-title>Fenologické fázy viniča</h3>
<h4>Rastové štádiá, identifikovanie a označovanie</h4>
<mat-dialog-content class="mat-typography">
  <p>Presné zadávanie fenofázy Vášho viniča je dôležité pre správnu predikciu chorôb viniča. Služba VinLink a algoritmom galati rozlišujú 9 základných fenofáz, ktoré sú potrebné pre používanie prognózy a signalizácie chorôb viniča.</p>
  <table class="table table-light table-responsive">
    <thead class="thead-light">
      <tr>
        <th>Číslo</th>
        <th>Piktogram fenofázy</th>
        <th>Opis fenofázy</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>0</td>
        <td><img src="/assets/img/phenophases/pheno_0_odpocinok.jpg" alt="" srcset=""></td>
        <td>
          <h4>Zimný odpočinok (dormancia):</h4> zimné púčiky sú uzavreté do šupín (očká), podľa odrody môžu byť viac alebo menej hrotité alebo zaokrúhlené, svetlo až tmavohnedé.
        </td>
      </tr>
      <tr>
        <td>1</td>
        <td><img src="/assets/img/phenophases/pheno_1_slzenie.jpg" alt="" srcset=""></td>
        <td>
          <h4>Slzenie:</h4> pri obnovení rezu najmenej 1-2 cm od jesenného rezu sa rezná plocha zarosí, šošovkovite sa na nej vytvorí kvapka.
        </td>
      </tr>
      <tr>
        <td>2</td>
        <td><img src="/assets/img/phenophases/pheno_2_pucanie.jpg" alt="" srcset=""></td>
        <td>
          <h4>Pučanie - otváranie očiek (rašenie), rast letorastov:</h4> zelené končeky výhonkov sú jasne viditeľné.
        </td>
      </tr>
      <tr>
        <td>3</td>
        <td><img src="/assets/img/phenophases/pheno_3_9list.jpg" alt="" srcset=""></td>
        <td>
          <h4>Vývoj Listov, 60-80 cm letorasty:</h4> Deviaty list a ďalšie sa rozvinuli.
        </td>
      </tr>
      <tr>
        <td>4</td>
        <td><img src="/assets/img/phenophases/pheno_4_rozkvitanie.jpg" alt="" srcset=""></td>
        <td>
          <h4>Kvitnutie (Rozkvitanie), začiatok kvitnutia:</h4> 10% čiapočiek opadne.
        </td>
      </tr>
      <tr>
        <td>5</td>
        <td><img src="/assets/img/phenophases/pheno_5_kvitnutie.jpg" alt="" srcset=""></td>
        <td>
          <h4>Kvitnutie:</h4> 50% čiapočiek opadne.
        </td>
      </tr>
      <tr>
        <td>6</td>
        <td><img src="/assets/img/phenophases/pheno_6_koniec-kvitnutia.jpg" alt="" srcset=""></td>
        <td>
          <h4>Koniec kvitnutia:</h4> vyše 80% čiapočiek opadnutých.
        </td>
      </tr>
      <tr>
        <td>7</td>
        <td><img src="/assets/img/phenophases/pheno_7_previs.jpg" alt="" srcset=""></td>
        <td>
          <h4>Vývoj bobúľ a strapcov:</h4> Štádium veľkosti hrášku, strapce previsnú.
        </td>
      </tr>
      <tr>
        <td>8</td>
        <td><img src="/assets/img/phenophases/pheno_8_dotyk-bobul.jpg" alt="" srcset=""></td>
        <td>
          <h4>Vývoj bobúľ a strapcov:</h4> Začiatok uzatvárania strapcov - bobule sazačínajú navzájom dotýka.
        </td>
      </tr>
      <tr>
        <td>9</td>
        <td><img src="/assets/img/phenophases/pheno_9_zamakanie.jpg" alt="" srcset=""></td>
        <td>
          <h4>Dozrievanie bobúľ:</h4> Zamäkanie (mäknutie) bobúľ
        </td>
      </tr>
    </tbody>
  </table>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>{{"Close"|translate}}</button>
</mat-dialog-actions>