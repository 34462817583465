<div class="main-content">
  <div class="page-content">
    <div class="container-fluid">
      <h2 class="page-heading">{{'My Meteo Stations' | translate}}</h2>
      <div [@inOutAnimation] #vineyardsList class="vineyards-list">
  
          <app-device-card class="example-box" *ngFor="let vineyard of vineyardService.myVineyards" [vineyard]="vineyard" (vineyardRemoveEmitted)="vineyardService.removeVineyard(vineyard.ActivationKey)">
          </app-device-card>
        <div class="buttons" *ngIf="vineyardService.myVineyards">
          <button class="btn btn-primary not-mobile" type="button" (click)="vineyardService.wizardOpen()" translate="Add New Vineyard"></button>
        </div>
      </div>
    </div>
  </div>
</div>
<app-loading [@inOutAnimation] *ngIf="">{{'Loading stations' | translate}}</app-loading>
<div class="placeholder-content-wrapper no-charts" *ngIf="!vineyardService.myVineyards">
  <div [@inOutAnimation] class="placeholder-content">
    <img src="/assets/img/undraw/empty-box.svg" alt="" />
    <div class="placeholder-texts">
      <h2>{{'NO_Devices_Yet' | translate}}</h2>
      <h5 class="mobile-only" style="font-size: 22px;margin-top: 19px;">{{"Please use your computer to add a new vineyard." | translate}}</h5>
    </div>
    <button class="btn btn-primary not-mobile" type="button" (click)="vineyardService.wizardOpen()"> {{'Add Vineyard' | translate}}</button>
  </div>
</div>