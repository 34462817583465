<div class="card vineyard-card" style="max-width: 836px;">
  <div class="card-header">
    <div *ngIf="vineyard.currentMeteoData && vineyard.meteoResponse !== '0 results'">
      <div class="vineyard-meteo">
        <div class="vineyard-meteo__item">
          <div class="label" translate="meteoData.temperature"></div>
          <div class="value">{{ vineyard.currentMeteoData.temperature }} °C</div>
        </div>
        <div class="vineyard-meteo__item" *ngIf="vineyard.currentOWM">
          <div class="label" translate="meteoData.temp_feels_like"></div>
          <div class="value">{{ vineyard.currentOWM.main.feels_like | number:'1.0-1' }} °C</div>
        </div>
        <div class="vineyard-meteo__item">
          <div class="label" translate="meteoData.humidity"></div>
          <div class="value">{{ vineyard.currentMeteoData.humidity }} %</div>
        </div>
        <div class="vineyard-meteo__item " *ngIf="vineyard.currentMeteoData.humidity && vineyard.currentMeteoData.temperature">
          <div class="label" translate="rosný bod"></div>
          <div class="value">{{ weatherService.findDewPointWithHumidity(vineyard.currentMeteoData.humidity,vineyard.currentMeteoData.temperature)}} °C</div>
        </div>
        <!-- <div class="vineyard-meteo__item">
          <div class="label" translate="meteoData.rain24h"></div>
          <div class="value">{{ vineyard.currentMeteoData.rain24h || 0 | number:'1.0-1' }} mm</div>
        </div> --> <div class="vineyard-meteo__item" [ngClass]="{'vineyard-meteo__item--disabled': !vineyard.rainYesterday}">
          <div class="label" translate="meteoData.rainYesterday"></div>
          <div class="value" *ngIf="vineyard.rainYesterday">{{ vineyard.rainYesterday || 0 | number:'1.0-1' }} mm</div>
          <div class="value" *ngIf="!vineyard.rainYesterday">-- mm</div>
        </div>
        <div class="vineyard-meteo__item" [ngClass]="{'vineyard-meteo__item--disabled': !vineyard.rainToday}">
          <div class="label" translate="meteoData.rainToday"></div>
          <div class="value" *ngIf="vineyard.rainToday">{{ vineyard.rainToday || 0 | number:'1.0-1' }} mm</div>
          <div class="value" *ngIf="!vineyard.rainToday">-- mm</div>
        </div>
        <div class="vineyard-meteo__item" *ngIf="vineyard.currentOWM && vineyard.currentOWM.pressure">
          <div class="label" translate="meteoData.pressure"></div>
          <!-- <div class="value" *ngIf="vineyard.currentMeteoData.pressure !== 1074.5">{{ vineyard.currentMeteoData.pressure }} hPa</div>
          <div class="value" *ngIf="vineyard.currentMeteoData.pressure === 1074.5">{{ vineyard.currentOWM.pressure }} hPa</div> -->
          <div class="value"> {{ vineyard.currentOWM.pressure }} hPa</div>
        </div>
        <div class="vineyard-meteo__item" *ngIf="vineyard.currentOWM" matTooltip="{{ mapService.convertSpeed(vineyard.currentOWM.wind_speed,'kmh') | number:'1.0-1'  }} km/h">
          <div class="label" translate="meteoData.wind_speed"></div>
          <div class="value">{{ vineyard.currentOWM.wind.speed }} m/s</div>
        </div>
        <div class="vineyard-meteo__item" *ngIf="vineyard.currentOWM">
          <div class="label" translate="meteoData.wind_deg"></div>
          <div class="value">{{ mapService.degToCompass(vineyard.currentOWM.wind.deg) | translate}} </div>
        </div>
        <div class="vineyard-meteo__item" *ngIf="vineyard.currentOWM && vineyard.currentOWM.sunrise">
          <div class="label" translate="meteoData.sunrise"></div>
          <div class="value">{{ unixToTime(vineyard.currentOWM.sunrise) | date: "H:mm"}}</div>
        </div>
        <div class="vineyard-meteo__item" *ngIf="vineyard.currentOWM && vineyard.currentOWM.sunset">
          <div class="label" translate="meteoData.sunset"></div>
          <div class="value">{{ unixToTime(vineyard.currentOWM.sunset) | date: "H:mm"}}</div>
        </div>
        <div class="vineyard-meteo__item">
          <div class="label" translate="meteoData.battery"></div>
          <div class="value">{{ vineyard.currentMeteoData.bat }} V</div>
        </div>
        <div class="vineyard-meteo__item " [ngClass]="{'vineyard-meteo__item--disabled': !vineyard.currentMeteoData.lux}">
          <div class="label" translate="meteoData.light"></div>
          <div class="value">{{vineyard.currentMeteoData.lux}} W/m²</div>
        </div>
        <div class="vineyard-meteo__item " [ngClass]="{'vineyard-meteo__item--disabled': vineyard.currentMeteoData.soilTemp === '-10'}">
          <div class="label" translate="meteoData.SoilTemperature"></div>
          <div class="value" *ngIf="vineyard.currentMeteoData.soilTemp !== '-10'">{{vineyard.currentMeteoData.soilTemp }} °C</div>
          <div class="value" *ngIf="vineyard.currentMeteoData.soilTemp === '-10'">-- °C</div>
        </div>
        <div class="vineyard-meteo__item vineyard-meteo__item--disabled">
          <div class="label" translate="meteoData.SoilMoisture"></div>
          <div class="value">-- %</div>
        </div>
        <div class="vineyard-meteo__item vineyard-meteo__item--disabled">
          <div class="label" translate="meteoData.LeafWetness"></div>
          <div class="value">-- %</div>
        </div>
      </div>
    </div>
  </div>
</div>