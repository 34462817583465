<div class="card vineyard-card" id="capture" *ngIf="vineyard">
  <button *ngIf="authService.user && (authService.user.uid=='1jV8LVkDy2ehtgGffQLkpfi6Lj22' || authService.accessResult)" mat-button (click)="sendMejl()">send mail</button>
  <button *ngIf="authService.user && (authService.user.uid=='1jV8LVkDy2ehtgGffQLkpfi6Lj22' || authService.accessResult)" mat-button (click)="testCallable()">test callable</button>
  <div class="card-header" style="padding: 0;">
    <div class="vineyard-header">
      <div class="icon-title" [ngClass]="{'noData24h': !vineyard.currentMeteoData}">
        <div class="icon-temp">
          <mat-icon class="weather-icon desktop-only" *ngIf="!vineyard.currentMeteoData" style="width:34px;height: 34px;font-size: 34px;color:#b1b1b1;">wifi_off</mat-icon>
          <img class="weather-icon svg desktop-only" src="./assets/img/weather-icons-master/svg/{{ vineyard.currentOWMicon }}.svg" alt="" [@inOutAnimation] *ngIf="vineyard.currentOWMicon" />
        </div>
        <div class="vineyard-header__text main-text">
          <h4 class="card-title">
            <a [routerLink]="['/vineyards', vineyard.ActivationKey]">{{ vineyard.Alias }}
            </a>
            <!-- <button mat-button (click)="ws.getExternalMeteoForPreviousWeek(vineyard)">getWeatherData</button> -->
            <div class="role">{{"Account" | translate}}: <span *ngIf="authService.accessResult || vineyard.ownerUID === authService.user.uid">{{"admin" | translate }}</span> <span *ngIf="vineyard.ownerUID !== authService.user.uid && !authService.accessResult">{{"read-only" | translate}}</span> <!---, {{"XXXX"}}-->
              <span *ngIf="vineyard.ownerUID !== authService.user.uid &&authService.accessResult"> - Not owner - Super User</span>
            </div>
    
          </h4>
          <div class="vineyard-header--right">
            <button class="more-info-btn" id="vineyardHeaderBtn" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" mat-icon-button>
              <mat-icon>more_horiz</mat-icon>
            </button>
            <div class="dropdown-menu dropdown-menu-right" aria-labelledby="vineyardHeaderBtn">
              <a class="dropdown-item" [routerLink]="['/vineyards', vineyard.ActivationKey]">{{ 'Disease chart'| translate | FirstWordCap}}</a>
              <a class="dropdown-item" [routerLink]="['/vineyards', vineyard.ActivationKey]" [queryParams]="{tab:'charts'}">{{ 'Show charts'| translate | FirstWordCap}}</a>
              <a class="dropdown-item" [routerLink]="['/vineyards', vineyard.ActivationKey]" [queryParams]="{tab:'info'}">{{ 'Vineyard Info'| translate | FirstWordCap}}</a>
              <a class="dropdown-item" [routerLink]="['/vineyards', vineyard.ActivationKey]" [queryParams]="{tab:'calendar'}">{{ 'calendar'| translate | FirstWordCap}}</a>
              <a class="dropdown-item" [routerLink]="['/vineyards', vineyard.ActivationKey]" [queryParams]="{tab:'forecast'}">{{ 'Weather forecast'| translate | FirstWordCap}}</a>
              <span class="dropdown-item" *ngIf="authService.accessResult || vineyard.ownerUID === authService.user.uid" (click)="phenoFixFn()">{{ 'Oprava fenofázy'| translate | FirstWordCap}}</span>
              <!-- <span class="dropdown-item" (click)="phenoFixFn()">{{ 'Oprava fenofázy'| translate | FirstWordCap}}</span> -->
              <!-- <a class="dropdown-item" (click)="onVineyardRemove(vineyard)">{{'Remove vineyard'|translate}} {{ vineyard.ActivationKey }}</a> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <mat-divider>
  
  </mat-divider>
  <!-- 
    .
    .
    .  
   -->
<div class="phenoFix" *ngIf="phenoFix">
  <h4 style="font-weight: 600;">{{"Spätné odstránenie zadaných fenofáz a postrekov" | translate}}</h4>
  <div class="well ">{{"Táto operácia nemôže byť vrátená späť. Postupujte opatrne. Od Vami zvoleného týždňa (vrátane) sa zo systému vymažú všetky zadané fenofázy a učinené postreky." | translate}}</div>
  <mat-form-field>
    <mat-label>{{"Vyberte týždeň, od ktorého(vrátane) sa majú zadané fenofázy vymazať." | translate}}</mat-label>
    <mat-select [(value)]="phenoToDelete">
      <mat-option *ngFor="let option of phenoFixOptions" [value]="option.key">
        <img src='https://2024.galati.sk/{{phenoBefore(option.key).bbchIcon}}' style="max-width:35px; margin-right: 10px;">{{phenoBefore(option.key).phenoMsg}} <b style="font-weight: 500;">{{option.value | date: 'dd.M.yyyy':undefined:locale}}</b>
      </mat-option>
    </mat-select>
  </mat-form-field>
  <button class="btn btn-outline" style="" (click)="phenoFix = false;">{{"Zahodiť zmeny" | translate}}</button>
  <button class="btn btn-primary btn-delete" [disabled]="!phenoToDelete" (click)="deletePheno()">{{"Odstrániť fenofázu" | translate}}</button>
</div>
<mat-divider *ngIf="phenoFix">
</mat-divider>
<div *ngIf="!phenoFix">
  <div class="well pestControlMsg" *ngIf="!currentWeek"> {{"Sezóna ešte nebola započatá" | translate}} <button class="btn btn-primary" type="button" (click)="globalService.phenoDialog.next(vineyard)" *ngIf="authService.accessResult || vineyard.ownerUID === authService.user.uid">{{"Zadať"|translate}}</button>
  </div>
  <div class="well pestControlMsg" *ngIf="currentWeek && vineyardService.weekOfYear < currentWeek.weekNum">{{"Sezóna zadávania je popredu oproti aktuálnemu týždňu"|translate}} </div>
  <div class="well pestControlMsg intensity-2" *ngIf="currentWeek && vineyardService.weekOfYear > currentWeek.weekNum">{{'Zobrazenie infekčného tlaku a odporúčaní je možné až po zadaní fenofázy a vykonaných postrekov za všetky predchádzajúce týždne.' | translate}}
    <button *ngIf="authService.accessResult || vineyard.ownerUID === authService.user.uid" class="btn btn-primary" type="button" (click)="globalService.phenoDialog.next(vineyard)">{{"Zadať"|translate}}</button>
  </div>
  <div *ngIf="vineyard.currentMeteoData && vineyard.meteoResponse !== '0 results'">
    <div class="vineyard-meteo">
      <div class="vineyard-meteo__item" [ngClass]="{'vineyard-meteo__item--disabled': !vineyard.currentMeteoData.temperature}">
        <div class="label" translate="meteoData.temperature"></div>
        <div class="value" *ngIf="vineyard.currentMeteoData.temperature">{{ vineyard.currentMeteoData.temperature }} <span class="unit">°C</span></div>
        <div class="value" *ngIf="!vineyard.currentMeteoData.temperature">-- <span class="unit">°C</span></div>
      </div>
      <div class="vineyard-meteo__item" *ngIf="showMoreMeteo" [ngClass]="{'vineyard-meteo__item--disabled': !vineyard.rainYesterday}">
        <div class="label" translate="meteoData.rainYesterday"></div>
        <div class="value" *ngIf="vineyard.rainYesterday">{{ vineyard.rainYesterday || 0 | number:'1.0-1' }} <span class="unit">mm</span></div>
        <div class="value" *ngIf="!vineyard.rainYesterday">-- <span class="unit">mm</span></div>
      </div>
      <div class="vineyard-meteo__item" *ngIf="showMoreMeteo" [ngClass]="{'vineyard-meteo__item--disabled': !vineyard.rainToday}">
        <div class="label" translate="meteoData.rainToday"></div>
        <div class="value" *ngIf="vineyard.rainToday">{{ vineyard.rainToday || 0 | number:'1.0-1' }} mm</div>
        <div class="value" *ngIf="!vineyard.rainToday">-- <span class="unit">mm</span>
        </div>
      </div>
      <!-- <div class="vineyard-meteo__item " *ngIf="showMoreMeteo" [ngClass]="{'vineyard-meteo__item--disabled': !vineyard.currentMeteoData.lux}">
        <div class="label">UV index</div>
        <div class="value" *ngIf="vineyard.currentMeteoData.lux">{{vineyard.currentMeteoData.lux}} W/m²</div>
        <div class="value" *ngIf="!vineyard.currentMeteoData.lux">-- W/m²</div>
        </div> --> <div class="vineyard-meteo__item" *ngIf="vineyard.currentOWM">
          <!-- matTooltip="{{ mapService.convertSpeed(vineyard.currentOWM.wind.speed,'kmh') | number:'1.0-1'  }} km/h" -->
          <div class="label" translate="meteoData.wind_speed"></div>
        
            <span class="wind-speeds">
              <span class="wind-value" style="margin-right:3px; ">{{ vineyard.currentOWM.wind.speed  | number:'1.0-1'  }} <span class="unit">m/s</span></span>
              <span class="wind-value">{{mapService.convertSpeed(vineyard.currentOWM.wind.speed,'kmh') | number:'1.0-1'  }} <span class="unit">km/h</span></span>
            </span>
            <span>{{ mapService.degToCompass(vineyard.currentOWM.wind.deg) | translate}}</span>
        </div>
        </div>
        </div>
<div style="margin-top: 15px;  width: 100%;" *ngIf="vineyard && vineyard.galatiSeason && vineyard.galatiSeason.vineyard && currentWeek && vineyardService.weekOfYear==currentWeek.weekNum">
    <div class="pestControlMsg intensity-{{getLargestLevel()}}">
      <b>{{"Odporúčanie" | translate}}:</b> {{currentWeek.pestControlMsg[0]}}
      <br>
      <!-- <div style="font-style:italic;margin-top: 10px;font-size: 14px;"> {{currentWeek.pestControlMsg[1]}}</div> -->
    </div>
  </div>
  <div class="infections" *ngIf="currentWeek && vineyardService.weekOfYear==currentWeek.weekNum">
    <div class="vineyard-infection__row" *ngFor="let infect of ['m','p','b']">
      <div class="disease-name" [routerLink]="['/vineyards', vineyard.ActivationKey]"><span translate="infect.{{infect}}" class="disease-name-span  disease-name--{{currentWeek[infect]['level']}}"><mat-icon>{{ iconClassForInfection(infect)['icon'] }}</mat-icon><span translate="infect.{{infect}}" style="margin-left:6px;"></span></span></div>
      <div *ngIf="currentWeek" class="disease-status disease-status--{{currentWeek[infect]['level']}}">
        <div style="font-style: italic;">{{currentWeek[infect]['msg']}}</div>
        <div style="padding-top: 10px;font-weight: 600;">{{currentWeek[infect]['signalMsg']}}</div>
      </div>
    </div>
  </div>
  <!-- 
    .
    .
    .
   -->
  <mat-divider *ngIf="vineyard.currentMeteoData && vineyard.meteoResponse !== '0 results'"></mat-divider>
  <!-- 
    .
    .
    .
   -->
  <div class="forecast-days desktop">
    <div class="forecast-day" *ngFor="let day of forecast | slice:0:4">
      <div class="forecast-day__head">
        <img class="svg " style="max-width: 50px;" src="./assets/img/weather-icons-master/svg/{{ weatherService.weatherIcon(day.weather[0].id) }}.svg" *ngIf="day.weather" alt="" [@inOutAnimation] />
        <div class="forecast-head-text">
          <div class="forecast-weekday">{{ unixToTime(day.dt) | date: 'EEEE':undefined:locale }}</div>
          <div class="weahter-description">{{ day.weather[0].description | translate }}</div>
        </div>
      </div>
      <div class="vineyard-meteo__item forecast-meteo" *ngIf="day" [ngClass]="{w100p: !vineyard.galatiSeason}" style="margin-top: 7px;">
        <div class="forecast-meteo" *ngIf="day.temp && day.temp.min">
          <div class="forecast-meteo">
            <span class="temps"><span style="color:#2a68ae;font-size: 16px;">{{ day.temp.min | number : '1.0-0'}}°</span>-<span style="color:#f44336;font-size: 16px;">{{ day.temp.max | number : '1.0-0'}}°</span></span>
        <span class="value" *ngIf="day.rain">
          <!-- <i class="material-icons-outlined" style="width: 20px;font-size: 16px;margin-left: 3px;margin-right: -2px;color: #0c6fa0;">water_drop_outline</i> -->
          {{ day.rain | number : '1.0-1' }} <span class="unit">mm</span></span>
        <span class="value" *ngIf="!day.rain">0 <span class="unit">mm</span></span>
          </div>
          <!-- <div class="value" *ngIf="day.rain">{{ day.rain  }} mm</div> -->
        </div>
      </div>
    </div>
    </div>
    
  <!-- 
    .
    .
    .
   -->
  <mat-divider></mat-divider>
</div>
  <div class="card-body">
    <div class="card-footer-buttons">
      <!-- <button [routerLink]="['/vineyards', vineyard.ActivationKey]" class="btn  btn-outline-primary" type="button" translate="device.Open_Vineyard_Details"></button> -->
      <span *ngIf="vineyard.currentMeteoData && vineyard.currentMeteoData.insert_datetime" style="font-weight: 300;">{{'updated' | translate | FirstWordCap}}: <span style="font-weight: 500;"> {{ vineyard.currentMeteoData.insert_datetime | date: 'short':undefined:locale  }}</span>
      </span>
      <div class="icon-buttons pull-right desktop-only" style="font-size: 25px;">
        <button matTooltip="{{'situacna-sprava' | translate | FirstWordCap }}" mat-icon-button [routerLink]="['/vineyards', vineyard.ActivationKey]" [queryParams]="{tab:'situation'}">
          <i class="material-icons-outlined"> list_all </i>
        </button>
        <button matTooltip="{{'Disease chart' | translate | FirstWordCap }}" mat-icon-button [routerLink]="['/vineyards', vineyard.ActivationKey]" [queryParams]="{tab:'infection'}">
          <mat-icon>medical_services</mat-icon>
        </button>
        <button matTooltip="{{'Aktuálne počasie' | translate | FirstWordCap }}" mat-icon-button [routerLink]="['/vineyards', vineyard.ActivationKey]" [queryParams]="{tab:'current'}">
          <mat-icon>sunny</mat-icon>
        </button>
        <button matTooltip="{{'Show charts' | translate | FirstWordCap }}" mat-icon-button [routerLink]="['/vineyards', vineyard.ActivationKey]" [queryParams]="{tab:'charts'}">
          <!-- <i class="material-icons-outlined">query_stats</i> -->
          <mat-icon class="material-icons-outlined">query_stats</mat-icon>
        </button>
        <button matTooltip="{{'Vineyard Info' | translate | FirstWordCap }}" mat-icon-button [routerLink]="['/vineyards', vineyard.ActivationKey]" [queryParams]="{tab:'info'}">
          <mat-icon>info</mat-icon>
        </button>
        <button *ngIf="authService.accessResult || vineyard.ownerUID === fireAuth.auth.currentUser.uid" matTooltip="{{'calendar' | translate | FirstWordCap }}" mat-icon-button [routerLink]="['/vineyards', vineyard.ActivationKey]" [queryParams]="{tab:'calendar'}">
          <mat-icon>calendar_month</mat-icon>
        </button>
        <button matTooltip="{{'Weather forecast' | translate | FirstWordCap }}" mat-icon-button [routerLink]="['/vineyards', vineyard.ActivationKey]" [queryParams]="{tab:'forecast'}">
          <mat-icon>cloud_outline</mat-icon>
        </button>
        <button *ngIf="authService.accessResult || vineyard.ownerUID === fireAuth.auth.currentUser.uid" matTooltip="{{'Users' | translate | FirstWordCap }}" mat-icon-button [routerLink]="['/vineyards', vineyard.ActivationKey]" [queryParams]="{tab:'users'}">
          <mat-icon>supervisor_account</mat-icon>
        </button>
      </div>
    </div>
  </div>
</div>