import { Component, OnInit } from '@angular/core';

import { StripeService } from '../_services/stripe.service';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss']
})
export class ProductsComponent implements OnInit {
  products: any[] = [];

  constructor(private stripeService: StripeService) { }

  ngOnInit() {
    this.stripeService.getProducts().subscribe(products => {
      this.products = products;
      this.products.forEach(product => {
        this.stripeService.getPrices(product.id).subscribe(prices => {
          product.prices = prices;
        });
      });
    });
  }
}