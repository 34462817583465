import { Component, Input, OnInit } from '@angular/core';
import { Vineyard } from 'app/_classes/vineyard';
import { MapService } from 'app/_services/map.service';
import { WeatherService } from 'app/_services/weather.service';
import { fromUnixTime } from 'date-fns';

@Component({
  selector: 'app-current-meteo',
  templateUrl: './current-meteo.component.html',
  styleUrls: ['./current-meteo.component.scss']
})
export class CurrentMeteoComponent implements OnInit {

  @Input() public vineyard: Vineyard;
  constructor(
    public weatherService: WeatherService,
    public mapService: MapService
  ) { }
  unixToTime(timestamp) {
    return fromUnixTime(timestamp);
  }
  ngOnInit(): void {



    
  }

}
