<div class="main-content">
  <div class="page-content">
    <div class="container-fluid">
      <h2 class="page-heading">{{'calendar' | translate}} </h2>
      <div class="row">
        <div class="max-w-1300">
          <app-calendar></app-calendar>
        </div>
      </div>
    </div>
  </div>
</div>