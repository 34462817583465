
    <h3 mat-dialog-title>Vytvorenie vinohradu / registrácia stanice</h3>
    <mat-dialog-content class="mat-typography">
      <div class="video-container">
        <iframe
          width="853"
          height="480"
          src="https://www.youtube.com/embed/L-th1dpPFHE"
          frameborder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
      <button mat-button mat-dialog-close style="margin-top:15px;">
        {{ "Close" | translate }}
      </button>
    </mat-dialog-actions>
  