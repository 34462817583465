<app-vineyard-wizard *ngIf="wizard" [wizard]="" (onWizardClose)="vineyardService.wizardClose()"></app-vineyard-wizard>
<div class="wrapper">
  <div class="sidebar" *ngIf="this.router.url !== '/login'" data-color="green" data-background-color="white" data-image="./assets/img/sidebar-1.jpg">
    <app-sidebar></app-sidebar>
    <div class="sidebar-background" style="background-image: url(./assets/img/sidebar-1.jpg)"></div>
  </div>
  <div class="main-panel" [ngClass]="{loginPage: this.router.url === '/login'}">
  
    <!-- <header class="masthead image-header" *ngIf="!isPage('login')"></header>-->
    <app-navbar *ngIf="this.router.url !== '/login'"></app-navbar>
    <app-adminbar *ngIf="authService.user && (authService.user.uid=='1jV8LVkDy2ehtgGffQLkpfi6Lj22' || authService.accessResult)"></app-adminbar>
    <!-- <router-outlet *ngIf="router.url === '/login' || user"></router-outlet> -->
    <router-outlet> </router-outlet>
    <noscript> Vaša sieť má obmedzenia použitia JavaScriptu. Táto aplikácia si vyžaduje použitie JavaScriptu bez obmedzenia. </noscript>
    <app-footer></app-footer>
  </div>
</div>