import { Component, OnInit, Input, ViewChild, ElementRef, ChangeDetectorRef } from "@angular/core";
import { EChartOption } from 'echarts';
import {
  format,
  differenceInDays,
  subMinutes,
  compareAsc,
  addDays,
  getYear,
} from "date-fns";
import { WeatherService } from "app/_services/weather.service";
import { TranslateService } from "@ngx-translate/core";
import { ChartService } from "app/_services/chart.service";
import { subDays } from "date-fns/esm";
import { Vineyard } from "app/_classes/vineyard";
import { maxMinAvg } from "app/_classes/maxMinAvg";
import { trigger, transition, style, animate } from "@angular/animations";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { Weather } from "app/_classes/weather";
import { GlobalService } from "app/_services/global.service";
import { ExcelService } from "app/_services/excel.service";
import jsPDF from "jspdf";
import autoTable from 'jspdf-autotable';
import { Subscription } from "rxjs";
import { untilDestroyed } from "ngx-take-until-destroy";

import { DateAdapter } from "@angular/material/core";
import Litepicker from "litepicker";
import "litepicker/dist/plugins/ranges";

// import * as fontref from "assets/fonts/Roboto-Regular-normal.js";

// declare var $: any;
@Component({
  selector: "app-chart",
  templateUrl: "./chart.component.html",
  styleUrls: ["./chart.component.scss"],
  animations: [
    trigger("inOutAnimation", [
      transition(":enter", [
        style({ opacity: 0 }),
        animate(".25s ease-out", style({ opacity: 0.3 })),
      ]),
      transition(":leave", [
        style({ opacity: 0.3 }),
        animate(".25s ease-in", style({ opacity: 0 })),
      ]),
    ]),
  ],
})
export class ChartComponent implements OnInit {
  @Input() public chartID: string;
  @Input() public ActivationKey: string;
  @Input() public vineyard: Vineyard;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild("chartElement", { static: true }) chartElement: ElementRef;
  @ViewChild("litepicker", { static: true }) litepicker: ElementRef;
  chartOption: EChartOption = {
    xAxis: {
      type: 'category',
      data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
    },
    yAxis: {
      type: 'value',
    },
    series: [
      {
        data: [820, 932, 901, 934, 1290, 1330, 1320],
        type: 'line',
      },
    ],
  };
  picker;

  maxMinAvgTemp = new maxMinAvg();
  maxMinAvgHumid = new maxMinAvg();
  maxMinAvgLux = new maxMinAvg();

  latestMeteoData: any;
  latestDate: Date;
  minDate: Date;
  maxDate: Date;
  chartsLoaded = false;
  timeSpan = "oneDay";
  dataSource: MatTableDataSource<Weather>;
  sinceDate = subDays(new Date(), 1);
  untilDate = new Date();
  // units = { first: "temperature", last: "rain" };
  exportData;
  fontref;
  excelData: any = [];
  PDFdata: any = [];
  chartLegends;

  subscription: Subscription;
  displayedColumns: string[] = [
    "datetime",
    "temperature",
    "lux",
    "humidity",
    "rain",
  ];
  rainSUM = 0;
  rainYear: any;
  ngOnInit() {
    this.sinceDate = subDays(new Date(), 1);
    this.untilDate = new Date();
    this.minDate = new Date(this.vineyard.inVineyardSince);
    this.maxDate = new Date();

    this.cdref.detectChanges();

  }
  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.




    this.picker = new Litepicker({
      element: document.getElementById("litepicker"),
      singleMode: false,
      minDate: new Date(this.vineyard.inVineyardSince),
      maxDate: new Date(),
      minDays: 2,
      tooltipText: {
        one: this.translate.instant("shortForDays"),
        other: this.translate.instant("shortForDays"),
      },
      startDate: this.sinceDate,
      endDate: this.untilDate,
      lang: this.globalService.lang,
      setup: (picker) => {
        picker.on("selected", (date1, date2) => {
          // console.log("----", date1, date2);
          this.chartChangeDate(
            date1.dateInstance,
            subMinutes(addDays(date2.dateInstance, 1), 1)
          );
        });
      },
    });

    if (this.vineyard.meteoData) {
      this.latestMeteoData = this.sortMeteoByDate(this.vineyard.meteoData);
      this.maxMinAvgTemp = this.maxMinAvg(this.vineyard.meteoData, "temperature");
      this.maxMinAvgHumid = this.maxMinAvg(this.vineyard.meteoData, "humidity");
      this.maxMinAvgLux = this.maxMinAvg(this.vineyard.meteoData, "lux");

      this.updateTables(this.latestMeteoData);
      this.toExportData(this.latestMeteoData, this.sinceDate);

      this.charts.setOptions(
        this.chartID,
        this.latestMeteoData,
        this.latestMeteoData[0].insert_datetime
      );
      this.getRainSum(this.latestMeteoData);
      this.chartsLoaded = true;
    }

    this.ws
      .getMeteoData(this.sinceDate, this.vineyard.StationID).toPromise().then((response) => {

        if (response !== "0 results") {
          this.latestMeteoData = response;
          this.latestMeteoData = this.sortMeteoByDate(response);
          this.maxMinAvgTemp = this.maxMinAvg(response, "temperature");
          this.maxMinAvgHumid = this.maxMinAvg(response, "humidity");
          this.maxMinAvgLux = this.maxMinAvg(response, "lux");

          this.updateTables(this.latestMeteoData);
          this.toExportData(this.latestMeteoData, this.sinceDate);

          this.charts.setOptions(
            this.chartID,
            this.latestMeteoData,
            this.latestMeteoData[0].insert_datetime
          );
        } else {
          this.sinceDate = new Date(this.globalService.nowSubDays(60));

          this.ws
            .getMeteoData(this.sinceDate, this.vineyard.StationID).toPromise().then((response) => {
              if (response !== "0 results") {
                this.latestMeteoData = response;
                this.latestMeteoData = this.sortMeteoByDate(response);
                this.updateTables(this.latestMeteoData);
                this.latestDate = new Date(
                  this.globalService.nowSubDays(this.latestDateInDays())
                );
                this.charts.setOptions(
                  this.chartID,
                  this.latestMeteoData,
                  this.latestMeteoData[0].insert_datetime,
                  true
                );
                // this.charts.defaultLegends(this.chartID);
                this.toExportData(this.latestMeteoData, this.sinceDate);
              }

              // this.charts.defaultLegends(this.chartID);
              this.chartsLoaded = true;
            }).catch(
              (err) => {
                console.log("ch.comp.getMeteoD err", err);
                this.latestMeteoData = [];
              });
        }

        if (this.latestMeteoData) {
          this.getRainSum(this.latestMeteoData);
        }
      })

    this.subscription = this.charts.dataZoomStartDateObservable
      .pipe(untilDestroyed(this))
      .subscribe((res) => {
        console.log("START DATE: ", res);
        if (this.latestMeteoData && res[0] && res[1]) {
          this.updateTables(this.latestMeteoData, res[0], res[1]);
          this.sinceDate = res[0];
          this.untilDate = res[1];
        }
      });

  }

  sortMeteoByDate(meteo) {
    meteo.sort((a, b) => {
      return compareAsc(
        new Date(a.insert_datetime),
        new Date(b.insert_datetime)
      );
    });
    // console.log("SORTED METEO BY DATETIME", meteo);
    return meteo;
  }
  datepickerShow() {
    this.picker.show();
  }


  translateString(string) {
    return this.translate.instant(string);
  }
  updateTables(meteoData, dateStart?, dateEnd?) {
    if (dateStart && dateEnd) {
      meteoData = meteoData.filter(
        (meteoItem) =>
          new Date(meteoItem.insert_datetime) >= new Date(dateStart) &&
          new Date(meteoItem.insert_datetime) <= new Date(dateEnd)
      );
      this.toExportData(meteoData);
    }
    this.maxMinAvgTemp = this.maxMinAvg(meteoData, "temperature");
    this.maxMinAvgHumid = this.maxMinAvg(meteoData, "humidity");
    this.maxMinAvgLux = this.maxMinAvg(meteoData, "lux");
    setTimeout(() => {
      let meteoDataReversed = [...meteoData];
      meteoDataReversed = meteoDataReversed.reverse();
      this.dataSource = new MatTableDataSource(meteoDataReversed);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    }, 10);
    this.getRainSum(meteoData);
  }

  getRainSum(meteodata) {
    this.rainSUM = 0;
    meteodata.forEach((meteoDay) => {
      // console.log("meteo dej", meteoDay);
      this.rainSUM += parseFloat(meteoDay.rain);
    });
    this.rainSUM.toFixed(1);
  }

  iOSDate(date) {
    if (date !== undefined) {
      return date.replace(/\s/g, "T");
    }
  }
  timeSpanChange(timeSpan: string) {
    this.timeSpan = timeSpan;
    // window.location.hash = timeSpan;
  }

  toExportData(data, chosen_date?) {
    console.log("toexportdata", data)
    let cloneData = JSON.parse(JSON.stringify(data));
    if (chosen_date) {
      cloneData = cloneData.filter(function (obj) {
        return new Date(obj.insert_datetime) > new Date(chosen_date);
      });
    }

    cloneData.forEach((v) => {
      delete v.ActivationKey;
      delete v.StationID;
      delete v.deviceTypeId;
      delete v.leafWet;
      delete v.soilMoist;
      delete v.soilTemp;
      delete v.pressure;
      delete v.bat;
      delete v.meteoID;
      v[this.translate.instant("date")] = v.insert_datetime;
      v[this.translate.instant("meteoData.temperature")] =
        v.temperature + " °C";
      v[this.translate.instant("meteoData.humidity")] = v.humidity + " %";
      v[this.translate.instant("meteoData.light")] = v.lux + " W/m²";
      v[this.translate.instant("meteoData.rain")] = v.rain + " mm";
      delete v.lux;
      delete v.rain;
      delete v.temperature;
      delete v.insert_datetime;
      delete v.humidity;
    });
    this.exportData = [...cloneData];
  }



  chartChangeDate(since_date, untilDate = new Date()) {
    if (since_date !== this.sinceDate) {
      this.sinceDate = since_date;
    }
    if (untilDate !== this.untilDate) {
      this.untilDate = untilDate;
    }
    console.log("chart change date", since_date, untilDate, this.latestMeteoData);
    this.latestMeteoData = null;
    this.ws
      .getMeteoData(new Date(since_date), this.vineyard.StationID).toPromise().then((response) => {
        if (response === "0 results") {
        } else {
          this.latestMeteoData = response;
          console.log(
            "::::::::::::::: chartChangeDate getMeteoData ::::::::",
            response
          );
          this.latestMeteoData = this.latestMeteoData.filter(
            (e) => new Date(e.insert_datetime.replace(/-/g, "/")) <= new Date(untilDate)
          );
          this.charts.setOptions(
            this.chartID,
            this.latestMeteoData,
            since_date
          );
          this.toExportData(this.latestMeteoData, since_date);
          this.updateTables(this.latestMeteoData, since_date, new Date());
        }
      }).catch((err) => console.log("ch.comp.getMeteoD err", err))
  }

  latestDateInDays() {
    if (this.latestMeteoData && this.latestMeteoData.length > 0) {
      return differenceInDays(
        new Date(),
        new Date(
          this.latestMeteoData[this.latestMeteoData.length - 1].insert_datetime
        )
      );
    }

  }


  exportAsXLSX(): void {
    let firstDate = new Date(
      this.exportData[0][this.translate.instant("date")]
    );
    console.log(firstDate);
    this.excelService.exportAsExcelFile(
      this.exportData,
      `${this.vineyard.Alias}_${format(firstDate, "dd.MM.yyyy")}-${format(
        new Date(),
        "dd.MM.yyyy"
      )}`
    );
  }

  PDF() {
    let values = [];
    let tableHead = [];
    let firstDate = new Date(
      this.exportData[0][this.translate.instant("date")]
    );
    this.exportData.forEach((e) => {
      // values.push(Object.values(e));
      values.push(Object.keys(e).map((key) => e[key]));
    });
    tableHead.push(Object.keys(this.exportData[0]));

    let doc = new jsPDF();
    doc.getFontList();
    doc.setFont("Roboto-Regular"); // set font
    // doc.setFontType("normal");
    doc.setFontSize(18);
    doc.text(
      `${this.vineyard.Alias}: ${this.translate.instant("since")} ${format(
        firstDate,
        "dd-MM-yyyy"
      )} ${this.translate.instant("until")}  ${format(
        new Date(),
        "dd-MM-yyyy"
      )}`,
      20,
      20
    );
    autoTable(
      doc, {
      head: tableHead,
      body: values,
      margin: { top: 25 },
        styles: { font: "Roboto-Regular" },
        headStyles: { fillColor: '#9bad28' },
    });
    // headStyles: { fontStyle: "Roboto-Regular" },


    doc.save(
      `${this.vineyard.Alias}_${format(firstDate, "dd-MM-yyyy")}-${format(
        new Date(),
        "dd-MM-yyyy"
      )}`
    );
  }

  maxMinAvg(arr, unit: string) {
    let max = Number(arr[0][unit]);
    let min = Number(arr[0][unit]);
    let sum = Number(arr[0][unit]);
    let maxDate = new Date(arr[0].insert_datetime.replace(/-/g, "/"));
    let minDate = new Date(arr[0].insert_datetime.replace(/-/g, "/"));

    for (var i = 1; i < arr.length; i++) {
      let value = Number(arr[i][unit]);
      if (value > max) {
        max = Number(arr[i][unit]);
        maxDate = arr[i].insert_datetime.replace(/-/g, "/");
      }
      if (value < min) {
        min = Number(arr[i][unit]);
        minDate = arr[i].insert_datetime.replace(/-/g, "/");
      }
      sum = sum + Number(arr[i][unit]);
    }
    // console.log("sum", sum, "length", arr.length);

    return {
      max: { value: max, date: new Date(maxDate) },
      min: { value: min, date: new Date(minDate) },
      avg: (sum / arr.length).toFixed(2),
    };
  }


  constructor(
    public ws: WeatherService,
    public charts: ChartService,
    public globalService: GlobalService,
    public excelService: ExcelService,
    public translate: TranslateService,
    private cdref: ChangeDetectorRef
  ) {


    this.rainYear = getYear(new Date());
    console.log("this.rainYear", this.rainYear)

  }
  ngOnDestroy() {
    // To protect you, we'll throw an error if it doesn't exist.
  }
}
