<div class="card vineyard-card" *ngIf="vineyard">
  <div class="card-header">
    <button class="more-info-btn" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" mat-icon-button>
      <mat-icon>more_horiz</mat-icon>
    </button>
    <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownMenuLink">
      <a class="dropdown-item" [routerLink]="['/vineyards', vineyard.ActivationKey]" [queryParams]='{tab:"charts"}'>{{ 'Show charts'| translate | FirstWordCap}}</a>
      <a class="dropdown-item" [routerLink]="['/vineyards', vineyard.ActivationKey]" [queryParams]='{tab:"info"}'>{{ 'Vineyard Info'| translate | FirstWordCap}}</a>
      <a class="dropdown-item" [routerLink]="['/vineyards', vineyard.ActivationKey]" [queryParams]='{tab:"calendar"}'>{{ 'calendar'| translate | FirstWordCap}}</a>
      <!-- <a class="dropdown-item" href="#">Edit Vineyard</a> -->
      <!-- <a class="dropdown-item" (click)="onVineyardRemove(vineyard)">{{'Remove vineyard'|translate}} {{ vineyard.ActivationKey }}</a> -->
    </div>
    <div class="vineyard-header">
      <div class="icon-title">
        <i class="fi flaticon-wifi desktop-only"></i>
        <div class="vineyard-header__text">
          <h4 class="card-title" *ngIf="vineyard">
            {{ vineyard.Alias }}, <a [routerLink]="['/vineyards', vineyard.ActivationKey]" style="margin-left:5px;"><small> {{ vineyard.ActivationKey }}</small></a>
          </h4>
          <p class="card-category" *ngIf="vineyard.currentOWM">
            <span>{{ vineyard.currentOWM.city_district }}</span>
          </p>
        </div>
      </div>
    </div>
    <!-- <mat-divider></mat-divider>
    <div *ngIf="vineyard.meteoData && vineyard.meteoResponse == '0 results'" style="margin: 8px;">
      <span translate="NO_DATA_24h"></span>
    </div> -->
  </div>
  <mat-divider></mat-divider>
  <div class="card-body">
    <!-- <mat-expansion-panel style="box-shadow: none; margin-top:10px" (click)="map.invalidateSize()" [expanded]="true"> -->
    <!-- <mat-expansion-panel-header>
        <mat-panel-title translate="device.Vineyard_info"></mat-panel-title>
        <mat-panel-description> </mat-panel-description>
      </mat-expansion-panel-header> -->
    <div id="map-{{ vineyard.ActivationKey }}" *ngIf="vineyard.ActivationKey" leaflet style="height: 200px;width:100%;margin-bottom:10px;margin-top:15px;resize: both;" (leafletMapReady)="onMapReady($event)"></div>
    <div class="table-responsive table--vineyard-info" style="margin-top:20px;">
      <table class="table" style="margin-bottom: 0;">
        <tbody>
          <tr>
            <td>{{'device.Nearest_city' | translate}}</td>
            <td>{{vineyard.city_district}}</td>
          </tr>
          <tr>
            <td>{{'Latitude' | translate }}</td>
            <td>{{vineyard.lat}}</td>
          </tr>
          <tr>
            <td>{{'Longitude' | translate }}</td>
            <td>{{vineyard.lng}}</td>
          </tr>
          <tr>
            <td>{{'station altitude' | translate }}</td>
            <td>{{vineyard.alt}} m</td>
          </tr>
          <tr>
            <td>{{'device.Lane_orientation' | translate }}</td>
            <td>{{ vineyard.laneOrientation | translate}}</td>
          </tr>
          <tr>
            <td>{{'device.Alt_Delta' | translate }}</td>
            <td>{{ mapService.getAltDelta(vineyard.laneStart.alt,vineyard.laneEnd.alt) | translate}} m</td>
          </tr>
        </tbody>
      </table>

      <!-- </mat-expansion-panel> -->
    </div>
    <!-- <div class="card-body">
    <button [routerLink]="['/vineyards', vineyard.ActivationKey]" class="btn  btn-outline-primary" style="margin-top: 16px;" type="button" translate="device.Open_Vineyard_Details"></button>
  </div> -->
  </div>