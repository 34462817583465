import { Injectable, NgZone } from "@angular/core";
import { Location, PopStateEvent } from "@angular/common";
import { Router, NavigationEnd, NavigationStart } from "@angular/router";
import { untilDestroyed } from "ngx-take-until-destroy";
import {
  MatSnackBar,
  MatSnackBarConfig,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
  MatSnackBarRef,
} from "@angular/material/snack-bar";

// declare var jquery: any;
// declare var $: any;

@Injectable({
  providedIn: "root",
})
export class NotificationsService {
  private lastPoppedUrl: string;
  private yScrollStack: number[] = [];
  pageTitle: string;

  snackBarConfig: MatSnackBarConfig;
  snackBarRef: MatSnackBarRef<any>;
  horizontalPosition: MatSnackBarHorizontalPosition = "center";
  verticalPosition: MatSnackBarVerticalPosition = "top";
  snackBarAutoHide = "3000";

  constructor(public snackBar: MatSnackBar, public location: Location) {
    this.snackBarConfig = new MatSnackBarConfig();
  }
  toaster(
    message,
    Icon = "",
    Type = "warning",
    button = "",
    duration = this.snackBarAutoHide
  ) {
    this.snackBarConfig.horizontalPosition = this.horizontalPosition;
    this.snackBarConfig.verticalPosition = this.verticalPosition;
    this.snackBarConfig.duration = parseInt(duration, 0);
    const alertType = `alert-${Type}`;
    this.snackBarConfig.panelClass = ["VS_snackbar", "alert", alertType];
    this.snackBarRef = this.snackBar.open(message, button, this.snackBarConfig);
  }

  getPageTitle() {
    let pageTitle = this.location.prepareExternalUrl(this.location.path());
    pageTitle = pageTitle.slice(1);
    this.pageTitle = pageTitle;
    return pageTitle;
  }
  ngOnDestroy() {
    // To protect you, we'll throw an error if it doesn't exist.
  }
}
