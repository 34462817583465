export class GalatiPost {
  m: {
    severity: Number;
  };
  p: {
    severity: Number;
  };
  b: {
    severity: Number;
  };
  constructor() {
    this.m = {
      severity: null,
    };
    this.p = {
      severity: null,
    };
    this.b = {
      severity: null,
    };
  }
}
