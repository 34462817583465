import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { BrowserModule } from "@angular/platform-browser";
import { Routes, RouterModule, CanActivate, Router } from "@angular/router";
// import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
// import { AuthGuard } from "./_services/logged-in.guard";
import { LoginPageComponent } from "./login-page/login-page.component";
import { UserProfileComponent } from "./user-profile/user-profile.component";
import { SprayComponent } from "./spray/spray.component";
import { CalendarComponent } from "./calendar/calendar.component";
import { VineyardComponent } from "./vineyards/vineyards.component";
import { VineyardDetailComponent } from "./vineyards/vineyard-detail/vineyard-detail.component";
import { DevicesComponent } from "./devices/devices.component";
import { DocsComponent } from "./docs/docs.component";
import { CalendarPageComponent } from "./calendar-page/calendar-page.component";
import { PhenosComponent } from './docs/phenos/phenos.component';
import { ManualComponent } from './docs/manual/manual.component';
import { ProductsComponent } from './products/products.component';
import {
  AngularFireAuthGuard,
  redirectUnauthorizedTo,
  redirectLoggedInTo,
} from "@angular/fire/auth-guard";


const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(["login"]);
const redirectLoggedInToVineyards = () => redirectLoggedInTo(["vineyards"]);

const routes: Routes = [
  {
    path: "",
    redirectTo: "login",
    pathMatch: "full",
    canActivate: [AngularFireAuthGuard],
    data: {
      authGuardPipe: [redirectUnauthorizedToLogin, redirectLoggedInToVineyards],
    },
  },

  {
    path: "vineyards",
    component: VineyardComponent,
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  {
    path: "vineyards/:ActivationKey",
    component: VineyardDetailComponent,
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  {
    path: "vineyards/:ActivationKey/:tab",
    component: VineyardDetailComponent,
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  {
    path: "devices",
    component: DevicesComponent,
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  {
    path: "calendar",
    component: CalendarComponent,
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  {
    path: "calendar-page",
    component: CalendarPageComponent,
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  {
    path: "login",
    component: LoginPageComponent,
  },
  {
    path: "user-profile",
    component: UserProfileComponent,
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  {
    path: "spray",
    component: SprayComponent,
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  {
    path: "products",
    component: ProductsComponent,
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  {
    path: "docs",
    component: DocsComponent,
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  {
    path: "manual",
    component: ManualComponent,
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  {
    path: "phenos",
    component: PhenosComponent,
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  { path: "**", redirectTo: "login", pathMatch: "full" },
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes, { useHash: true }),
  ],
  exports: [],
})
export class AppRoutingModule {}
