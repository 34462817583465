<div class="main-content">
  <div class="page-content">
    <div class="container-fluid">
      <h2 class="page-heading" style="display: flex;align-items: center;">{{'My Vineyards' | translate}} <button mat-icon-button (click)="vineyardService.myVineyards = null;vineyardService.getMyVineyards();">
          <mat-icon>refresh</mat-icon>
        </button>
      </h2>
      <div class="col-md-3" style="padding-left: 5px; margin-top: 27px;" *ngIf="vineyardService.myVineyards && vineyardService.myVineyards.length > 1">
        <mat-form-field appearance="fill">
          <mat-label>{{"Filter"|translate}}: </mat-label>
          <mat-select [(ngModel)]="filteredVineyard">
            <mat-option value="all">{{"Všetky vinohrady"|translate}} ({{vineyardService.myVineyards.length}})</mat-option>
            <mat-option *ngFor="let vineyard of vineyardService.myVineyards" value="{{vineyard.ActivationKey}}">{{vineyard.Alias}}</mat-option>
            </mat-select>
        </mat-form-field>
      </div>
      <div [@inOutAnimation] #vineyardsList class="vineyards-list" *ngIf="vineyardService.myVineyards">
        <div cdkDropList class="stations-wrapper example-list" [ngClass]="{'filterActive': filteredVineyard !== 'all'}" (cdkDropListDropped)="drop($event)" cdkDropListOrientation="horizontal">
          <!-- Aby Device Card bol draggable, tak pridaj: cdkDrag -->
          <app-vineyard-card [ngClass]="{'shown': vineyard.ActivationKey === filteredVineyard}" *ngFor="let vineyard of vineyardService.myVineyards" [vineyard]="vineyard" (vineyardRemoveEmitted)="vineyardService.removeVineyard(vineyard.ActivationKey)" [@inOutAnimation]>
          </app-vineyard-card>
        </div>
      </div>
      <div class="buttons" *ngIf="vineyardService.myVineyards" style="margin-top: 15px;">
        <button *ngIf="vineyardService.myVineyards.length>0" class="btn btn-primary not-mobile" type="button" (click)="vineyardService.wizardOpen()" translate="Add New Vineyard"></button>
      </div>
    </div>
  </div>
</div>
<!-- <app-loading [@inOutAnimation] *ngIf="vineyardService.isLoading">{{'Loading Vineyards' | translate}}</app-loading> -->
<div class=" placeholder-content-wrapper no-charts" *ngIf="!vineyardService.isLoading && !vineyardService.myVineyards || (vineyardService.myVineyards && vineyardService.myVineyards.length < 1)">
  <div [@inOutAnimation] class="placeholder-content">
    <img src="/assets/img/undraw/empty-box.svg" alt="" />
    <div class="placeholder-texts">
      <h2>{{'NO_Devices_Yet' | translate}}</h2>
      <h5 class="mobile-only" style="font-size: 22px;margin-top: 19px;">{{"Please use your computer to add a new vineyard." | translate}}</h5>
    </div>
    <button class="btn btn-primary not-mobile" type="button" (click)="vineyardService.wizardOpen()"> {{'Add Vineyard' | translate}}</button>
    <br>
    <!-- <div class="btn btn-outline-secondary btn-show-docs" (click)="openDocsVideo()"><i class="material-icons material-icons--outlined posa" style="margin-right: 7px;">info</i>{{"Zobraziť nápovedu" | translate}}</div> -->
  </div>
</div>