<div class="card vineyard-card" [ngClass]="{'mt0': timeFrame === 'history'}">
  <div>
    <div class="card-header" style="padding-top: 0;background-color: #fefefe;" *ngIf="week">
      <div class="week-top">
        <span *ngIf="timeFrame !== 'history' && timeFrame !== 'today'"><strong>{{ "Dátum" | translate }}:</strong> {{week.weekMsg}}</span>
        <span *ngIf="timeFrame == 'today'"><strong>{{ "Dátum" | translate }}:</strong> {{date}}</span>
        <span><strong>{{ "phenophase" | translate | FirstWordCap}}:</strong> {{week.phenoMsg}}</span>
<span *ngIf="previousWeek">
  <span>
    <strong *ngIf="timeFrame == 'today'">{{"Priemerná teplota od začiatku týždňa" | translate}}:</strong>
    <strong *ngIf="timeFrame == 'thisWeek' || timeFrame == 'history'">{{"Priemerná teplota predchádzajúci týždeň" | translate}}:</strong>
    <strong *ngIf="timeFrame == 'nextWeek'">{{"Predpokladaná priemerná teplota tento týždeň" | translate}}:</strong>
    {{previousWeek.temp}}°C </span>
  <span>
    <strong *ngIf="timeFrame == 'today'">{{ "Úhrn zrážok od začiatku týždňa" | translate}}:</strong>
    <strong *ngIf="timeFrame == 'thisWeek' || timeFrame == 'history'">{{ "Úhrn zrážok za predchádzajúci týždeň" | translate}}:</strong>
    <strong *ngIf="timeFrame == 'nextWeek'">{{ "Predpokladaný úhrn zrážok tento týždeň" | translate}}:</strong>
    <span style="margin:0;" *ngIf="!previousWeek.rainfall"> 0</span> {{previousWeek.rainfall}}mm</span>
</span>

      </div>
      <div class="pestControlMsg" [ngClass]="{'intensity-1': (week.pestControl.length === 1),'intensity-2': (week.pestControl.length === 2),'intensity-0': (week.pestControl.length === 0)}">
        <span style="  font-size: 22px;margin: 6px 0 16px;display: block;">
          <strong>{{'Odporúčanie' | translate}}:</strong>
          {{week.pestControlMsg[0]}}
        </span>
      
        <i> {{week.pestControlMsg[1]}}</i>
      </div>
      <div class="vineyard-infection" *ngIf="week" [ngClass]="{'hasDaily': week.day}">
        <div class="vineyard-infection__row" *ngFor="let infect of ['m','p','b']">
          <div class="disease-content-wrapper disease-content-wrapper--{{infect}}">
            <div class="disease-info disease-right" style="margin-left: 3px;">
              <div class="dis-head">
                <div class="disease-name" [routerLink]="['/vineyards', vineyard.ActivationKey]" [queryParams]="{tab:'infection', infection: infect}"><span translate="infect.{{infect}}"></span>
                  <br>
                </div>
                <div class="dis-head-right disease-status--{{week[infect].level}}"> <mat-icon>{{ iconClassForInfection(infect)['icon'] }}</mat-icon>
                  <span style="font-weight: 500;">{{week[infect]['msg']}}</span> &nbsp; {{week[infect]['signalMsg']}}
                </div>
              </div>
              <div class="infection-bar-wrapper">
                <div class="infection-bar">
                  <div class="sensitivity-point" [ngStyle]="{'left': ((vineyard.galatiSeason.vineyard.sensitivity[infect] + 1) * 10 ) + '%'}"></div>
                  <div class="infection yellow" [ngStyle]="{'width': ((week[infect].inf[0] + 1) * 10) + '%'}"></div>
                  <div class="infection red" [ngStyle]="{'width': ((week[infect].inf[1] + 1) * 10) + '%'}"></div>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="week && week[infect]['img'] && week[infect]['img']['msg']" style="width: 100%;margin-top: 20px;">
            {{week[infect]['img']['msg']}}
          </div>
        </div>
      </div>
      <div class="" style="margin-top: 15px;  width: 100%;" *ngIf="week.pestControl">
        <h2 class="pests-heading">{{'Odporúčané ošetrenie' |translate}}</h2>
        <p style="  font-size: 20px;
                                                        font-weight: 600;
                                                        margin-bottom: 40px;
                                                        padding: 16px 20px;
                                                        background-color: #f0df9e;
                                                        border-radius: 6px;">
          {{week.pestControlMsg[0]}}
        </p>
        <div class="accordion-wrapper" [ngClass]="{'pestsShown': pestsShown}" *ngIf="week.pestControl.length > 0">
          <div *ngIf="week.pestControl" class="pestControl">

            <div *ngFor="let pestControlItem of week.pestControl;let i = index;" class="pestControlItem">
              <h2 class="odporucanieHeading">{{'Odporúčanie' | translate}} {{i+1}}</h2>
              <i style="">{{pestControlItem.msg}}</i>
              <div>
                <div style=" margin-top: 35px;">
                  <div *ngFor="let prescriptArrays of pestControlItem.prescript; let j = index;" class="row">
                    <div class="pripravok" *ngFor="let pest of prescriptArrays; let k = index;" [ngStyle]="{'display': !pest.product? 'none':'block'} ">
                      <div class="pest-header" [ngClass]="{'active': activeIndex === i}">
                        <div>
                          <h3 style="font-weight: 600;font-size: 17px;margin-bottom: 0;">{{pest.product}} <small style="margin-left:8px;" *ngIf="pest.vendor">({{pest.vendor}})</small>
                          </h3>
                          <i style="margin-bottom: 7px;" *ngFor="let substance of pest.substances;let k = index;">{{substance}}<span *ngIf="k < pest.substances.length-1">, </span></i>
                        </div>
                      </div>
                      <div>
                        <span *ngIf="pest.notes">{{pest.notes[0]}}</span>
                      </div>
                      <div *ngIf="pest.addresses && pest.addresses.length>0">
                        <a href="{{url}}" target="_blank" class="" style="font-weight: 600;margin-right: 7px;padding: 5px 0;" *ngFor="let url of pest.addresses">{{"Viac info" | translate}}...</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="week.pestControlAlternative && week.pestControlAlternative.length">
        <h2 class="pests-heading">{{'Alternatívne ošetrenia' | translate}}</h2>
        <div class="pestControlAlternativeMsg">
          <p *ngFor="let msg of week.pestControlAlternativeMsg">{{msg}}</p>
        </div>
        <div class="pestControl">
          <div *ngFor="let pestControlItem of week.pestControlAlternative;let i = index;" class="pestControlItem">
            <h2 class="odporucanieHeading">{{"Odporúčanie"|translate}} {{i+1}}</h2>
            <i style="">{{pestControlItem.msg}}</i>
            <div>
              <div style="display: flex; justify-content: space-between;margin-top: 0;padding-left: 15px;">
                <div *ngFor="let prescriptArrays of pestControlItem.prescript; let j = index;" class="prescriptArrays">
                  <div *ngFor="let pest of prescriptArrays; let k = index;" [ngStyle]="{'display': !pest.product? 'none':'block'} " class="pripravok-list-item">
                    <div class="pest-header" [ngClass]="{'active': activeIndex === i}">
                      <div>
                        <h3 style="font-weight: 600;font-size: 17px;margin-bottom: 0;">{{pest.product}} <small style="margin-left:8px;" *ngIf="pest.vendor">({{pest.vendor}})</small>
                        </h3>
                        <i style="margin-bottom: 7px;" *ngFor="let substance of pest.substances;let k = index;">{{substance}}<span *ngIf="k < pest.substances.length-1">, </span></i>
                      </div>
                    </div>
                    <div>
                      <span *ngIf="pest.notes">{{pest.notes[0]}}</span>
                    </div>
                    <div *ngIf="pest.harvestPeriod">
                      <span style="font-weight: 500;">{{'Ochranná doba pred zberom' | translate}}:</span><span>{{pest.harvestPeriod}} {{'dní' | translate}}</span>
                    </div>
                    <div *ngIf="pest.addresses && pest.addresses.length>0">
                      <a href="{{url}}" target="_blank" class="" style="font-weight: 600;margin-right: 7px;padding: 5px 0;" *ngFor="let url of pest.addresses">Viac info...</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="" style="margin-top: 15px;  width: 100%;" *ngIf="week.works">
        <h2 class="pests-heading">{{'Current Phenophase Tips' | translate}}</h2>
        <div>
          <ul>
            <li *ngFor="let work of week.works">{{work}}</li>
          </ul>
          <img src="https://2024.galati.sk/{{week.worksImage}}" alt="" class="worksImage">
          </div>
      </div>

      <mat-divider *ngIf="week.pestManagement && week.pestManagement.pesticides">
      </mat-divider>
      <div class="disease-content-wrapper" *ngIf="week.pestManagement  && week.pestManagement.pesticides  && week.pestManagement.pesticides.length > 0">
        <div class="disease-name">{{"Použité prípravky" | translate}}</div>
        <mat-list *ngIf="vineyard.galatiSeason.vineyard.diseaseManagementScheme !== 'expert'">
          <mat-list-item *ngFor="let pest of week.pestManagement.pesticides">
            <a href="{{url}}" target="_blank" *ngFor="let url of pest.addresses"><b>{{pest.product | slice:0:135}}
                <span *ngIf="pest.product.length > 20">...</span></b></a> <small style="margin-left:15px;" *ngIf="pest.vendor">({{pest.vendor}})</small> <span style="color:black;margin-left:10px;margin-right: 15px; padding-right:15px;">
              <span class="pest-infect" *ngIf="pest.intensit && pest.intensity.m>0">{{ "infect.m" | translate | slice:0:3 }}. </span>
              <span class="pest-infect" *ngIf="pest.intensit && pest.intensity.p>0">{{ "infect.p" | translate | slice:0:3 }}. </span>
              <span class="pest-infect" *ngIf="pest.intensit && pest.intensity.b>0">{{ "infect.b" | translate | slice:0:3 }}.</span>
            </span>
            </mat-list-item>
            </mat-list>
            <mat-list *ngIf="vineyard.galatiSeason.vineyard.diseaseManagementScheme === 'expert'">
              <mat-list-item *ngFor="let pest of week.pestManagement.pesticides">
                <b>{{pest.product | slice:0:135}}<span *ngIf="pest.product.length > 20">...</span></b>
                <span style="color:black;margin-left:10px;margin-right: 15px; padding-right:15px;">
                  <span class="pest-infect" *ngIf="pest.intensit && pest.intensity.m>0">{{ "infect.m" | translate | slice:0:3 }}. </span>
                  <span class="pest-infect" *ngIf="pest.intensit && pest.intensity.p>0">{{ "infect.p" | translate | slice:0:3 }}. </span>
                  <span class="pest-infect" *ngIf="pest.intensit && pest.intensity.b>0">{{ "infect.b" | translate | slice:0:3 }}.</span>
                </span>
              </mat-list-item>

        </mat-list>
      </div>
    </div>
  </div>
  <div class="card-body"></div>
</div>