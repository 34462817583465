<div class="logo">
  <a [routerLink]="['/']" class="simple-text">
    <div class="logo-img">
      <img src="/assets/img/vinlink-logo.svg" alt="VinLink Logo" />
      <img src="/assets/img/GALATI-logo_black.svg" class="galati-logo" alt="GALATI Logo" />
    </div>
  </a>
</div>

<div class="sidebar-wrapper">
  <div *ngIf="isMobileMenu()">
    <ul class="nav navbar-nav nav-mobile-menu">
      <li class="nav-item dropdown">
        <a class="nav-link" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <mat-icon>language</mat-icon>
          <span class="lanaguage-name" style="margin-left:7px;">{{global.lang}}</span>
        </a>
        <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownMenuLink">
          <!-- <a class="dropdown-item" [ngClass]="{active: global.lang=='en'}" (click)="useLanguage('en');global.lang='en'">{{"English" | translate}}</a> -->
          <a class="dropdown-item" [ngClass]="{active: global.lang=='sk'}" (click)="authService.useLanguage('sk');global.lang='sk'">{{"Slovak" | translate}}</a>
          <a class="dropdown-item" [ngClass]="{active: global.lang=='cz'}" (click)="authService.useLanguage('cz');global.lang='cz'">{{"Czech" | translate}}</a>
          <a class="dropdown-item" [ngClass]="{active: global.lang=='hu'}" (click)="authService.useLanguage('hu');global.lang='hu'">{{"Hungarian" | translate}}</a>
          <a class="dropdown-item" [ngClass]="{active: global.lang=='en'}" (click)="authService.useLanguage('en');global.lang='en'">{{"English" | translate}}</a>
        </div>
      </li>
    </ul>
    <hr>

  </div>
  <ul class="nav">
    <li routerLinkActive="active" *ngFor="let menuItem of menuNew" class="{{ menuItem.class }} nav-item">
      <a *ngIf="!menuItem.restricted" class="nav-link" [routerLink]="[menuItem.path]" style="position: relative;      z-index: 1;">
        <i class="material-icons" *ngIf="menuItem.icon !== 'ownfont'">{{ menuItem.icon }}</i>
        <i class="fi {{ menuItem.icon_code }}" *ngIf="menuItem.icon === 'ownfont'"></i>
        <!-- <img src="{{ menuItem.svgPath }}" *ngIf="menuItem.icon === 'svg'" alt="" srcset=""> -->
        <p translate="{{menuItem.title}}"></p>
      </a>
      <div class="collapse show" id="components" *ngIf="menuItem.vineyards">
        <ul class="nav" style="  margin-top: 9px;
        margin-bottom: 20px;">
          <li class="subitem ">
            <a class="nav-link all-vineyards all-vineyards" [routerLink]="['/vineyards']"> {{"Všetky vinohrady" | translate}} </a>
          </li>
          <li class="subitem" *ngFor="let vineyard of menuItem.vineyards">
            <a class="nav-link" routerLink="/vineyards/{{vineyard.ActivationKey}}" routerLinkActive="nav-link-active">
              <mat-icon style="font-size: 8px;color: gray;height: 8px;margin-right: -7px;margin-left: 15px;">circle</mat-icon>
              {{vineyard.Alias}}
            </a>
          </li>
        </ul>
      </div>
    <div class="collapse" [ngClass]="{'show':  ['/docs','/manual','/phenos'].includes(currentRoute.url)}" *ngIf="currentRoute">
      <ul class="nav" style="margin-bottom: 20px;margin-top:0;">
        <li class="subitem " *ngFor="let subItem of menuItem.sub">
          <a class="nav-link" [routerLink]="[subItem.path]" routerLinkActive="active">
            <mat-icon style="font-size: 26px;color: gray;height: 26px; margin-left: 15px; margin-right: 12px;">{{subItem.icon}}</mat-icon>
            {{subItem.title | translate}}
          </a>
        </li>
      </ul>
    </div>
    </li>
  </ul>
  <ul class="nav" *ngIf="isMobileMenu()">
    <li class="nav-item">
      <a class="nav-link" (click)="authService.userLogout();ss.myVineyards = null">
        <i class="material-icons">perm_identity</i>
        <p>{{'Log Out' | translate}}</p>
      </a>
    </li>
  </ul>
</div>