import { Component, OnInit, ViewChild, Input, Inject } from "@angular/core";
import "rxjs/add/operator/filter";
import "rxjs/add/operator/map";
import { format } from "date-fns";
/* Calendar */
import { Calendar } from "@fullcalendar/core";
import dayGridPlugin from "@fullcalendar/daygrid";
import list from "@fullcalendar/list";
import listWeek from "@fullcalendar/list";
import removeEvents from "@fullcalendar/core/api/EventApi";
import interactionPlugin from "@fullcalendar/interaction";
import { trigger, transition, style, animate } from "@angular/animations";
import { Vineyard } from "app/_classes/vineyard";
import { TranslateService } from "@ngx-translate/core";
import { GlobalService } from "app/_services/global.service";
import skLocale from "@fullcalendar/core/locales/sk";
import { untilDestroyed } from "ngx-take-until-destroy";
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from "@angular/material/dialog";
import { DialogData } from "app/user-profile/user-profile.component";
import { FormControl, Validators } from "@angular/forms";
import { AuthenticationService } from "app/_services/authentication.service";
import { NotificationsService } from "app/_services/notifications.service";
import { VineyardService } from "app/_services/vineyard.service";
import firebase from 'firebase';
import { AngularFirestore } from '@angular/fire/firestore';
import { timestamp } from 'rxjs/operators';
// import { until } from 'protractor';

// declare var $: any;
@Component({
  selector: "app-calendar",
  templateUrl: "./calendar.component.html",
  styleUrls: ["./calendar.component.scss"],
  animations: [
    trigger("inOutAnimation", [
      transition(":enter", [
        style({ opacity: 0 }),
        animate(".25s ease-out", style({ opacity: 1 })),
      ]),
      transition(":leave", [
        style({ opacity: 1 }),
        animate(".25s ease-in", style({ opacity: 0 })),
      ]),
    ]),
  ],
})
export class CalendarComponent implements OnInit {
  @Input() public calendarID: string;
  // @Input() public ActivationKey: string;
  @Input() public vineyard: Vineyard;

  date;
  selectedDate;
  events = [];
  calendar: Calendar;
  // scope = this;

  loadingFinished: boolean;

  name = "test";
  nameTwo = "nameTwo";
  locale = this.global.lang;
  user: any;
  allVineyards: any;
  timestamp: firebase.firestore.FieldValue;
  calendarDocs: any;
  workActivies = [
    { name: "strihanie", value: "strihanie" },
    { name: "viazanie", value: "viazanie" },
    { name: "kopanie", value: "kopanie" },
    { name: "zelené práce", value: "zelene" },
    { name: "striekanie", value: "striekanie" },
    { name: "snimanie", value: "snimanie" },
    { name: "oberanie", value: "oberanie" },
    { name: "iné", value: "ine" },
  ];

  constructor(
    public notif: NotificationsService,
    public dialog: MatDialog,
    public translate: TranslateService,
    public global: GlobalService,
    public vineyardService: VineyardService,
    public afs: AngularFirestore,
    public authService: AuthenticationService
  ) {
    this.authService
      .userObs()
      .pipe(untilDestroyed(this)) 
      .subscribe((userData) => {
        this.user = userData;
      });
    // this.vineyardService.vineyardsBehaviorSubject.subscribe((vineyards) => {
    //   this.allVineyards = vineyards;
    // });
  }

  eventClickFn(eventInfo) {
    let eventID, date, work, note;
    date = eventInfo.event._instance.range.start;
    work = eventInfo.event._def.extendedProps.work;
    note = eventInfo.event._def.extendedProps.note;
    eventID = eventInfo.event._def.extendedProps.eventID

    const dialogRef = this.dialog.open(DialogAddEvent, {
      width: "360px",
      data: {
        workActivities: this.workActivies,
        name: this.name,
        date: date,
        locale: this.locale,
        work: work,
        note: note,
      },
    });
    dialogRef
      .afterClosed()
      .pipe(untilDestroyed(this))
      .subscribe((result) => {
        if (result && (result.work || result.note)) {
          this.afs.collection('Vineyards').doc(this.vineyard.ActivationKey).collection("Calendar").doc(eventID).update({
            date: format(date, "yyyy-MM-dd"),
            eventID: eventID,
            work: result.work,
            note: result.note,
            updatedAt: this.timestamp
          }).then(res => {
            console.log("calendar set", res);
            this.notif.toaster(
              this.translate.instant("EVENT_SAVED"), "", "success"
            );
          })
        }
      });


  }
  dateClickFn(info) {
    console.log("date click info", info)

    let eventID, date, work, note;
    date = info.date;

    const dialogRef = this.dialog.open(DialogAddEvent, {
      width: "360px",
      data: {
        workActivities: this.workActivies,
        name: this.name,
        date: date,
        locale: this.locale,
        work: work,
        note: note,
      },
    });

    dialogRef
      .afterClosed()
      .pipe(untilDestroyed(this))
      .subscribe((result) => {
        console.log("dialog ref after closed", result)
        if (result && (result.work || result.note)) {
          const tempID = this.makeid(20);
          this.afs.collection('Vineyards').doc(this.vineyard.ActivationKey).collection("Calendar").doc(tempID).set({
            eventID: tempID,
            date: format(date, "yyyy-MM-dd"),
            work: result.work || "",
            note: result.note || "",
            updatedAt: this.timestamp
          }).then(res => {
            console.log("calendar set", res);
            this.notif.toaster(
              this.translate.instant("EVENT_SAVED"), "", "success"
            );
          })
        }
      });
  }
  makeid(length) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
  }
  eventTitleFn(work, note, begginning) {
    let title = "";
    if (begginning) {
      title += begginning + ": ";
    }
    if (work) {
      title += this.translate.instant(work);
      if (note) {
        title += "\n" + note;
      }
    } else if (note) {
      title += note;
    }
    return title;
  }
  getVineyardByActivationKey(ActivationKey) {
    let vineyard = this.vineyardService.myVineyards.filter(
      (v) => v.ActivationKey === ActivationKey
    );
    console.log("vineyard", vineyard);
    return vineyard[0];
  }

  ngOnInit() {


    this.timestamp = firebase.firestore.FieldValue.serverTimestamp();
    // const scope = this;
    const calendarEl = document.getElementById("calendar");

    this.calendar = new Calendar(calendarEl, {
      plugins: [dayGridPlugin, interactionPlugin, list, listWeek],
      editable: true,
      contentHeight: "auto",
      handleWindowResize: true,
      showNonCurrentDates: false,
      firstDay: 1,

      buttonText: {
        today: this.translate.instant("today"),
        month: this.translate.instant("month"),
        week: this.translate.instant("week"),
        day: this.translate.instant("day"),
        list: this.translate.instant("list"),
      },

      dateClick: (info) => {
        this.dateClickFn(info);
      },
      eventClick: (event) => {
        this.eventClickFn(event);
      },
      eventDrop: (info) => {
        const dateStr = format(info.event.start, 'yyyy-MM-dd');
        console.log(info);
        this.afs.collection("Vineyards").doc(this.vineyard.ActivationKey).collection("Calendar").doc(info.event.extendedProps.eventID).update({
          date: dateStr,
          work: info.event.extendedProps.work,
          note: info.event.extendedProps.note,
          updatedAt: this.timestamp
        })
          .then((res) => {
            console.log("CRUD res: ", res);
            this.notif.toaster(
              this.translate.instant("EVENT_SAVED"), "", "success"
            );
          })
          .catch((err) => {
            console.log("err", err);
            info.revert();
          });
      },
    });

    console.log("teraz nastane get events");


    this.global.langObservable.pipe(untilDestroyed(this)).subscribe((lang) => {
      console.log("lang changed", lang);
      this.calendar.setOption("locale", lang);

      // 
      // FIRESTORE SUBSCRIBE
      //       

      let eventCollection = this.afs.collection("Vineyards").doc(this.vineyard.ActivationKey).collection("Calendar");
      this.calendarDocs = eventCollection.valueChanges();
      this.calendarDocs.subscribe(res => {
        this.events = [];
        // alert("calendar get subscribe");
        this.loadingFinished = true;
        console.log("get events: ", res);
        res.forEach((event) => {
          // let event = result.data();
          event.classNames = `btn event-${event.work}`;
          event.title = this.eventTitleFn(
            event.work,
            event.note,
            ""
          );
          this.events.push(event);
          this.calendar.removeAllEvents();
          const eventSource = this.calendar.getEventSources()[0];
          if (eventSource) {
            eventSource.remove();
          }
          this.calendar.addEventSource(this.events);
          this.calendar.render();

        })
      }, (err) => {
        this.loadingFinished = true;
        this.calendar.render();
        console.log("nastal catch v get events", err);
      })
    });

    this.calendar.render();
  }



  ngOnDestroy() {
    // To protect you, we'll throw an error if it doesn't exist.
  }
}

@Component({
  selector: "dialog-add-event",
  templateUrl: "../dialogs/calendar/dialog-add-event.html",
  styleUrls: ["../dialogs/calendar/dialog-add-event.scss"],
})
export class DialogAddEvent {
  constructor(
    public dialogRef: MatDialogRef<DialogAddEvent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }
}
