import { Injectable, NgZone } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/auth";
import { NotificationsService } from "./notifications.service";
import { Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { GlobalService } from "./global.service";
import { TranslateService } from "@ngx-translate/core";
import { Observable } from "rxjs/Observable";
import { untilDestroyed } from "ngx-take-until-destroy";
import { BehaviorSubject, observable } from "rxjs";
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireFunctions } from '@angular/fire/functions';
@Injectable({
  providedIn: "root",
})
export class AuthenticationService {

  public user; // Adjusted for AngularFireAuth user Observable

  verifNeeded = false;
  authState: any = null;
  notificationsEmailAddress;
  vinlinkUser: any;
  vinlinkUserBeh: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  accessResult: boolean;

  constructor(
    private fireAuth: AngularFireAuth,
    private notif: NotificationsService,
    private http: HttpClient,
    private router: Router,
    private ngZone: NgZone,
    public translate: TranslateService,
    public global: GlobalService,
    public afs: AngularFirestore,
    private cloudFunctions: AngularFireFunctions
  ) {
    this.fireAuth.authState.subscribe(authState => {
      this.authState = authState;
    });
    this.checkSpecialAccess() 


    this.global.langObservable
      .pipe(untilDestroyed(this))
      .subscribe((lang) => (this.fireAuth.auth.languageCode = lang));
  }
  isLoggedIn(): Observable<boolean> {
    return this.fireAuth.authState.pipe().map(auth => !!auth);
  }
  userObs(): Observable<any> {
    // Adjusted to directly use AngularFireAuth observable
    return this.fireAuth.user;
  }

  getUser() {
    return new Promise<any>((resolve, reject) => {
      this.fireAuth.authState.pipe(untilDestroyed(this)).subscribe((user) => {
        if (user) {
          resolve(user);
        }
      });
    });
  }

  useLanguage(language: string) {
    this.translate.use(language);
    this.global.lang = language;
    this.global.langObservable.next(language);
    this.afs.collection("users").doc(this.user.uid).update({ lang: this.global.lang }).then(res => {
      this.notif.toaster(
        this.translate.instant("Language settings saved successfully"),
        "",
        "success"
      );
      this.global.lang = language;
      this.global.langObservable.next(language);
      this.translate.use(language);
    }).catch(err => {
      console.log("use language err", err);
      this.notif.toaster(
        "Language settings were not saved",
        "",
        "warning"
      );
    })

  }
  userLogout(message = this.translate.instant("You logged out")) {
    this.fireAuth.auth
      .signOut()
      .then((res) => {
        console.log("log out result:", res);
      })
      .catch((err) => console.log("log out err:", err));
    this.user = null;
    this.notif.toaster(message, "person", "warning");
    this.router.navigate(["/"]);
  }
  passwordReset() {
    this.global.langObservable
      .pipe(untilDestroyed(this))
      .subscribe((lang) => (this.fireAuth.auth.languageCode = lang));

    this.fireAuth.auth
      .sendPasswordResetEmail(this.user.email)
      .then((data) => {
        console.log(data);
        this.notif.toaster(
          `${this.translate.instant("Reset link sent to")} ${this.user.email}`,
          "how_to_reg",
          "success"
        );
      })
      .catch((error) => {
        this.notif.toaster(
          this.translate.instant(error),
          "person_add_disabled",
          "danger"
        );
      });
  }
  sendVerificationMail() {
    return this.fireAuth.auth.currentUser
      .sendEmailVerification()
      .then(() => {
        this.router.navigate(["/login"]);
        this.notif.toaster(
          this.translate.instant("Verification mail sent to") +
          this.fireAuth.auth.currentUser.email,
          "person_add_disabled",
          "success"
        );
      })
      .catch((error) => {
        this.notif.toaster(
          this.translate.instant(error),
          "person_add_disabled",
          "success"
        );
      });
  }
  // userRegistration(email, password, name) {
  //   this.fireAuth.auth
  //     .createUserWithEmailAndPassword(email, password)
  //     .then((data) => {
  //       console.log("reg data", data);
  //       data.user
  //         .updateProfile({
  //           displayName: name
  //         })
  //         .then((result) => {
  //           console.log(" firebase user registration res:", result);
  //           this.afs.collection("users").doc(data.user.uid).set({ notificationsEmail: data.user.email, lang: this.global.lang ? this.global.lang : "sk" }, { merge: true }).then(afsRes => {
  //             console.log("afsRes", afsRes, "user saved to firestore");
  //           }).catch(afsError => {
  //             console.log("afs error", afsError)
  //           })
  //           this.sendVerificationMail();
  //         });
  //     })
  //     .then((data) => {
  //       console.log("data-", data)
  //     })
  //     .catch((error) => {
  //       // Handle Errors here.
  //       var errorCode = error.code;
  //       var errorMessage = error.message;

  //       this.notif.toaster(
  //         this.translate.instant(errorMessage)
  //       );
  //       console.log(error);
  //     });
  // }


  async userRegistration(email, password, name) {
    try {
      const data = await this.fireAuth.auth.createUserWithEmailAndPassword(email, password);
      console.log("reg data", data);

      await data.user.updateProfile({
        displayName: name,
      });
      console.log("firebase user registration res:", data.user);

      await this.afs.collection("users").doc(data.user.uid).set(
        {
          notificationsEmail: data.user.email,
          lang: this.global.lang ? this.global.lang : "sk",
        },
        { merge: true }
      );
      console.log("user saved to firestore");

      this.sendVerificationMail();
      this.userLogout();
      this.notif.toaster(
        this.translate.instant("Verification mail sent to") +
        this.fireAuth.auth.currentUser.email,
        "person_add_disabled",
        "success", null, "10000"
      );
    } catch (error) {
      const errorMessage = error.message;

      this.notif.toaster(this.translate.instant(errorMessage));
      console.log(error);
    }
  }



  userLogin(username, password) {
    this.fireAuth.auth
      .signInWithEmailAndPassword(username, password)
      .then((data) => {

        if (data.user.emailVerified !== true) {
          this.sendVerificationMail();
          // this.notif.toaster(
          //   this.translate.instant("Email_Validation_Sent_Message"),
          //   "how_to_reg",
          //   "warning"
          // );
          // this.ngZone.run(() => this.router.navigate(["/"]));
        } else {
          this.user = data.user;
          this.notif.toaster(
            `${this.translate.instant("Welcome")} ${data.user.displayName}`,
            "how_to_reg",
            "success"
          );
          // this.router.navigate(["/"]);
        }

        this.afs.collection("users").doc(`${data.user.uid}`).get().toPromise().then(doc => {
          if (!doc.exists) {
            this.afs.collection("users").doc(`${data.user.uid}`).set({
              displayName: data.user.displayName,
              notificationsEmail: data.user.email,
              phone: data.user.phoneNumber,
              lang: this.global.lang,
              wantSMS: false
            }).then(result => {
              console.log("user added to db because it is his first log in", result)
            }).catch(err => {
              console.warn("db user err", err)
            })
          } else { }
        })
      })
      .catch((error) => {
        // console.warn("sign in data", error);
        this.notif.toaster(
          `${this.translate.instant(error.message)}`,
          "error",
          "danger"
        );
      });
    console.log(username);
  }
  checkSpecialAccess() {
    const checkAccessFn = this.cloudFunctions.httpsCallable('checkSpecialAccess');
    checkAccessFn({}).toPromise().then(res => {
      this.accessResult = res.hasSpecialAccess;
    })
    return checkAccessFn({});
  }

  ngOnDestroy() {
    // To protect you, we'll throw an error if it doesn't exist.
  }
}
