<!-----WIZARD------>
<div class="lightbox-bg" style="z-index: 980;" (click)="wizardClose()"></div>
<div class="container wizard-wrapper" style="z-index:999;">
  <div class="col-sm-12 col-md-12 col-lg-12 span12">
    <div class="wizard-container">
      <div class="card wizard-card" data-color="green" id="wizard"> <i class="material-icons hide-wizard" (click)="wizardClose()">clear</i>
        <div class="wizard-header">
          <h2 class="wizard-title">{{"New Vineyard" |translate}}</h2>
          <h5> {{"We need some info before assigning you your new VinLink Vineyard." | translate }} </h5>
        </div>
        <mat-horizontal-stepper [linear]="isLinear" #stepper (selectionChange)="setIndex($event)" (click)="triggerClick()">
          <mat-step [completed]="step1Finished" label='{{ "Vineyard Position" | translate }}'>
            <div class="tab-pane" id="details">
              <div class="row">
                <div class="col-sm-12">
                  <h4 class="info-text not-mobile"> {{ "Click inside the map where your VinLink Vineyard is." | translate }} </h4>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-12">
                  <div leaflet style="height: 400px;resize: vertical;" class="map" (leafletClick)="mapClick($event)" (leafletMapReady)="onMapReady($event)"></div>
                </div>
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-md-3">
                    <mat-form-field class="example-full-width"> <input matInput placeholder="{{'Latitude' | translate}}" value="{{newVineyard.lat}}" name="newVineyard.lat" required /> </mat-form-field>
                  </div>
                  <div class="col-md-3">
                    <mat-form-field class="example-full-width"> <input matInput placeholder="{{'Longitude' | translate}}" value="{{ newVineyard.lng }}" /> </mat-form-field>
                  </div>
                  <div class="col-md-3">
                    <mat-form-field class="example-full-width"> <input matInput placeholder="{{'Altitude' | translate}}" value="{{ newVineyard.alt }}" name="newVineyard.alt" /> </mat-form-field>
                  </div>
                  <div class="col-md-3">
                    <mat-form-field class="example-full-width"> <input matInput placeholder="{{'City' | translate}}" value="{{ newVineyard.city_district }}" name="newVineyard.city_district" /> </mat-form-field>
                  </div>
                </div>
              </div>
            </div>
            <div class="wizard-footer">
              <div class="pull-right"> <button [disabled]="!averages30y" class="btn btn-primary" type="button" translate="Next" matStepperNext (click)="finishStep1()">
                  <span *ngIf="!loadAverages">{{"Next" | translate}}</span>
                  <span *ngIf="loadAverages && !averages30y" style="display: flex; align-items: censter;
                                ">
                    <mat-spinner *ngIf="loadAverages && !averages30y" style="display: inline-block;margin-right: 10px;" [diameter]="15"></mat-spinner> {{"Loading" | translate}}</span>
                </button> </div>
              <div class="clearfix"></div>
            </div>
          </mat-step>
          <mat-step [completed]="step2Finished">
            <ng-template matStepLabel>
              <div (click)="finishStep1()">{{ "Lane Orientation" | translate }}</div>
            </ng-template>
            <div class="tab-pane" id="orientation">
              <div class="row">
                <div class="col-sm-12">
                  <h4 class="info-text"> {{"Click on the begginning & end of your vineyard lane to roughly set its orientation." | translate}} </h4>
                  <h5 class="info-title" *ngIf="newVineyard.laneOrientation"> {{"Your vineyards lane orientation is" | translate }} {{ newVineyard.laneOrientation | translate}}</h5>
                </div>
              </div>
              <div class="map" id="map2" leaflet style="height: 400px;resize: vertical;" (leafletClick)="map2Click($event)" (leafletMapReady)="onMap2Ready($event)"></div>
            </div>
            <div class="wizard-footer">
              <div class="pull-right"> <button class="btn btn-primary" type="button" (click)="finishStep2()" translate="Next" matStepperNext (click)="finishStep2()"></button> </div>
              <div class="clearfix"></div>
            </div>
          </mat-step>
          <!-- <mat-step [stepControl]="step3Finished"> -->
          <mat-step [completed]="step3Finished">
            <ng-template matStepLabel>
              <div (click)="finishStep2()">{{ "Vineyard activation" | translate }}</div>
            </ng-template>
            <div class="tab-pane" id="activation">
              <h4 class="info-text">{{ "Vineyard activation" | translate }}</h4>
              <div class="col-lg-12 activationForm" [formGroup]="activationForm">
                <div class="row">
                  <div class="col-lg-6 m0a">
                    <mat-form-field class="example-full-width">
                      <input [errorStateMatcher]="matcher" required formControlName="stationAlias" matInput placeholder="{{ 'Vineyard / Vineyard name' | translate }}" [(ngModel)]="newVineyard.Alias" name="newVineyard.Alias" /> <i matSuffix matTooltip="{{ 'The display name of your station / vineyard' | translate }}" class="material-icons material-icons--outlined ">info</i>
                    </mat-form-field>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-6 m0a">
                    <mat-form-field class="example-full-width">
                      <mat-label>{{ 'Date of station installation in the vineyard' | translate }}</mat-label>
                      <input [errorStateMatcher]="matcher" formControlName="inVineyardSince" matInput type="date" [(ngModel)]="newVineyard.inVineyardSince" />
                    </mat-form-field>
                  </div>
                </div>
                <!-- <div class="row">
                  <div class="col-lg-6 m0a">
                    <input type="date" name="" id="">
                    <mat-form-field class="example-full-width"> <input matInput [(ngModel)]="newVineyard.inVineyardSince" [ngModelOptions]="{ standalone: true }" [matDatepicker]="inVineyardSince" placeholder="{{ 'Date of station installation in the vineyard' | translate }}" />
                      <mat-datepicker-toggle matSuffix [for]="inVineyardSince"></mat-datepicker-toggle>
                      <mat-datepicker #inVineyardSince></mat-datepicker>
                    </mat-form-field>
                  </div>
                </div> -->
                <div class="row">
                  <div class="col-lg-6 m0a">
                    <mat-form-field class="example-full-width">
                      <input [errorStateMatcher]="matcher" formControlName="stationID" matInput placeholder="{{'Activation Key' | translate }}" [(ngModel)]="newVineyard.ActivationKey" name="newVineyard.ActivationKey" /> <i matSuffix matTooltip="{{ 'ID number you received with your station' | translate }}" class="material-icons material-icons--outlined">info</i>
                    </mat-form-field>
                  </div>
                </div>
                <div class="row" *ngIf="scheme">
                  <div class="col-lg-6 m0a">
                    <mat-form-field class="example-full-width">
                      <mat-label>{{'Protection scheme' | translate}}</mat-label>
                      <mat-select [errorStateMatcher]="matcher" formControlName="diseaseManagementScheme" [(value)]="newVineyard.diseaseManagementScheme" [(ngModel)]="newVineyard.diseaseManagementScheme">
                        <mat-option *ngFor="let schemeItem of scheme.options" value="{{schemeItem.value}}">{{ schemeItem.msg }} </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
                <div class="row" *ngIf="scheme">
                  <div class="col-lg-6 m0a" style="margin:10px auto;">
                    <mat-accordion>
                      <mat-expansion-panel>
                        <mat-expansion-panel-header>
                          <mat-panel-title> {{"Disease protection scheme hint" | translate}} </mat-panel-title>
                        </mat-expansion-panel-header>
                        <ul>
                          <li *ngFor="let schemeItem of scheme.options"> <b>{{schemeItem.msg}}:</b> {{schemeItem.info}}</li>
                        </ul>
                      </mat-expansion-panel>
                    </mat-accordion>
                  </div>
                </div>
                <div class="row" *ngIf="usage">
                  <div class="col-lg-6 m0a">
                    <mat-form-field class="example-full-width">
                      <mat-label>{{usage.msg}}</mat-label>
                      <mat-select [errorStateMatcher]="matcher" formControlName="usage" [(value)]="newVineyard.usage" [(ngModel)]="newVineyard.usage">
                        <mat-option value="{{usageItem.value}}" *ngFor="let usageItem of usage.options">{{usageItem.msg}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
                <div class="row" *ngIf="usage">
                  <div class="col-lg-6 m0a" style="margin:10px auto;">
                    <mat-accordion>
                      <mat-expansion-panel>
                        <mat-expansion-panel-header>
                          <mat-panel-title>Vysvetlivka: {{usage.msg}}</mat-panel-title>
                        </mat-expansion-panel-header>
                        <ul>
                          <li *ngFor="let usageItem of usage.options"> <b>{{usageItem.msg}}:</b> {{usageItem.info}}</li>
                        </ul>
                      </mat-expansion-panel>
                    </mat-accordion>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-6 m0a">
                    <mat-form-field class="example-full-width">
                      <mat-label>{{'vine variety' | translate}}</mat-label>
                      <mat-select multiple [errorStateMatcher]="matcher" max="5" formControlName="outOdroda" [(value)]="outOdroda" (selectionChange)="max5()" [(ngModel)]="outOdroda">
                        <mat-option *ngFor="let odroda of odrody" [value]="odroda" [disabled]="outOdroda && outOdroda.length >= 5 && !outOdroda.includes(odroda)"> {{odroda.name}} </mat-option>
                      </mat-select> <i matSuffix matTooltip="{{ 'Choose all vine types you produce here.' | translate }}" class="material-icons material-icons--outlined ">info</i>
                    </mat-form-field>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-6 m0a">
                    <mat-form-field class="example-full-width">
                      <mat-label>{{'Terrain sensitivity' | translate}}</mat-label>
                      <mat-select [errorStateMatcher]="matcher" formControlName="terrainSensitivity" [(value)]="newVineyard.terrainSensitivity" [(ngModel)]="newVineyard.terrainSensitivity">
                        <mat-option *ngFor="let sensitivity of terrainSensitivity" [value]="sensitivity.value"> <b>{{sensitivity.msg}} </b> <span *ngIf="sensitivity.info" style="  font-size: 12px;"> ({{sensitivity.info}})</span> </mat-option>
                      </mat-select>
                    </mat-form-field>
                    <div *ngIf="newVineyard.terrainSensitivity=== '-1'" style="color: #b95b2d;font-size: 13px;font-weight: 500;margin-bottom: 10px;">{{"Are you sure? Extreme sensitivity means strong chemical spraying." | translate}}</div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-6 m0a" style="margin:10px auto;">
                    <mat-accordion>
                      <mat-expansion-panel>
                        <mat-expansion-panel-header>
                          <mat-panel-title> {{"Terrain sensitivity hint" | translate}} </mat-panel-title>
                        </mat-expansion-panel-header> <img src="assets/img/citlivost_polohy_new.png" alt="" srcset="" style="max-width:100%;">
                      </mat-expansion-panel>
                    </mat-accordion>
                  </div>
                </div>
                <div class="col-lg-12">
                  <div class="col-lg-6 m0a" style="margin-top:20px;">
                    <button type="button" (click)="onFormSubmit(newVineyard)" [ngClass]="{'disabled': !activationForm.valid}" [disabled]="!activationForm.valid" class="btn btn-primary" matStepperNext type="button"> <span *ngIf="!activationForm.valid">{{'Fill out all the fields' | translate}}</span><span *ngIf="activationForm.valid">{{ 'Save station & vineyard' | translate }}</span></button>
                  </div>
                </div>
              </div>
            </div>
          </mat-step>
          <mat-step [completed]="this.StAddedSuccessfully === true">
            <ng-template matStepLabel>
              <div>{{ "Finish setup" | translate }}</div>
            </ng-template>
            <div class="tab-pane tac" id="activation">
              <h4 class="info-text">{{ "Finish setup" | translate }}</h4>
              <div class="row">
                <div class="col-lg-6 m0a" style="margin:10px auto;">
                  {{"We higly recommend you to add a reminder to your calendar so you won't forget to submit the estimated phenological phase of your vineyard on weekly basis." | translate }}
                  <br>
                  {{ "Please use the button below to add the event to your calendar." | translate}}
                </div>
              </div>
              <div title="Add to Calendar" data-dropdown-y="up" class="addeventatc btn btn-outline-secondary" style="margin-left: 10px;text-transform: none;font-size: 14px;font-weight: 400;line-height: 1em;">
                {{"Nastaviť pripomienku" | translate}}
                <span class="start">{{nearestThursday}} 10:00 AM</span>
                <span class="end">{{nearestThursday}} 10:30 AM</span>
                <span class="timezone">Europe/Bratislava </span>
                <span class="title">VinLink: Zadať predpokladanú fenofázu</span>
                <span class="description">Zadajte prosím predpokladanú fenofázu viniča pre budúci týždeň. https://app.vinlink.eu </span>
                <span class="recurring">FREQ=WEEKLY;INTERVAL=1;COUNT={{weeksUntilSeasonEnd}}</span>
              </div>
              <div *ngIf="this.StAddedSuccessfully" class="col-lg-6 m0a tac" style="margin-top:20px;"> <button class="btn btn-primary" type="button" (click)="wizardClose()"> {{ 'Close' | translate }}</button> </div>
            </div>
          </mat-step>
        </mat-horizontal-stepper>
      </div>
    </div>
  </div>
</div>