<div class="main-content">
  <div class="page-content">
    <div class="container-fluid">
      <h5 class="breadcrumbs"><a href="#" [routerLink]="['/vineyards']">{{'VINEYARDS' | translate}}</a> <i class="material-icons">keyboard_arrow_right</i> {{'Vineyard_Detail' | translate}}</h5>
      <h2 class="page-heading" *ngIf="vineyard">{{vineyard.Alias}}</h2>
    </div>
  </div>
</div>
<ul class=" nav nav-pills nav-pills-warning nav-pills-icons " role="tablist">
  <li class="nav-item">
    <a class="nav-link" [ngClass]="{'active': nav==='situation'}" [routerLink]="['./']" [queryParams]="{tab:'situation'}" data-toggle="tab" role="tablist">
      <i class="material-icons-outlined">list_alt</i>{{'situacna-sprava' | translate }}
    </a>
  </li>
  <li class="nav-item">
    <a class="nav-link" [ngClass]="{'active': nav==='infection'}" [routerLink]="['./']" [queryParams]="{tab:'infection'}" data-toggle="tab" role="tablist">
      <i class="fi flaticon-cross"></i>{{'infect.Infection' | translate }}
    </a>
  </li>
  <li class="nav-item">
    <a class="nav-link" [ngClass]="{'active': nav==='current'}" [routerLink]="['./']" [queryParams]="{tab:'current'}" data-toggle="tab" role="tablist">
      <i class="material-icons-outlined">sensors</i>{{'Aktuálne počasie' | translate}}</a>
  </li>
  <li class="nav-item">
    <a class="nav-link" [ngClass]="{'active': nav==='charts'}" [routerLink]="['./']" [queryParams]="{tab:'charts'}" data-toggle="tab" role="tablist">
      <i class="material-icons-outlined">bar_chart</i> {{'Charts' | translate}} </a>
  </li>
  <li class="nav-item">
    <a class="nav-link" [ngClass]="{'active': nav==='info'}" [routerLink]="['./']" [queryParams]="{tab:'info'}" data-toggle="tab" role="tablist">
      <i class="material-icons-outlined">info</i> {{'tab_vineyard_info' | translate}} </a>
  </li>
  <li class="nav-item">
    <a class="nav-link" *ngIf="vineyard && vineyard.ownerUID === fireAuth.auth.currentUser.uid" [ngClass]="{'active': nav==='calendar'}" [routerLink]="['./']" [queryParams]="{tab:'calendar'}" data-toggle="tab" role="tablist">
      <i class="material-icons-outlined">calendar_today</i>{{'calendar' | translate}}</a>
  </li>
  <li class="nav-item">
    <a class="nav-link" [ngClass]="{'active': nav==='forecast'}" [routerLink]="['./']" [queryParams]="{tab:'forecast'}" data-toggle="tab" role="tablist">
      <i class="material-icons-outlined">cloud</i>{{'Weather forecast' | translate}}</a>
  </li>
  <li class="nav-item" *ngIf="vineyard && vineyard.ownerUID === fireAuth.auth.currentUser.uid">
    <a class="nav-link" [ngClass]="{'active': nav==='users'}" [routerLink]="['./']" [queryParams]="{tab:'users'}" data-toggle="tab" role="tablist">
      <i class="material-icons-outlined">supervisor_account</i>{{'Users' | translate}}</a>
  </li>
</ul>
<div class="main-content" *ngIf="vineyardService.myVineyards && vineyard">
  <div class="page-content">
    <div class="container-fluid" style="position:relative;">
      <div class="max-w-1300">
        <app-situation *ngIf="nav==='situation' && vineyard && vineyard.galatiSeason && vineyard.galatiSeason.season" [vineyard]="vineyard" [@inOutAnimation]></app-situation>
        <app-galati *ngIf="nav==='infection' && vineyard" [vineyard]="vineyard" [@inOutAnimation]></app-galati>
        <app-chart *ngIf="nav==='charts' && vineyard" chartID="chart1" ActivationKey="{{vineyard.ActivationKey}}" [vineyard]="vineyard" chartSince="chartSince" [@inOutAnimation]></app-chart>
        <div class="station-info" *ngIf="nav==='info'">
          <div style="margin-left: 8px;" *ngIf="vineyard && vineyard.galatiSeason">
            <div style="padding: 5px 8px;">
              <span class="variety">{{vineyard.galatiSeason.trend.msg}}</span>
            </div>
            <div style="padding: 5px 8px;">
              <span style="font-weight:500;">{{ "Protection scheme" | translate }}:</span> &nbsp; <span class="variety">{{galatiService.diseaseManagementMsg(vineyard.galatiSeason.vineyard.diseaseManagementScheme)}}</span>
            </div>
            <div style="padding: 5px 8px;">
              <span style="font-weight:500;">{{ "Spôsob používania služby" | translate }}:</span> &nbsp; <span class="variety">{{galatiService.usageMsg(vineyard.galatiSeason.vineyard.usage)}}
              </span>
            </div>
            <div style="padding: 5px 8px;"><span style="font-weight:500;">{{ "vine varieties" | translate }}:</span> &nbsp; <span class="variety" *ngFor="let odroda of vineyard.odrody">{{odroda.name}}<span class="comma">, </span></span>
            </div>
          </div>
          <app-device-card class="example-box" *ngIf="vineyard" [vineyard]="vineyard" (vineyardRemoveEmitted)="VineyardService.removeVineyard(vineyard.ActivationKey)">
          </app-device-card>
        </div>
        <app-current-meteo *ngIf="nav==='current'  && vineyard" [@inOutAnimation] [vineyard]="vineyard"></app-current-meteo>
        <app-calendar *ngIf="nav==='calendar'" [@inOutAnimation] [vineyard]="vineyard" calendarID="calendar"></app-calendar>
        <app-forecast *ngIf="nav==='forecast' && vineyard" [vineyard]="vineyard" [@inOutAnimation]></app-forecast>
        <app-users *ngIf="nav==='users' && vineyard.ActivationKey && vineyard.ownerUID === fireAuth.auth.currentUser.uid" [vineyard]="vineyard" ActivationKey="{{vineyard.ActivationKey}}" [@inOutAnimation]></app-users>
      </div>
      </div>
      </div>
</div>