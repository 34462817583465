import { Component, OnInit, Input } from "@angular/core";
import { WeatherService } from "app/_services/weather.service";
import { endOfYear, startOfYear } from "date-fns";
import { ChartService } from "app/_services/chart.service";
import { untilDestroyed } from 'ngx-take-until-destroy';
import { VineyardService } from 'app/_services/vineyard.service';

@Component({
  selector: "app-rain-chart",
  templateUrl: "./rain-chart.component.html",
  styleUrls: ["./rain-chart.component.scss"],
})
export class RainChartComponent implements OnInit {
  @Input() public chartID: string;
  @Input() public ActivationKey: string;
  @Input() public vineyard;

  @Input() public rainYearNum: number;
  chartsLoaded: boolean;
  // vineyard: any;

  constructor(
    public ws: WeatherService,
    public vineyardService: VineyardService,
    public charts: ChartService
  ) { }

  ngOnInit() {
    console.log("VAJN", this.vineyard)
    // this.vineyard = this.vineyardService.myVineyards.filter(
    //   (v) => v.ActivationKey === this.ActivationKey
    // );
  }
  ngAfterViewInit(): void {
    console.log(
      startOfYear(new Date(this.rainYearNum)),
      endOfYear(new Date(this.rainYearNum)),
      "year"
    );
    this.ws
      .getRainData(
        startOfYear(new Date(this.rainYearNum)),
        endOfYear(new Date(this.rainYearNum)),
        this.vineyard.StationID
      ).pipe(untilDestroyed(this))
      .subscribe((rainData) => {

        console.log("Rain data ", rainData);
        if (rainData !== "0 results") {
          this.charts.rainChart(this.chartID, rainData);
        }
      }, error => { console.log("get rain data error", error) })

  }
  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.

  }
}
