<!-- <header class="masthead image-header" *ngIf="!isPage('login')"></header> -->
<div class="">
  <app-navbar class="desktop-only" [loginPageAction]="action" (setLoginAction)="action=$event"></app-navbar>
</div>

<div class="wrapper wrapper-full-page login-page">
  <video autoplay muted loop class="bg-video not-mobile" poster="../../assets/img/sidebar-7.jpg" *ngIf="!isMobile">
    <source src="../../assets/video/VinLink_meteostanica-predikcia-chorob-vinica.mp4" type="video/mp4"> Your browser does not support HTML5 video.
  </video>
  <div class="page-header login-page header-filter" filter-color="black" style="background-image: url('../../assets/img/sidebar-7.jpg'); background-size: cover; background-position: top center;">
    <!--   you can change the color of the filter page using: data-color="blue | purple | green | orange | red | rose " -->
    <div class="container">
      <div class="row">
        <div class="col-lg-4 col-md-6 col-sm-8 ml-auto mr-auto">
          <form class="form" method="" action="">
            <div class="card card-login">
              <div class="card-header card-header-primary text-center">
                <h3 class="card-title">
                  <img src="../../assets/img/vinlink-logo.png" style="max-height:40px" alt="">
                  <img src="../../assets/img/GALATI-logo_white.svg" style="max-height: 37px;margin-left: 10px;margin-bottom: -8px;" alt="">
                </h3>
              </div>
              <div class="card-body ">
                <form class="form" *ngIf="!isLoggedIn" [formGroup]="registrationForm">
                  <!-- <p class="card-description text-center"> {{'Log In or Create an Account' | translate}} </p> -->
                  <h5 class="card-description text-center"> <span *ngIf="isLoggedIn">{{'Logged in as' | translate}} {{ user.displayName }}</span>
                    <span *ngIf="!isLoggedIn">{{action | translate}}</span>
                  </h5>
                  <!-- login -->
                  <mat-form-field class="example-full-width" *ngIf="action == 'Registration'">
                    <mat-label>
                      {{ 'Your name' | translate }}
                    </mat-label>
                    <input [errorStateMatcher]="matcher" formControlName="name" matInput type="text" />
                    <mat-icon matSuffix fontSet="material-icons-outlined" class="input-icon">account_circle</mat-icon>
                  </mat-form-field>
                  <mat-form-field class="example-full-width">
                    <mat-label>
                      {{ 'E-mail address' | translate }}
                    </mat-label>
                    <input [errorStateMatcher]="matcher" formControlName="email" matInput type="email" email />
                    <mat-icon matSuffix fontSet="material-icons-outlined" class="input-icon">email</mat-icon>
                  </mat-form-field>
                  <mat-form-field class="example-full-width" *ngIf="action != 'Reset Password'">
                    <mat-label>{{ 'Password' | translate }}
                    </mat-label>
                    <input [errorStateMatcher]="matcher" formControlName="password" matInput type="password" />
                    <mat-icon matSuffix class="input-icon">lock_outline</mat-icon>
                  </mat-form-field>
                  <button type="submit" [ngClass]="{'disabled': !registrationForm.valid}" [disabled]="!registrationForm.valid" class="btn btn-primary  col-sm-12" *ngIf="action == 'Registration'" (click)="onRegistrationSubmit(registrationForm.value)"> {{'Register'| translate}} </button>
                  <button type="button" class="btn btn-primary col-12" *ngIf="authService.verifNeeded && action == 'Login'" (click)="authService.sendVerificationMail()"> {{'Resend verification email' | translate}} </button>
                  <button type="submit" class="btn btn-success col-12" (click)="onLogin(registrationForm.value)" *ngIf="action == 'Login'"> {{'Log In' | translate }} </button>
                  <button type="button" class="btn btn-primary  col-sm-12" *ngIf="action == 'Reset Password'" (click)="authService.passwordReset(registrationForm.value.email)"> {{'Reset my password' | translate}} </button>
                  <!-- <button type="submit" class="btn btn-outline-danger col-12"> <i class="fa fa-google-plus" style="padding-right:7px;"></i>{{'Google Login' | translate}}</button> -->
                  <!--! opravit google login -->
                  <!-- <button mat-raised-button class="col-12" (click)="authService.doGoogleLogin()" *ngIf="action == 'Login'" style="padding:4px;color: #666;">
                    <mat-icon svgIcon="google-logo" aria-hidden="false" aria-label="Example thumbs up SVG icon" style="padding-right:7px;line-height: 23px;"></mat-icon> {{'Google Login' | translate}}
                  </button> -->
                </form>
              </div>
              <div class="card-footer justify-content-center">
                <div class="col-lg-12 tac" *ngIf="isLoggedIn">
                  <button type="button" class="btn btn-primary" (click)="authService.userLogout(); action = 'Login';ss.myVineyards = null"> {{'Log Out' | translate}} </button>
                </div>
                <div class=" " *ngIf="!isLoggedIn">
                  <div class="col-12">
                    <!-- ! tento dropdown je skryty iba v demo verzii-->
                    <!-- <div class="dropdown" *ngIf="action == 'Login'">
                      <button id="my-dropdown " class="btn btn-outline-primary dropdown-toggle col-sm-12" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{{'Other Login Methods'|translate}}</button>
                      <div class="dropdown-menu" aria-labelledby="my-dropdown">
                        <a class="dropdown-item" (click)="authService.doFacebookLogin()"><i class="fa fa-facebook-square" style="padding-right:7px;"></i>Facebook Login</a>
                        <a class="dropdown-item" (click)="authService.doGoogleLogin()"><i class="fa fa-google-plus" style="padding-right:7px;"></i>Google Login </a>
                      </div>
                    </div> -->
                    <button type="button" class="btn btn-outline-primary col-sm-12" (click)="action = 'Registration'" *ngIf="action == 'Login'"> {{'Sign Up'| translate}} </button>
                  </div>
                  <div class="col-lg-12 tac" style="padding:10px 0;">
                    <a (click)="action = 'Reset Password'" *ngIf="action == 'Login'"> {{'I forgot my password' | translate}} </a>
                    <a (click)="action = 'Login'" *ngIf="action !== 'Login'"> {{'Back to Login' | translate}} </a>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- <div class="login-footer">
  <app-footer></app-footer>
</div> -->