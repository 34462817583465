import { Component, OnInit, Input } from "@angular/core";
import { Vineyard } from "app/_classes/vineyard";
import { trigger, transition, style, animate } from "@angular/animations";
import { getDay, fromUnixTime } from "date-fns";
import { TranslateService } from "@ngx-translate/core";
import { GlobalService } from "app/_services/global.service";
import { WeatherService } from "app/_services/weather.service";
import { HttpClient } from "@angular/common/http";
import { untilDestroyed } from "ngx-take-until-destroy";

@Component({
  selector: "app-forecast",
  templateUrl: "./forecast.component.html",
  styleUrls: ["./forecast.component.scss"],
  animations: [
    trigger("inOutAnimation", [
      transition(":enter", [
        style({ opacity: 0 }),
        animate(".25s ease-out", style({ opacity: 1 })),
      ]),
      transition(":leave", [
        style({ opacity: 1 }),
        animate(".25s ease-in", style({ opacity: 0 })),
      ]),
    ]),
  ],
})
export class ForecastComponent implements OnInit {
  @Input() public vineyard: Vineyard;
  locale = this.global.lang;
  forecast;



  unixToTime(timestamp) {
    return fromUnixTime(timestamp);
  }
  constructor(
    public translate: TranslateService,
    public global: GlobalService,
    public weatherServ: WeatherService,
    private http: HttpClient
  ) {}

  async ngOnInit(): Promise<void> {
    console.log("vineyard", this.vineyard);

    const url = `https://api.openweathermap.org/data/2.5/onecall?lat=${this.vineyard.lat}&lon=${this.vineyard.lng}&lang=${this.global.lang}&units=metric&exclude=current,minutely,hourly&appid=${this.global.owmApiKey}`;
    this.forecast = await this.http.get(url).toPromise();

  }
  ngOnDestroy() {
    // To protect you, we'll throw an error if it doesn't exist.
  }
}
