export class Infect {
  msg: string;
  icon: string;
  class: string;
  signalMsg: string;
  constructor() {
    this.msg = "";
    this.icon = "";
    this.class = "";
    this.signalMsg = "";
  }
}
