import { Injectable } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/auth";
import { HttpClient } from "@angular/common/http";
import * as obce30r from "assets/json/obce30r.json";
import { Observable } from "rxjs/Observable";
import { NotificationsService } from "./notifications.service";
import { TranslateService } from "@ngx-translate/core";
import { GlobalService } from "./global.service";
import { format, getYear } from "date-fns";

@Injectable({
  providedIn: "root",
})
export class GalatiService {
  //  obce30r;
  obce30rJSON = "assets/json/obce30r_02.json";
  nearestGalatiObec;
  galatiObce: any = (obce30r as any).default;
  diseaseManagementSchemes;
  usages;
  constructor(
    private http: HttpClient,
    private fireAuth: AngularFireAuth,
    private notif: NotificationsService,
    public translate: TranslateService,
    public global: GlobalService,
  ) {


    // console.log("obce json", JSON.stringify({ ...this.obce }));
    this.http.get(`https://2024.galati.sk/api-24/diseaseManagementScheme/?lang=${this.global.lang}`).subscribe((res) => {
      this.diseaseManagementSchemes = res;
    });
    this.http.get(`https://2024.galati.sk/api-24/usage/?lang=${this.global.lang}`).subscribe((res) => {
      this.usages = res;
    });
  }











  galatiCalendar(date = new Date()) {
    return this.http
      .get(
        `https://2024.galati.sk/api-24/calendar/?date=${format(
          date,
          "yyyy-MM-dd"
        )}&lang=${this.global.lang}`
      )
      .toPromise();
  }
  getDatesFromWeekNum(weekNum) {
    const url =
      `https://2024.galati.sk/api-24/calendar/week/?year=${getYear(new Date())}&weekNum=${weekNum}&lang=${this.global.lang}`;

    return this.http.get(url).toPromise();
  }
  diseaseManagementMsg(value) {
    let found = this.diseaseManagementSchemes.options.filter(obj => {
      return obj.value === value;
    });
    return found[0]['msg']
  }
  usageMsg(value) {
    let found = this.usages.options.filter(obj => {
      return obj.value === value;
    });
    return found[0]['msg']
  }
  getSeason(vineyard) {
    return this.http
      .get(
        `https://2024.galati.sk/api-24/season/?key=${vineyard.galatiKey}&lang=${this.global.lang}`
      )
      .toPromise();
  }
  galatiInputGet(galatiKey) {
    return this.http
      .get(
        `https://2024.galati.sk/api-24/input/?key=${galatiKey}&lang=${this.global.lang}`
      )
      .toPromise();
  }
  get30rObce() {
    return this.http.get(this.obce30rJSON).map((res) => {
      console.log(res);
      return res;
    });
  }

  nearestCity(latitude, longitude) {
    console.log("nearest city data", this.galatiObce);
    let minDif = 99999;
    let closest: number;
    for (let index = 0; index < this.galatiObce.length; ++index) {
      var dif = this.PythagorasEquirectangular(
        latitude,
        longitude,
        this.galatiObce[index]["lat"],
        this.galatiObce[index]["lon"]
      );
      if (dif < minDif) {
        closest = index;
        minDif = dif;
      }
    }
    console.log("closes", closest, this.galatiObce[closest]);
    return this.galatiObce[closest];
  }
  PythagorasEquirectangular(lat1, lon1, lat2, lon2) {
    lat1 = this.Deg2Rad(lat1);
    lat2 = this.Deg2Rad(lat2);
    lon1 = this.Deg2Rad(lon1);
    lon2 = this.Deg2Rad(lon2);
    let R = 6371; // km
    let x = (lon2 - lon1) * Math.cos((lat1 + lat2) / 2);
    let y = lat2 - lat1;
    let d = Math.sqrt(x * x + y * y) * R;
    return d;
  }
  Deg2Rad(deg) {
    // Convert Degress to Radians
    return (deg * Math.PI) / 180;
  }
  ngOnDestroy() {
    // To protect you, we'll throw an error if it doesn't exist.
  }
}
