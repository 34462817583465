import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { Vineyard } from 'app/_classes/vineyard';
import { GalatiService } from 'app/_services/galati.service';
import { GlobalService } from 'app/_services/global.service';
import { VineyardService } from 'app/_services/vineyard.service';
import { format, parseISO } from 'date-fns';

interface Week {
  weekMsg: any;
  phenoMsg: any;
  temp: any;
  rainfall: any;
  pestControl: any;
  pestControlMsg: any;
  day: any;
  pestControlAlternative: any;
  pestControlAlternativeMsg: any;
  works: any;
  worksImage: any;
  pestManagement: any;
  monday: string;
  // Define other properties as per your object structure
}

@Component({
  selector: 'app-situation-card',
  templateUrl: './situation-card.component.html',
  styleUrls: ['./situation-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SituationCardComponent implements OnInit {

  @Input() previousWeek: Week | null = null;
  @Input() week: Week | null = null;
  @Input() timeFrame: string = '';
  @Input() vineyard: Vineyard | null = null;
  private previousVineyard: Vineyard | null = null;
  date: string = '';
  pestsShown: any;
  activeIndex: any;

  constructor(
    public global: GlobalService,
    public galatiService: GalatiService,
    public cdr: ChangeDetectorRef,
    public vineyardService: VineyardService
  ) { }

  ngOnInit(): void {
    this.updateDate();
  }

  // ngOnChanges(changes: SimpleChanges): void {
  //   if (changes['vineyard'] && this.vineyard !== this.previousVineyard) {
  //     this.previousVineyard = this.vineyard;
  //     this.galatiService.getSeason(this.vineyard).then((res) => {
  //       if (this.vineyard) {
  //         this.vineyard.galatiSeason = res;
  //         // Data has been updated, now manually trigger change detection
  //         this.cdr.detectChanges(); // Manually trigger change detection
  //         console.warn("situation-card.component.ts", this.vineyard.galatiSeason.season.at(-1).pestControlMsg[0], this.vineyard.Alias);
  //       }
  //     });
  //   }
  // }

  updateDate(): void {
    if (this.timeFrame === "today" && this.week) {
      this.date = `${format(parseISO(this.week.monday), "dd.MM.yyyy")} - ${format(new Date(), "dd.MM.yyyy")}`;
    }
  }

  iconClassForInfection(infectName: string): { icon: string; class: string } {
    const infect = this.week ? this.week[infectName] : { level: 0 };
    switch (infect.level) {
      case 0: return { icon: "done", class: "disease-status--0" };
      case 1: return { icon: "trending_down", class: "disease-status--1" };
      case 2: return { icon: "error_outlined", class: "disease-status--2" };
      default: return { icon: "", class: "" }; // Consider a default case or error handling
    }
  }
}
