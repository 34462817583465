import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-loading",
  templateUrl: "./loading.component.html",
  styleUrls: ["./loading.component.scss"],
})
export class LoadingComponent implements OnInit {
  loadingError = false;
  constructor() {
    setTimeout(() => {
      this.loadingError = true;
    }, 10000);
  }
  refresh() {
    window.location.reload();
  }
  ngOnInit() {}
}
