import {
  Component,
  OnInit,
  EventEmitter,
  Output,
  Input,
  ViewChild,
} from "@angular/core";
import { MapService } from "app/_services/map.service";
import { VineyardService } from "app/_services/vineyard.service";
import { GalatiService } from "app/_services/galati.service";
import { Vineyard } from "app/_classes/vineyard";
import { icon, Marker } from "leaflet";
import { NotificationsService } from "app/_services/notifications.service";
import { TranslateService } from "@ngx-translate/core";
import { trigger, transition, style, animate } from "@angular/animations";
import { MatHorizontalStepper } from "@angular/material/stepper";
import { MAT_STEPPER_GLOBAL_OPTIONS } from "@angular/cdk/stepper";
import { HttpClient } from "@angular/common/http";
import { format, differenceInWeeks } from "date-fns";
import {
  Validators,
  FormBuilder,
  FormControl,
  FormGroupDirective,
  NgForm,
} from "@angular/forms";
import { ErrorStateMatcher } from "@angular/material/core";
import { GlobalService } from "app/_services/global.service";
import { AngularFirestore } from '@angular/fire/firestore';
import { AuthenticationService } from 'app/_services/authentication.service';
import { AngularFireFunctions } from '@angular/fire/functions';

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: FormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    const isSubmitted = form && form.submitted;
    return !!(
      control &&
      control.invalid &&
      (control.dirty || control.touched || isSubmitted)
    );
  }
}

declare const addeventatc: any;
const iconUrl = "assets/img/icons/marker_icon.png";
const iconDefault = icon({
  iconUrl,
  iconSize: [34, 53],
  iconAnchor: [17, 48],
  popupAnchor: [0, -50],
  tooltipAnchor: [25, -28],
});
Marker.prototype.options.icon = iconDefault;

declare var L: any;
declare var SMap: any;
@Component({
  selector: "app-vineyard-wizard",
  templateUrl: "./vineyard-wizard.component.html",
  styleUrls: ["./vineyard-wizard.component.scss"],
  providers: [
    {
      provide: MAT_STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: false },
    },
  ],
  animations: [
    trigger("inOutAnimation", [
      transition(":enter", [
        style({ opacity: 0 }),
        animate(".25s ease-out", style({ opacity: 1 })),
      ]),
      transition(":leave", [
        style({ opacity: 1 }),
        animate(".25s ease-in", style({ opacity: 0 })),
      ]),
    ]),
  ],
})
export class VineyardWizardComponent implements OnInit {
  @Input() wizard: any;
  @Output() onWizardClose: EventEmitter<any> = new EventEmitter();
  @Output() onWizardFinish: EventEmitter<any> = new EventEmitter();
  @ViewChild("stepper", { static: true }) private stepper: MatHorizontalStepper;
  newVineyard: Vineyard = new Vineyard();
  // newVineyard;
  marker: Marker;
  map;
  map2;
  laneBeginning = null;
  laneEnd = null;
  lanePolyline = null;
  marker2: Marker;
  marker3: Marker;
  marker4: Marker;
  galatiNearestCity;
  marker4Alt: any;
  marker3Alt: any;
  map2Options;
  odrody: any;
  // odrody: any = (odrodyJSON as any).default;
  outOdroda;
  step = 0;
  isLinear = true;
  StAddedSuccessfully: boolean;
  selectedIndex = 0;
  step1Finished = false;
  step2Finished = false;
  scheme;
  step3Finished = false;
  schemeInfo = false;
  nearestThursday;
  weeksUntilSeasonEnd;

  terrainSensitivity;
  usage: Object;

  //!  activation form start
  matcher = new MyErrorStateMatcher();
  activationForm = this.formBuilder.group({
    stationAlias: ["", Validators.required],
    inVineyardSince: ["", Validators.required],
    stationID: ["", Validators.required],
    diseaseManagementScheme: ["", Validators.required],
    outOdroda: ["", Validators.required],
    usage: ["", Validators.required],
    terrainSensitivity: ["", Validators.required],
  });
  averages30y: any;
  loadAverages: boolean = false;

  onFormSubmit(newVineyard: Vineyard) {
    this.addVineyard(newVineyard);
  }
  //!  activation form end

  constructor(
    private mapService: MapService,
    public VineyardService: VineyardService,
    public notif: NotificationsService,
    public global: GlobalService,
    public translate: TranslateService,
    public galati: GalatiService,
    public httpClient: HttpClient,
    private formBuilder: FormBuilder,
    private afs: AngularFirestore,
    private fns: AngularFireFunctions,
    public authService: AuthenticationService
  ) {

    this.httpClient 
      .get(
        `https://2024.galati.sk/api-24/sensitivity/variety/?lang=${this.global.lang}`
      )
      .subscribe((res) => {
        this.odrody = res;
        console.log("ODRODY", this.odrody)
      });
    this.httpClient.get(`https://2024.galati.sk/api-24/sensitivity/locality/?lang=${this.global.lang}`).subscribe((res) => {
      this.terrainSensitivity = res;
      console.log("terrain sensitivity from galati api", this.terrainSensitivity)
    });
    this.httpClient.get(`https://2024.galati.sk/api-24/diseaseManagementScheme/?lang=${this.global.lang}`).subscribe((res) => {
      this.scheme = res;
      console.log("scheme", this.scheme)
    });
    this.httpClient.get(`https://2024.galati.sk/api-24/usage/?lang=${this.global.lang}`).subscribe((res) => {
      this.usage = res;
      console.log("usage", this.usage)
    });
    // console.log("odrody", this.odrody);
    addeventatc.refresh();
  }


  max5() {
    if (this.outOdroda.length >= 5) {
      this.notif.toaster(this.translate.instant("Vyberte max.5 odrôd"))
    }
  }

  async averages30years(lat, lon) {
    this.loadAverages = true;
    return this.fns.httpsCallable('tridsatrocnePriemery')({ lat: lat, lon: lon }).toPromise().then(res => {
      console.warn("priemery", res)

      const temps = res.map(d => d.averageTemperature);
      const rains = res.map(d => d.averageRain);

      const result = {
        temps: temps,
        rains: rains
      };
      console.warn("priemery converted", result)
      this.loadAverages = false;
      return result;
    }).catch(err => {
      console.warn("primery err", err)
      this.loadAverages = false;
    })
  }


  ngOnInit() {
    window.scroll(0, 0);
    this.wizard = true;
    this.prepareCalendarReminder();
    setTimeout(() => {
      addeventatc.refresh();
    }, 500);

  }
  prepareCalendarReminder() {
    // This will give next thursday if today is thursday
    var d = new Date();
    d.setDate(d.getDate() + ((7 - d.getDay()) % 7) + 4);
    this.nearestThursday = format(d, "MM/dd/yyyy");
    const seasonEnd = new Date();
    seasonEnd.setMonth(9);
    seasonEnd.setDate(31);
    this.weeksUntilSeasonEnd = differenceInWeeks(seasonEnd, d);
  }

  setIndex(event) {
    console.log("nastal setIndex()");
    this.selectedIndex = event.selectedIndex;
  }

  triggerClick() {
    console.log(
      `triggerClick() - Currently selected (not clicked) tab index: ${this.selectedIndex}`
    );
  }

  next() {
    this.stepper.next();
  }

  finishStep1() {
    if (this.newVineyard.lat && this.newVineyard.lng) {
      this.step1Finished = true;
      setTimeout(() => this.stepper.next(), 0);
      this.map2.setView([this.newVineyard.lat, this.newVineyard.lng], 16);
      if (this.marker2) {
        this.map2.removeLayer(this.marker2);
      }
      this.marker2 = new L.marker(
        [this.newVineyard.lat, this.newVineyard.lng],
        {
          icon: this.VineyardService.stationIcon,
        }
      ).addTo(this.map2);
      // .bindPopup(this.translate.instant("Your station"))
      // .openPopup();
    }
  }
  finishStep2() {
    if (this.newVineyard.laneStart.lat && this.newVineyard.laneEnd.lat) {
      this.step2Finished = true;
      setTimeout(() => this.stepper.next(), 0);
    }
  }
  finishStep3() {
    console.log("STEP 3 FINISHED");
    this.step3Finished = true;
    setTimeout(() => this.stepper.next(), 10);
  }

  stepperBack() {
    this.stepper.previous();
  }

  stepperNext() {
    this.stepper.next();
  }

  wizardClose() {
    if (this.StAddedSuccessfully) {
      this.wizardFinished();
    } else {
      this.onWizardClose.emit(true);
    }
  }
  wizardFinished() {
    this.wizard = false;
    this.notif.toaster(
      this.translate.instant("Your settings were successfully saved"),
      "done",
      "success"
    );
    this.onWizardClose.emit(true);
    this.StAddedSuccessfully = false;

  }
  onMapReady(e) {
    const cmpnnt = this;
    e.setView([48.14816, 17.10674], 13);
    e.locate({ setView: true, maxZoom: 16 });

    // e.addControl(
    //   new L.Control.Search({
    //     url: "https://nominatim.openstreetmap.org/search?format=json&q={s}",
    //     jsonpParam: "json_callback",
    //     propertyName: "display_name",
    //     propertyLoc: ["lat", "lon"],
    //     marker: L.circleMarker([0, 0], { radius: 30 }),
    //     autoCollapse: true,
    //     autoType: false,
    //     minLength: 2,
    //   })
    // );
    this.mapService.mapInit(e);
    this.map = e;
    e.on("locationfound", onLocationFound);
    e.on("locationerror", onLocationError);
    function onLocationFound(e) {
      const radius = e.accuracy / 2;
      L.marker([e.latlng.lat, e.latlng.lng])
        .addTo(cmpnnt.map)
        .bindPopup(cmpnnt.translate.instant("Your Estimated Location"))
        .openPopup();
      L.circle(e.latlng, radius).addTo(cmpnnt.map);
      cmpnnt.map.setView(e.latlng);
    }
    function onLocationError(e) {
      console.log("location error", e.message);
    }
  }

  onMap2Ready(e) {
    console.log(e);
    this.mapService.mapInit(e);
    this.map2 = e;
  }

  mapClick(e) {
    const toto = this;
    toto.averages30y = null;
    this.httpClient
      .get(
        `https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${e.latlng.lat}&lon=${e.latlng.lng}`
      )
      .toPromise().then(async (data) => {
        console.log("reverse geo", data);
        let address = data["address"];
        let country_code = address["country_code"];
        if (address["country_code"]) {
          toto.newVineyard.country = address["country_code"];
        }
        if (address["city_district"]) {
          toto.newVineyard.city_district =
            address["city_district"] + "," + country_code;
        } else if (address["city"]) {
          toto.newVineyard.city_district = address["city"] + "," + country_code;
        } else if (address["village"]) {
          toto.newVineyard.city_district =
            address["village"] + "," + country_code;
        } else if (address["town"]) {
          toto.newVineyard.city_district = address["town"] + "," + country_code;
        } else if (address["county"]) {
          toto.newVineyard.city_district =
            address["county"] + "," + country_code;
        } else {
          toto.newVineyard.city_district =
            address["suburb"] + "," + country_code;
        }


        this.newVineyard.lat = e.latlng.lat;
        this.newVineyard.lng = e.latlng.lng;
        if (country_code == "sk") {
          this.galatiNearestCity = this.galati.nearestCity(
            this.newVineyard.lat,
            this.newVineyard.lng
          );

          toto.averages30y = this.galatiNearestCity;
          toto.averages30y["temps"] = this.galatiNearestCity["temps"];
          toto.averages30y["rains"] = this.galatiNearestCity["rains"];
          console.log("sk = >", this.galatiNearestCity, this.averages30y)
        } else {
          toto.averages30y = await this.averages30years(e.latlng.lat, e.latlng.lng);
        }
      });


    const coords = SMap.Coords.fromWGS84(e.latlng.lng, e.latlng.lat);
    coords.getAltitude().then((altitude) => {
      this.newVineyard.alt = altitude.toFixed(1);
    });
    if (this.marker) {
      this.map.removeLayer(this.marker);
    }
    this.marker = new L.marker([e.latlng.lat, e.latlng.lng], {
      icon: this.VineyardService.stationIcon,
    })
      .addTo(this.map)
      .bindPopup(this.translate.instant("Your station"))
      .openPopup();
    this.newVineyard.lat = e.latlng.lat;
    this.newVineyard.lng = e.latlng.lng;
  }

  async map2Click(e) {
    if (this.marker3 && this.marker4) {
      // console.log("this.marker3 && this.marker4");
      this.map2.removeLayer(this.marker3);
      this.map2.removeLayer(this.marker4);
      this.map2.removeLayer(this.lanePolyline);
      this.marker3 = this.marker4 = null;
      this.laneBeginning = null;
      this.laneEnd = null;
      this.lanePolyline = null;
    } else if (this.marker3 && !this.marker4) {
      console.log("this.marker3 && !marker4");
      this.marker4 = L.marker([e.latlng.lat, e.latlng.lng])
        .addTo(this.map2)
        .bindPopup(this.translate.instant("Lane end"))
        .openPopup();
      /* draw a line between markers */
      this.laneBeginning = this.marker3.getLatLng();
      console.log(":::::: LANE BEGINNING::::::", this.laneBeginning);
      this.laneEnd = this.marker4.getLatLng();
      console.log(":::::: LANE END::::::", this.laneEnd);
      this.lanePolyline = new L.polyline([
        this.marker3.getLatLng(),
        this.marker4.getLatLng(),
      ]).addTo(this.map2);

      console.log(this.newVineyard);
      this.marker4Alt = await SMap.Coords.fromWGS84(
        e.latlng.lng,
        e.latlng.lat
      ).getAltitude();

      // console.log("lanestart", this.newVineyard.laneStart);
      this.newVineyard.laneStart.coords(
        this.laneBeginning.lat,
        this.laneBeginning.lng,
        this.marker3Alt
      );
      this.newVineyard.laneEnd.coords(
        this.laneEnd.lat,
        this.laneEnd.lng,
        this.marker4Alt
      );
      this.newVineyard.laneOrientation = this.mapService.getOrientation(
        this.newVineyard.laneStart,
        this.newVineyard.laneEnd
      );
    } else if (!this.marker3 && !this.marker4) {
      this.marker3 = L.marker([e.latlng.lat, e.latlng.lng])
        .addTo(this.map2)
        .bindPopup(this.translate.instant("Lane start"))
        .openPopup();
      this.marker3Alt = await SMap.Coords.fromWGS84(
        e.latlng.lng,
        e.latlng.lat
      ).getAltitude();
    }
  }

  invalidate() {
    this.map.invalidateSize();
    this.map2.invalidateSize();
    console.log(this.map2, "invalidated");
  }


  objMapper = (obj: { [key: string]: string | number }, keys: string[]) => {
    const result: { [key: string]: string | number } = {};

    for (const k of keys) {
      if (obj[k]) {
        result[k] = obj[k];
      }
    }

    return result;
  };

  addVineyard(newVineyard) {
    // console.clear();
    newVineyard.inVineyardSince = format(
      new Date(newVineyard.inVineyardSince),
      "yyyy-MM-dd"
    );
    let tempVineyard;

    tempVineyard = this.objMapper(newVineyard, [
      "city_district",
      "ActivationKey",
      "Alias",
      "laneStart",
      "laneEnd",
      "inVineyardSince",
      "terrainSensitivity",
      "usage",
      "diseaseManagementScheme",
      "lat",
      "lng",
      "alt",
      "laneOrientation",
      "odrody"
    ]);
    // tempVineyard.laneStart.alt = tempVineyard.laneStart.alt.toFixed(1);
    // tempVineyard.laneEnd.alt = tempVineyard.laneEnd.alt.toFixed(1);
    tempVineyard["ownerUID"] = this.authService.user.uid;
    tempVineyard["odrody"] = this.outOdroda;
    tempVineyard["users"] = {}
    tempVineyard["users"][this.authService.user.uid] = this.authService.user.email;
    console.log("temp", tempVineyard, "new", newVineyard);



      this.afs.collection('Vineyards').doc(tempVineyard.ActivationKey).get().toPromise().then(async dok => {
        console.log("tesne pred galati post", { grapeSensitivity: this.outOdroda });
        let fireVin = dok.data();
        console.warn(fireVin, "fireVin");

        this.httpClient
          .post(
            `https://2024.galati.sk/api-24/new/?key=${fireVin.galatiKey}&lang=${this.global.lang}&reset=true`,
            {
              grapeSensitivity: this.outOdroda,
              diseaseManagementScheme: tempVineyard.diseaseManagementScheme,
              locality: tempVineyard.terrainSensitivity,
              usage: tempVineyard.usage,
              avgTemp: this.averages30y["temps"],
              avgRainfall: this.averages30y["rains"],
              location: {
                village: tempVineyard.city_district,
                latitude: tempVineyard.lat,
                longitude: tempVineyard.lng
              },
              name: tempVineyard.Alias
            }
        ).toPromise().then(rez => {
          console.log("add vineyard galati rez", rez)
          this.afs.collection('Vineyards').doc(tempVineyard.ActivationKey).update(JSON.parse(JSON.stringify(tempVineyard))).then(res => {
            console.log("add vineyard res", res);
          this.finishStep3();
          this.stepperNext();
          this.StAddedSuccessfully = true;
          }).catch(err => {

            console.log("add vineyard ERR1", err, tempVineyard.ActivationKey, JSON.parse(JSON.stringify(tempVineyard)));
            this.notif.toaster(this.translate.instant("Nesprávny aktivačný kľúč"), "", "warning"); // to asi nemtreba
          })
        }).catch(error => {
          console.warn("galati new error", error);
          this.notif.toaster(this.translate.instant("Chyba: nesprávny galati kľúč"), "", "warning");

        })
      }).catch(err => {

        console.log("add vineyard ERR2", err, tempVineyard.ActivationKey, JSON.parse(JSON.stringify(tempVineyard)));
        this.notif.toaster(this.translate.instant("Nesprávny aktivačný kľúč"), "", "warning");
      })



  }



  ngAfterViewInit(): void {
    this.invalidate();
    setTimeout(() => { }, 500);
  }
  ngOnDestroy() {
    // To protect you, we'll throw an error if it doesn't exist.
  }
}
