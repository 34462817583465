<nav class="navbar navbar-expand-lg navbar-transparent fixed-top">
  <div class="container-fluid">
    <button mat-button class="navbar-toggler" type="button" (click)="sidebarToggle()">
      <span class="sr-only">{{'Toggle navigation' | translate}}</span>
      <span class="navbar-toggler-icon icon-bar"></span>
      <span class="navbar-toggler-icon icon-bar"></span>
      <span class="navbar-toggler-icon icon-bar"></span>
    </button>
    <div class="collapse navbar-collapse justify-content-end" id="navigation">
      <ul class="navbar-nav">
        <!--! LANGUAGE START -->
        <li class="nav-item dropdown">
          <a class="nav-link" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <i class="material-icons-outlined">language</i> <span class="lanaguage-name">{{global.lang}}</span>
          </a>
          <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownMenuLink">
            <!-- <a class="dropdown-item" [ngClass]="{active: global.lang=='en'}" (click)="useLanguage('en');global.lang='en'">{{"English" | translate}}</a> -->
            <a class="dropdown-item" [ngClass]="{active: global.lang=='sk'}" (click)="authService.useLanguage('sk');global.lang='sk'">{{"Slovak" | translate}}</a>
            <a class="dropdown-item" [ngClass]="{active: global.lang=='cz'}" (click)="authService.useLanguage('cz');global.lang='cz'">{{"Czech" | translate}}</a>
            <a class="dropdown-item" [ngClass]="{active: global.lang=='hu'}" (click)="authService.useLanguage('hu');global.lang='hu'">{{"Hungarian" | translate}}</a>
            <a class="dropdown-item" [ngClass]="{active: global.lang=='en'}" (click)="authService.useLanguage('en');global.lang='en'">{{"English" | translate}}</a>
          </div>
        </li>
        <!--! LANGUAGE END -->
        <li class="nav-item" *ngIf="!isLoggedIn" [ngClass]="{ active: loginPageAction === 'Registration' }">
          <a (click)="loginActionClick('Registration')" class="nav-link">
            <i class="material-icons">person_add</i> {{'Register' | translate}} </a>
        </li>
        <li class="nav-item" *ngIf="!isLoggedIn" [ngClass]="{ active: loginPageAction === 'Login' }">
          <a (click)="loginActionClick('Login')" class="nav-link">
            <i class="material-icons">fingerprint</i> {{'Login' | translate}} </a>
        </li>
        <li class="nav-item" *ngIf="isLoggedIn">
          <mat-menu #appMenu="matMenu" xPosition="before">
            <button mat-menu-item [routerLink]="['/vineyards']" routerLinkActive="router-link-active">{{'My Vineyards' | translate}}</button>
            <button mat-menu-item *ngIf="isLoggedIn" (click)="authService.userLogout();ss.myVineyards = null">{{'Log Out' | translate}}</button>
          </mat-menu>
          <button mat-icon-button [matMenuTriggerFor]="appMenu">
            <mat-icon>person</mat-icon>
          </button>
        </li>
        <!-- <li class="nav-item" *ngIf="isLoggedIn">
          <a class="nav-link" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <i class="material-icons">person</i>
            <p><span class="d-lg-none d-md-block">Account</span></p>
          </a>
          <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownMenuLink">
            <a class="dropdown-item" [routerLink]="['/vineyards']" routerLinkActive="router-link-active">{{'My Vineyards' | translate}}</a>
            <a *ngIf="isLoggedIn" class="dropdown-item" (click)="authService.userLogout();ss.myVineyards = null">{{'Log Out' | translate}}</a>
          </div>
        </li> -->
      </ul>
    </div>
  </div>
</nav>