import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { GlobalService } from "app/_services/global.service";
import { VineyardService } from "app/_services/vineyard.service";
import { untilDestroyed } from "ngx-take-until-destroy";
import { HttpHeaders } from '@angular/common/http';

export const contentHeaders = new HttpHeaders();


const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/pdf'
  })
};

contentHeaders.append('Accept', 'text/plain;base64');
contentHeaders.append('Access-Control-Allow-Origin', '*');
contentHeaders.append('Access-Control-Request-Method', '*');
contentHeaders.append('Access-Control-Allow-Methods', '');
contentHeaders.append('Access-Control-Request-Headers', 'Origin, Content-Type, Accept');

@Component({
  templateUrl: "./docs.component.html",
  styleUrls: ["./docs.component.scss"],
})
export class DocsComponent implements OnInit {
  step = 0;
  activeDoc: string;
  lang: string;
  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }
  constructor(
    private _Activatedroute: ActivatedRoute,
    public global: GlobalService,
    public vineyardService: VineyardService
  ) {
    this.global.langObservable
      .pipe(untilDestroyed(this))
      .subscribe((lang) => (this.lang = lang));
  }

  ngOnInit() {
    this.activeDoc = this._Activatedroute.snapshot.params["activeDoc"];
    this.vineyardService.getMyVineyards()
  }
  ngOnDestroy() {
    // To protect you, we'll throw an error if it doesn't exist.
  }
}
