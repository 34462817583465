<h1 mat-dialog-title>{{"Add Event to Calendar" | translate}}</h1>
<div mat-dialog-content>
  <p> {{"EVENT_What_did_you_do_on" | translate}} {{ data.date  | date: 'shortDate':undefined:data.locale }}?</p>
  <div class="form-group" *ngIf="data.generalCalendar">
    <mat-form-field>
      <mat-label>Vinohrad</mat-label>
      <mat-select [(ngModel)]="data.ActivationKey" [value]="data.ActivationKey">
        <mat-option *ngFor="let vineyard of data.allVineyards" [value]="vineyard.ActivationKey">
          {{vineyard.Alias}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <mat-form-field>
    <mat-select placeholder="{{'Work activity' | translate}}" [(ngModel)]="data.work">
      <mat-option *ngFor="let activity of data.workActivities" [value]="activity.value">
        {{ activity.name | translate}}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <div class="form-group">
    <mat-form-field>
      <mat-label>{{"Note" | translate}}</mat-label>
      <textarea id="my-textarea" class="form-control" name="" rows="3" matInput [(ngModel)]="data.note"></textarea>
    </mat-form-field>
  </div>
</div>
<div mat-dialog-actions>
  <button mat-button *ngIf="data.type==='update'" (click)="data.type='delete'" [mat-dialog-close]="data">{{"Delete" | translate}}</button>
  <button class="btn btn-danger" style="  background-color: #d32f2f;" (click)="onNoClick()">{{"Dismiss changes" | translate}}</button>
  <button class="btn btn-primary not-mobile" [mat-dialog-close]="data" cdkFocusInitial>{{"Save" | translate}}</button>
</div>