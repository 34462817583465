export class Season {
  jan: Array<any>;
  feb = [];
  mar = [];
  apr = [];
  may = [];
  jun = [];
  jul = [];
  aug = [];
  sep = [];
  oct = [];
  nov = [];
  dec = [];
  constructor(
    jan = [],
    feb = [],
    mar = [],
    apr = [],
    may = [],
    jun = [],
    jul = [],
    aug = [],
    sep = [],
    oct = [],
    nov = [],
    dec = []
  ) {
    return {
      jan: jan,
      feb: feb,
      mar: mar,
      apr: apr,
      may: may,
      jun: jun,
      jul: jul,
      aug: aug,
      sep: sep,
      oct: oct,
      nov: nov,
      dec: dec
    };
  }
}
