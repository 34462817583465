import { MapPoint } from "./mapPoint";
import { Weather } from "./weather";

export class Vineyard {
  diseaseManagementScheme;
  phenosChecked = false;
  meteoData;
  // currentMeteoData: Weather = new Weather();
  currentMeteoData;
  cit_final_b;
  cit_final_m;
  cit_final_p;
  laneStartLat;
  laneStartLng;
  laneEndLat;
  laneEndLng;
  laneStartAlt;
  laneEndAlt;
  city_district = "";
  ActivationKey = "";
  StationID = "";
  galatiKey = "";
  Alias = "";
  galatiSeason;
  minTemp;
  hidden = false;
  maxTemp;
  GalatiCronRecord = null;
  phenophase_previous;
  phenophase_next;
  scheme;
  lat;
  lng;
  alt;
  sensitivity;
  laneStart = new MapPoint();
  laneEnd = new MapPoint();
  currentOWM = null;
  // odroda;
  odrody: string;
  ownerUID;
  terrainSensitivity;
  OWMicon = "";
  inVineyardSince = null;
  lastCheckedDB;
  meteoResponse = "";
  laneOrientation: string;
  galatiNearestCity;
  // Station = {
  //   StationID: "",
  //   ActivationKey: "",
  // };
  finalSensitivity: Object;
  vineSensitivity: Object;
  grapeSensitivity: any;
  galatiDaily: boolean;
  plan: string;
  usage: any;
  country: any;
  users: {};
}
