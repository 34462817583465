import { Injectable } from "@angular/core";
import { Weather } from "../_classes/weather";
import { HttpClient } from "@angular/common/http";
import { AngularFireAuth } from "@angular/fire/auth";
import "rxjs/add/observable/fromPromise";
import "rxjs/add/operator/map";
import { Observable, from } from "rxjs";
import { mergeMap } from "rxjs/operators";
import weatherIcons from "../../assets/img/weather-icons-master/icons.json";
import * as myGlobals from "../global-variables";
import { format, subHours, subDays, startOfWeek, subWeeks, addDays } from "date-fns";
import { untilDestroyed } from "ngx-take-until-destroy";
import { GlobalService } from './global.service';

@Injectable({
  providedIn: "root",
})
export class WeatherService {
  getOWMdataResponse = false;
  constructor(private fireAuth: AngularFireAuth, private http: HttpClient, public global: GlobalService) { }

  getMeteoData(insert_datetime: Date, StationID) {
    return this.http
      .post("https://backend.vinlink.eu/2023/get_meteo_by_stationid.php", {
        insert_datetime: format(insert_datetime, "yyyy-MM-dd HH:mm:ss"),
        StationID: StationID
      });

  }
  async fetchForecastData(vineyard) {
    const url = `https://api.openweathermap.org/data/2.5/onecall?lat=${vineyard.lat}&lon=${vineyard.lng}&lang=${this.global.lang}&units=metric&exclude=current,minutely,hourly&appid=${this.global.owmApiKey}`;
    const result = await this.http.get(url).toPromise();
    console.log("forecast", result)
    let rainSUM = 0;
    let tempSum = 0;
    let days = result["daily"].length;

    result["daily"].forEach((day) => {
      tempSum += (day.temp.min + day.temp.max) / 2;
      if (day.rain) {
        rainSUM += day.rain;
      }
    });

    const avgTemp = (tempSum / days).toFixed(1);
    console.warn("Forecast rainsum, avgTemp", rainSUM, avgTemp);

    return { forecastTemp: avgTemp, forecastRain: rainSUM.toFixed(1) };
  }



  async getExternalMeteoForPreviousWeek(vineyard, referenceDate?) //referenceData - to je bud dnesok ak mi ide o minuly tyzden alebo je to galati date monday, z ktoreho potom ratam datumy predosleho tyzdna
  {
    console.log("get external meteo vineyard", vineyard, vineyard.ActivationKey, vineyard.galatiKey)
    let today, lastWeekMonday, startDate, previousWeekMonday, endDate;
    if (!referenceDate) {
      today = new Date();
      referenceDate = today;
      const lastWeek = subWeeks(today, 1);
      lastWeekMonday = startOfWeek(lastWeek, { weekStartsOn: 1 });
      startDate = format(lastWeekMonday, "yyyy-MM-dd");
      console.warn("startdejt", startDate, "lastWeekMonday", lastWeekMonday)
      endDate = format(addDays(lastWeekMonday, 6), "yyyy-MM-dd");

    } else {
      const previousWeek = subWeeks(new Date(referenceDate), 1);
      previousWeekMonday = startOfWeek(previousWeek, { weekStartsOn: 1 });
      startDate = format(previousWeekMonday, "yyyy-MM-dd");
      // startDate = format(new Date(startDate), "yyyy-MM-dd");
      console.warn("startdejt", startDate)
      endDate = format(addDays(previousWeekMonday, 6), "yyyy-MM-dd");


    }
    const url = `https://archive-api.open-meteo.com/v1/archive?latitude=${vineyard.lat}&longitude=${vineyard.lng}&start_date=${startDate}&end_date=${endDate}&daily=temperature_2m_mean,rain_sum&timezone=Europe%2FBerlin`;

    try {
      const response = await fetch(url);
      if (!response.ok) throw new Error('Network response was not ok');

      const data = await response.json();
      let averageTemp = this.calculateAverage(data.daily.temperature_2m_mean);
      let rainSUM = this.calculateSum(data.daily.rain_sum);
      let impulsiveRainfall = this.isRainOnSingleDay(data.daily.rain_sum);
      console.log('fetching weather data:', { AvgTemp: averageTemp, rainSUM: rainSUM, impulsiveRainfall: impulsiveRainfall });
      return { AvgTemp: averageTemp, rainSUM: rainSUM, impulsiveRainfall: impulsiveRainfall };

    } catch (error) {
      console.error('Error fetching weather data:', error);
      return;
    }
  }
  isRainOnSingleDay(rainSums) {
    let rainyDays = 0; // Counter for days with rain

    // Iterate over each day's rain sum
    for (let i = 0; i < rainSums.length; i++) {
      if (rainSums[i] > 0) {
        rainyDays++; // Increment counter if there's rain
        if (rainyDays > 1) {
          // If more than one rainy day is found, return false
          return false;
        }
      }
    }

    // Return true if exactly one rainy day, false otherwise
    return rainyDays === 1;
  }

  calculateAverage(arr) {
    let avg = arr.reduce((acc, cur) => acc + cur, 0) / arr.length;
    return parseFloat(avg.toFixed(2));
  }
  calculateSum(arr) {
    return arr.reduce((acc, cur) => acc + cur, 0);
  }
  // async getWeatherData(vineyard, startDate?, endDate?) {
  //   if (!startDate && !endDate) {
  //     startDate = format(subDays(new Date(), 7), "yyyy-MM-dd");
  //     endDate = format(new Date(), "yyyy-MM-dd");
  //   }
  //   console.log(startDate, endDate)
  //   const startTimestamp = new Date(startDate).getTime();
  //   const endTimestamp = new Date(endDate).getTime();
  //   console.log(startTimestamp, endTimestamp);

  //   let totalTemp = 0;
  //   let totalRain = 0;
  //   let count = 0;

  //   // Assuming OpenWeatherMap API requires one request per day
  //   for (let currentTimestamp = startTimestamp; currentTimestamp <= endTimestamp; currentTimestamp += 86400) {
  //     const url = `https://api.openweathermap.org/data/3.0/onecall/day_summary?lat=${vineyard.lat}&lon=${vineyard.lng}&date=${startDate}&appid=${this.global.owmApiKey}`;

  //     try {
  //       const response = await fetch(url);
  //       if (!response.ok) throw new Error('Network response was not ok');

  //       const data = await response.json();
  //       console.log('fetching weather data:', data);
  //       data.list.forEach((entry) => {
  //         if (entry.main && entry.main.temp) {
  //           totalTemp += entry.main.temp;
  //           count++;
  //         }
  //         if (entry.rain && entry.rain['3h']) {
  //           totalRain += entry.rain['3h'];
  //         }
  //       });
  //     } catch (error) {
  //       console.error('Error fetching weather data:', error);
  //       return;
  //     }
  //   }

  //   const averageTemp = totalTemp / count;
  //   console.log({ averageTemp, totalRain });
  //   return { averageTemp, totalRain };
  // }





  findDewPointWithHumidity(humi, temp) {
    let ans =
      temp -
      (14.55 + 0.114 * temp) * (1 - 0.01 * humi) -
      Math.pow((2.5 + 0.007 * temp) * (1 - 0.01 * humi), 3) -
      (15.9 + 0.117 * temp) * Math.pow(1 - 0.01 * humi, 14);

    return ans.toFixed(1);
  }
  getRainData(
    date_rain_start: Date,
    date_rain_end: Date,
    StationID
  ) {
    console.log("RAIN STATIOINS", format(date_rain_start, "yyyy-MM-dd"), format(date_rain_end, "yyyy-MM-dd"),
      StationID)
    return this.http.post(
      "https://backend.vinlink.eu/2023/get_rain_data.php",
      {
        date_rain_start: format(date_rain_start, "yyyy-MM-dd"),
        date_rain_end: format(date_rain_end, "yyyy-MM-dd"),
        StationID: StationID
      }
    );
  }

  getOWMdataHttp(vineyard) {
    return Observable.fromPromise(
      this.fireAuth.auth.currentUser.getIdToken(true)
    ).pipe(
      mergeMap((token) => {
        return this.http.post(
          "https://backend.vinlink.eu/2023/get_OWM_data.php",
          {
            idToken: token,
            city_district: vineyard.city_district,
            ActivationKey: vineyard.ActivationKey,
          }
        );
      })
    );
  }


  weatherIcon(weather_id) {
    if (weather_id) {
      const prefix = "wi-";
      const code = weather_id;
      let icon = weatherIcons[weather_id].icon;
      // If we are not in the ranges mentioned above, add a day/night prefix.
      if (!(code > 699 && code < 800) && !(code > 899 && code < 1000)) {
        icon = "day-" + icon;
      }

      // Finally tack on the prefix.
      icon = prefix + icon;
      return icon;
    }
  }

  temperature(temperatureRaw) {
    return (temperatureRaw - 511) / 10;
  }

  pressure(pressureRaw) {
    return pressureRaw / 10 + 665;
  }
  timeConverter(UNIX_timestamp) {
    const a = new Date(UNIX_timestamp * 1000);
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "Máj",
      "Jún",
      "Júl",
      "Aug",
      "Sep",
      "Okt",
      "Nov",
      "Dec",
    ];
    const year = a.getFullYear();
    const month = months[a.getMonth()];
    const date = a.getDate();
    const hour = a.getHours();
    const min = a.getMinutes();
    const sec = a.getSeconds();
    const time =
      date + " " + month + " " + year + " " + hour + ":" + min + ":" + sec;
    return time;
  }


  ngOnDestroy() {
    // To protect you, we'll throw an error if it doesn't exist.
  }
}
