import { Injectable } from "@angular/core";
import { VineyardService } from "app/_services/vineyard.service";
import * as geolib from "geolib";
import { MapPoint } from "app/_classes/mapPoint";

declare var L: any;
@Injectable({
  providedIn: "root",
})
export class MapService {
  stationIcon = L.icon({
    iconUrl: "assets/img/icons/station_icon.png",
    iconSize: [34, 53],
    iconAnchor: [17, 48],
    popupAnchor: [0, -50],
    tooltipAnchor: [25, -28],
  });
  mapInit(map) {
    // pridanie dvoch vrstiev mape - stalitna a cesty s nazvami
    const WorldImagery = L.tileLayer(
      "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}",
      {
        // attribution:
        //   'Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community'
      }
    );
    const TonerHybrid = L.tileLayer(
      "https://stamen-tiles-{s}.a.ssl.fastly.net/toner-hybrid/{z}/{x}/{y}{r}.{ext}",
      {
        attribution:
          'Map tiles by <a href="http://stamen.com">Stamen Design</a>, <a href="http://creativecommons.org/licenses/by/3.0">CC BY 3.0</a> &mdash; Map data &copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
        subdomains: "abcd",
        minZoom: 0,
        maxZoom: 20,
        ext: "png",
      }
    );


    WorldImagery.addTo(map);
    TonerHybrid.addTo(map);
  }






  convertSpeed(value, unit) {
    return geolib.convertSpeed(value, unit);
  }
  /* Get compass from wind degrees */
  degToCompass(num) {
    var val = Math.floor(num / 22.5 + 0.5);
    var arr = [
      "N",
      "NNE",
      "NE",
      "ENE",
      "E",
      "ESE",
      "SE",
      "SSE",
      "S",
      "SSW",
      "SW",
      "WSW",
      "W",
      "WNW",
      "NW",
      "NNW",
    ];
    return arr[val % 16];
  }
  /* Get compass direction between to coordinates */

  getOrientation(laneStart: MapPoint, laneEnd: MapPoint) {
    const orientation = geolib.getCompassDirection(
      { latitude: laneStart.lat, longitude: laneStart.lng },
      { latitude: laneEnd.lat, longitude: laneEnd.lng }
    );
    return orientation;
  }
  getDistance(startLat, startLng, endLat, endLng) {
    return geolib.getDistance(
      { latitude: startLat, longitude: startLng },
      { latitude: endLat, longitude: endLng }
    );
  }
  getAltDelta(startLat, endLat) {
    return Math.abs(Number(startLat) - Number(endLat)).toFixed(1);
  }
  constructor(private VineyardService: VineyardService) {}
}
