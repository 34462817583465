import { Component, OnInit, Inject } from "@angular/core";
import { AuthenticationService } from "app/_services/authentication.service";
import { trigger, transition, style, animate } from "@angular/animations";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { untilDestroyed } from "ngx-take-until-destroy";
import { GlobalService } from "app/_services/global.service";
import { Observable } from "rxjs/Observable";
import { VineyardService } from "app/_services/vineyard.service";
import { AngularFirestore } from '@angular/fire/firestore';
import { NotificationsService } from 'app/_services/notifications.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { TranslateService } from '@ngx-translate/core';

export interface DialogData {
  name: string;
}
@Component({
  selector: "app-user-profile",
  templateUrl: "./user-profile.component.html",
  styleUrls: ["./user-profile.component.scss"],
  animations: [
    trigger("inOutAnimation", [
      transition(":enter", [
        style({ opacity: 0 }),
        animate(".25s ease-out", style({ opacity: 1 })),
      ]),
      transition(":leave", [
        style({ opacity: 1 }),
        animate(".25s ease-in", style({ opacity: 0 })),
      ]),
    ]),
  ],
})
export class UserProfileComponent implements OnInit {
  mode = "view";
  getSMS: boolean = false;
  user: firebase.User;
  newDisplayName: string;
  newEmail: string;
  newNotificationsEmail;
  oldNotificationsEmail;
  phone: string;
  locale: string;
  passwd: string;
  constructor(
    public globalService: GlobalService,
    public translate: TranslateService,
    public authService: AuthenticationService,
    public vineyardService: VineyardService,
    public dialog: MatDialog,
    private fireAuth: AngularFireAuth,
    private notif: NotificationsService,
    public afs: AngularFirestore
  ) {
    this.locale = this.globalService.lang;
    this.globalService.langObservable
      .pipe(untilDestroyed(this))
      .subscribe((lang) => (this.locale = lang));

    this.authService.userObs().subscribe(res => {
      this.afs.collection("users").doc(res.uid).valueChanges().subscribe((rezult) => {
        this.authService.vinlinkUser = rezult;
        this.authService.vinlinkUserBeh.next(rezult);
        this.oldNotificationsEmail = rezult["notificationsEmail"];

        if (!this.newNotificationsEmail) {
          this.newNotificationsEmail = this.oldNotificationsEmail
        }
        this.authService.vinlinkUserBeh.next(this.authService.vinlinkUser);
        if (this.authService.user.emailVerified !== true) {
          this.authService.verifNeeded = true;
        }
      })

    })
  }
  smsCheckbox() {
    let phone;
    let userUpdateData = {};
    if (this.authService.vinlinkUser.wantSMS === false) {
      userUpdateData = { wantSMS: this.authService.vinlinkUser.wantSMS, phone: null }
    }
    else {
      userUpdateData = { wantSMS: this.authService.vinlinkUser.wantSMS }
    }

    this.afs.collection("users").doc(this.authService.user.uid).update(userUpdateData).then(res => {
      console.log("written to firestore", this.authService.vinlinkUser.wantSMS)
    }).catch(err => {
      console.log("err checkbox sms", err)

    })
  }
  ngOnInit() {
    this.getUser();
  }

  openDialog(newDisplayName?: string, newEmail?: string): void {
    const dialogRef = this.dialog.open(DialogReauthenticate, {
      // width: "250px"
    });

    dialogRef
      .afterClosed()
      .pipe(untilDestroyed(this))
      .subscribe((result) => {
        console.log("The dialog was closed");
        let passwd = result;
        this.userInfoChange();
      });
  }
  getUser() {
    this.authService
      .userObs()
      .pipe(untilDestroyed(this))
      .subscribe((userData) => {

        this.newDisplayName = userData.displayName;
        this.newEmail = userData.email;

      });
  }





  userInfoChange() {
      this.afs.collection("users").doc(this.authService.user.uid).update({
        notificationsEmail: this.newNotificationsEmail,
        phone: this.authService.vinlinkUser.phone,
      }).then(res => {
        console.log("res change notif email", res);
        this.notif.toaster(
          this.translate.instant("Informácie aktualizované"),
          "",
          "success"
        );

      })


    if (this.newDisplayName !== this.authService.user.displayName) {

      this.authService.user
        .updateProfile({
          displayName: String(this.newDisplayName),
        })
        .then(
          (res) => {
            // console.warn("profile updated in firebase auth", res);
            this.notif.toaster(
              this.translate.instant("Username updated"),
              "",
              "success"
            );
          }).catch(err => {
            this.notif.toaster(
              this.translate.instant(err.message),
              "how_to_reg",
              "danger"
            );
          })

      if (this.newEmail !== this.authService.user.email) {

        this.openDialog(this.newDisplayName, this.newEmail);


        this.fireAuth.auth
          .signInWithEmailAndPassword(this.authService.user.email, this.passwd)
          .then((response) => {
            this.authService.user.updateEmail(this.newEmail).then(
              (res) => {
                console.log(res);
                this.getUser();
              },
              (error) => {
                this.notif.toaster(
                  this.translate.instant(error.message),
                  "how_to_reg",
                  "danger"
                );
                console.log("error", error);
              }
            );
          })
          .catch((err) => {
            console.log("reauth err", err);
            this.notif.toaster(
              this.translate.instant(err.message),
              "how_to_reg",
              "danger"
            );
          });
      }
    }
  }
















  ngOnDestroy() {
    // To protect you, we'll throw an error if it doesn't exist.
  }
}
@Component({
  selector: "dialog-reauthenticate",
  templateUrl: "dialog-reauthenticate.html",
})
export class DialogReauthenticate {
  passwd: string;
  locale: string;
  constructor(
    public globalService: GlobalService,
    public dialogRef: MatDialogRef<DialogReauthenticate>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    this.locale = this.globalService.lang;
    this.globalService.langObservable
      .pipe(untilDestroyed(this))
      .subscribe((lang) => (this.locale = lang));
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.

  }
}
