import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { AuthenticationService } from 'app/_services/authentication.service';
import { NotificationsService } from 'app/_services/notifications.service';
// import { untilDestroyed } from 'ngx-take-until-destroy';
import { AngularFireFunctions } from '@angular/fire/functions';
import { Vineyard } from 'app/_classes/vineyard';
import { ActivatedRoute } from '@angular/router';
import { VineyardService } from 'app/_services/vineyard.service';
import { TranslateService } from '@ngx-translate/core';
import { trigger, transition, style, animate } from '@angular/animations';


@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
  animations: [
    trigger("inOutAnimation", [
      transition(":enter", [
        style({ opacity: 0 }),
        animate(".25s ease-out", style({ opacity: 1 })),
      ]),
      transition(":leave", [
        style({ opacity: 1 }),
        animate(".25s ease-in", style({ opacity: 0 })),
      ]),
    ]),
  ],
})
export class UsersComponent implements OnInit {
  @Input() ActivationKey: string;
  @Input() test: string;
  @Input() vineyard: Vineyard;

  user: any;
  newEmail;
  userGroup;
  loading = false;
  constructor(
    public vineyardService: VineyardService,
    public notify: NotificationsService,
    public http: HttpClient,
    public afs: AngularFirestore,
    public functions: AngularFireFunctions, 
    public translate: TranslateService,
    public authServ: AuthenticationService) {

  }
  ngAfterViewInit(): void {
    this.user = this.authServ.authState;
    console.log(this.vineyard, this.ActivationKey)
    console.log("authservice authstate", this.authServ.authState)

    console.log("AFTER VIEW INIT", this.test, this.vineyard, this.ActivationKey)
  }
  ngOnInit(): void {
    this.afs.collection("Vineyards").doc(this.ActivationKey).get().toPromise().then((res: any) => {
      console.log("RES", res.data().users)
      this.userGroup = res.data().users;
    }).catch(err => {
      console.error(err)
    })
  }
  getEmailByUid(uid) {
    const getEmail = this.functions.httpsCallable('getEmailByUid');
    return getEmail({ uid }).toPromise();
  }
  addUser(email) {
    this.loading = true;
    const getUserData = this.functions.httpsCallable('getUserByEmail')
    getUserData({ email }).toPromise().then(res => {
      console.log(res.userData.uid);
      if (!this.userGroup[res.userData.uid]) {
        this.userGroup[res.userData.uid] = res.userData.email;
      } else {
        this.notify.toaster(this.translate.instant("The user is already in the list"))
        return;
      }
      this.afs.collection("Vineyards").doc(this.ActivationKey).update({
        users: this.userGroup
      }).then(res => {
        this.newEmail = null;
        this.notify.toaster(this.translate.instant("Používateľ pridaný"), "", "success");
        // this.afs.collection("mail").doc(`read only added: ${this.vineyard.Alias} - ${this.newEmail}`).set({
        //   to: "j.trgina@gmail.com",
        //   template: {
        //     name: "readOnlyUserAdded",
        //     subject: `${this.translate.instant("t_access_to_vineyard_granted")})`,
        //     data: {
        //       t_access_to_vineyard_granted: this.translate.instant("t_access_to_vineyard_granted")
        //     }
        //   }
        // }).then(res => {
        //   this.loading = false;
        //   console.log("mail send res", res);
        // }).catch(err => {
        //   this.loading = false;
        //   console.log("mail send err", err);
        // })
      })

    }).catch(error => {
      this.loading = false;
      if (error.code === 'auth/user-not-found') {
        this.notify.toaster(this.translate.instant("User not found"))
        // TODO : If here then you guessed it... you can create a new account.
      }
    })
  }
  remove(uid) {
    delete this.userGroup[uid]    
    this.afs.collection("Vineyards").doc(this.ActivationKey).update({
      users: this.userGroup
    }).then(res => {
      this.newEmail = null;
      this.notify.toaster(this.translate.instant("Používateľ odstránený"), "", "success")
    })

  }
  ngOnDestroy() {
    // To protect you, we'll throw an error if it doesn't exist.
  }
}

