import { Component, Inject, isDevMode } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { VineyardService } from "./_services/vineyard.service";
import { untilDestroyed } from "ngx-take-until-destroy";
import LogRocket from "logrocket";
import { AuthenticationService } from "./_services/authentication.service";
import { NotificationsService } from "./_services/notifications.service";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { HttpClient } from "@angular/common/http";
import { GlobalService } from "./_services/global.service";
import { BehaviorSubject, Observable } from "rxjs";
import { GalatiService } from "./_services/galati.service";
import { TranslateService } from "@ngx-translate/core";

import { GalatiPost } from "./_classes/galati-post";
import { AngularFireAuth } from "@angular/fire/auth";
import { AngularFirestore } from '@angular/fire/firestore';
import { WeatherService } from './_services/weather.service';
import { addDays, format, subDays } from 'date-fns';
import { AngularFireFunctions } from '@angular/fire/functions';
import { start } from 'repl';


@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  wizard: boolean;
  user: firebase.User;
  myVineyards;
  phenoBehaviorSubject = new BehaviorSubject<boolean>(true);
  weekOfSeason: any;
  weekOfYear: any;
  userNeeded = true;

  constructor(
    private afs: AngularFirestore,
    public router: Router,
    public vineyardService: VineyardService,
    public authService: AuthenticationService,
    public notif: NotificationsService,
    public dialog: MatDialog,
    public http: HttpClient,
    public global: GlobalService,
    public galati: GalatiService,
    public weatherService: WeatherService,
    private fireAuth: AngularFireAuth,
    private translate: TranslateService,
  ) {






    router.events.pipe(untilDestroyed(this)).subscribe((val) => {
    });
    this.authService
      .userObs()
      .pipe(untilDestroyed(this))
      .subscribe((userData) => {
        this.authService.user = userData;
        this.afs.collection('Vineyards', ref => ref.where(`users.${this.authService.user.uid}`, ">=", "")).valueChanges().subscribe((rez) => {
          this.vineyardService.myVineyards = rez;
          this.vineyardService.myVineyardsBehSub.next(this.vineyardService.myVineyards);
          this.vineyardService.vineyardsInit();
        })
      });



    // if (!isDevMode()) {
      LogRocket.init("leki6l/vinlink");
      console.log("Production mode.");
      this.authService
        .userObs()
        .pipe(untilDestroyed(this))
        .subscribe((userData) => {
          this.user = userData;
          LogRocket.identify(this.user.uid, {
            name: this.user.displayName,
            email: this.user.email,
          });
        });
    // }

    this.vineyardService.wizardState
      .pipe(untilDestroyed(this))
      .subscribe((wizardState) => {
        this.wizard = wizardState;
      });
  }

  openPhenoChoicesDialog(vineyard) {
    const dialogRef = this.dialog.open(DialogChoosePhenophase, {
      width: "900px",
      panelClass: "dialog-responsive",
      disableClose: false,
      data: { vineyard: vineyard },
    });

    dialogRef
      .afterClosed().toPromise().then((finishedVineyard) => {
        this.galati.getSeason(finishedVineyard).then((res) => {
          this.vineyardService.myVineyards.filter(obj => {
            if (obj.ActivationKey === finishedVineyard.ActivationKey) {
              obj.galatiSeason = res;
            }
          });
        });
      });
  }

  ngAfterViewInit(): void {

    this.global.phenoDialog.pipe(untilDestroyed(this)).subscribe(res => {
      // console.trace(res)
      if (res) {
        this.openPhenoChoicesDialog(res);
      }
    })
    this.fireAuth.user.subscribe((user) => {
      if (user && this.userNeeded) {
        this.afs.collection("users").doc(user.uid).valueChanges().pipe(untilDestroyed(this))
          .subscribe((data) => {
            if (data)
              this.authService.vinlinkUserBeh.next(data);
            this.global.lang = data["lang"];
            this.global.langObservable.next(data["lang"]);
            this.translate.use(data["lang"]);
            this.userNeeded = false;
          }, err => {
            console.log("get user  lang err", err)
          })
      }
    })
  }
  ngOnDestroy() {
    // To protect you, we'll throw an error if it doesn't exist.
  }
}
@Component({
  selector: "dialog-phenophases-info",
  templateUrl: "./dialogs/choose-phenophase/choose-phenophase.html",
  styleUrls: ["./dialogs/choose-phenophase/choose-phenophase.scss"],
})
export class DialogChoosePhenophase {
  weekOfSeason;
  yearWeek;
  chosenPhenophase: string;
  phenoOptions: any;
  seasonWeekMonday: any;
  locale = this.global.lang;
  weekOfYear;
  message: string = "";
  seasonWeekSunday: Date;
  weekOfSeasonDates;
  diseaseSeverity;
  severity = null;
  galatiInputGet;
  galatiPost = new GalatiPost();
  isLoading = false;
  closePrompt = false;
  allPesticides: any;
  searchTerm: any;
  pestResult: any;
  chosenPests = [];
  striekaloSa = false;
  chosenPestsIDs: any;
  vineyard;
  allPesticidesSchemeIgnored: any;
  allPesticidesSchemed: Object;
  ignoreScheme: any;
  redWeekNumTransition: boolean;
  weekOfExpectedPhenophase: string;
  datumOtazkyOsetrovania: string;

  constructor(
    public router: Router,
    public http: HttpClient,
    public dialogRef: MatDialogRef<DialogChoosePhenophase>,
    public global: GlobalService,
    public galati: GalatiService,
    public fns: AngularFireFunctions,
    public vineServ: VineyardService,
    public notif: NotificationsService,
    public translate: TranslateService,
    public weatherServ: WeatherService,
    public afs: AngularFirestore,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.refresh();



  }

  getPestName(pestID) {
    let pest = this.allPesticides.find(o => o.id === pestID);
    console.log("getPestName", pestID, this.chosenPests, pest)
    return pest.product;
  }
  removePest(pestID) {
    var idx = this.chosenPests.indexOf(pestID);
    this.chosenPests.splice(idx, 1);
  }
  setPest(pestID) {
    var idx = this.chosenPests.indexOf(pestID);

    // is currently selected
    if (idx > -1) {
      this.chosenPests.splice(idx, 1);
    }

    // is newly selected
    else {
      this.chosenPests.push(pestID);
    }
    console.log("chosen pests", pestID, this.chosenPests, JSON.stringify(this.chosenPests));
  }

  toggleCheckBox(pestID) {
    return (this.chosenPests.indexOf(pestID) != -1) ? true : false;
  }

  getChosenPestsIDs() {
    return this.chosenPests.map(a => a.id);
  }

  async submitPhenosToGalati(phenophase, vineyard, week) {
    if (this.shouldNotifySpraying()) return;

    const galDates = await this.galati.getDatesFromWeekNum(week);
    this.isLoading = true;
    const startDate = this.formatStartDate(galDates["monday"]);
    let weekMeteo;
    console.warn("submitPhenosToGalati", vineyard)
    try {
      try {
        // Attempt to fetch week meteo data
        console.log("submit start date", startDate)
        weekMeteo = await this.fetchWeekMeteo(vineyard, startDate);
      } catch (error) {
        // If fetchWeekMeteo fails, log the error and set weekMeteo to null to trigger alternative data fetching
        console.error("Error fetching week meteo data", error);
        weekMeteo = null;
      }

      // Check if valid weekMeteo data was fetched or set to null explicitly
      if (!weekMeteo || !weekMeteo["AvgTemp"] || !weekMeteo["rainSUM"]) {
        console.warn("No valid historical meteo data, fetching alternative data");

        const externalMeteoData = await this.weatherServ.getExternalMeteoForPreviousWeek(vineyard, galDates["monday"]);
        // Proceed with alternative data if available
        if (externalMeteoData) {
          await this.submitDataToGalati(externalMeteoData, phenophase, vineyard, await this.weatherServ.fetchForecastData(vineyard));
        } else {
          // Handle case where no alternative data is available
          console.error("No alternative meteo data available");
        }
      } else {
        // Proceed with original data if valid
        await this.submitDataToGalati(weekMeteo, phenophase, vineyard, await this.weatherServ.fetchForecastData(vineyard));
      }
    } catch (err) {
      console.error("Error submitting data", err);
    } finally {
      this.resetForm();
    }
  }

  async submitDataToGalati(weekMeteo, phenophase, vineyard, forecastData) {
    const payload = this.preparePayload(weekMeteo, phenophase, vineyard, forecastData);
    const response = await this.http.post(`https://2024.galati.sk/api-24/input/?key=${vineyard.galatiKey}&lang=${this.global.lang}`, payload).toPromise();
    console.log("result after submitting pheno to galati", response);
    this.notifySuccess();
  }

  preparePayload(weekMeteo, phenophase, vineyard, forecastData) {
    return {
      weekNum: this.weekOfSeason,
      temp: weekMeteo["AvgTemp"],
      rainfall: weekMeteo["rainSUM"],
      impulsiveRainfall: weekMeteo["impulsiveRainfall"],
      forecastTemp: forecastData.forecastTemp,
      forecastRain: forecastData.forecastRain,
      pestManagement: {
        pesticideIds: this.chosenPests
      },
      phenophase: phenophase,
      diseaseEvaluation: {
        p: this.galatiPost.p.severity,
        m: this.galatiPost.m.severity,
        b: this.galatiPost.b.severity,
      },
    };
  }


  shouldNotifySpraying() {
    if (this.striekaloSa && this.chosenPests.length < 1) {
      this.notif.toaster(this.translate.instant("Ak sa vinohrad postrekoval, je potrebné vybrať aspoň jeden z ponuky prípravkov."), "", "danger");
      return true;
    }
    return false;
  }

  formatStartDate(mondayDate) {
    return format(subDays(new Date(mondayDate), 7), 'yyyy-MM-dd');
  }

  async fetchWeekMeteo(vineyard, startDate) {
    const url = `https://backend.vinlink.eu/2023/galati_calculate_meteo.php?StationID=${vineyard.StationID}&startDate=${startDate}`;
    return await this.http.get(url).toPromise();
  }










  // // !! toto treba upravit tak aby to fungovalo s OWM api
  // async getMeteoFromOpenWeatherMap(vineyard, galDates) {
  //   // Convert start and end dates to Unix timestamps
  //   const startDate = new Date(galDates["monday"]);
  //   startDate.setDate(startDate.getDate() - 7); // 7 days before the start date
  //   const startTimestamp = Math.floor(startDate.getTime() / 1000);

  //   const endDate = new Date(galDates["monday"]);
  //   endDate.setDate(endDate.getDate() - 1); // 1 day before the start date
  //   const endTimestamp = Math.floor(endDate.getTime() / 1000);

  //   const url = `https://api.openweathermap.org/data/3.0/onecall/day_summary?lat=${vineyard.lat}&lon=${vineyard.lng}&date=${startTimestamp}&appid=${this.global.owmApiKey}`;

  //   try {
  //     const response = await fetch(url);
  //     const weatherData = await response.json();

  //     // Process the weather data to extract required information (e.g., average temperature, cumulative rain)
  //     // const processedData = this.processOpenWeatherMapData(weatherData);

  //     // Log or use the processed data as needed
  //     console.log("OpenWeatherMap Data:", weatherData);

  //     // return weatherData;
  //   } catch (error) {
  //     console.error("Error fetching weather data from OpenWeatherMap:", error);
  //     // Implement appropriate error handling
  //   }
  // }

  // This is a placeholder function. You'll need to implement it based on how OpenWeatherMap structures its historical data.
  // processOpenWeatherMapData(weatherData) {
  //   // Process the OpenWeatherMap data to extract the required information, such as avgTemp, cumulativeRain, oneDayRain
  //   // This is highly dependent on your specific requirements and how you wish to interpret the data from OpenWeatherMap
  //   // Example:
  //   return {
  //     AvgTemp: calculateAverageTemperature(weatherData),
  //     rainSUM: calculateCumulativeRain(weatherData),
  //     impulsiveRainfall: checkForOneDayRain(weatherData),
  //   };
  // }

  processWeatherData(weatherData) {
    let cumulativeRain = 0;
    let tempSum = 0;
    let tempCount = 0;
    let oneDayRain = false;

    weatherData.days.forEach(day => {
      if (day.hasOwnProperty("precip")) {
        cumulativeRain += day.precip;
        if (day.precip > 0) {
          oneDayRain = true;
        }
      }

      if (day.hasOwnProperty("temp")) {
        tempSum += day.temp;
        tempCount++;
      }
    });

    const avgTemp = tempCount > 0 ? (tempSum / tempCount).toFixed(1) : 0;
    return { avgTemp, cumulativeRain: cumulativeRain.toFixed(1), oneDayRain };
  }



  resetForm() {
    this.chosenPhenophase = null;
    this.chosenPests = [];
    this.striekaloSa = false;
    this.isLoading = false;
    this.refresh();
    this.vineServ.getMyVineyards();
    window.scrollTo(0, 0);
  }

  notifySuccess() {
    this.notif.toaster(this.translate.instant("Phenophase saved successfully"), "", "success", "", "3500");
  }


  searchPests() {
    this.pestResult = this.allPesticides.filter((item) => {
      let vendor;
      if (item.vendor) {
        vendor = item.vendor;
      } else { vendor = ""; }
      return (item.product.toLowerCase().includes(this.searchTerm) || vendor.toLowerCase().includes(this.searchTerm));
    });
  }

  pestSchemeOnOff(e) {
    console.log("XYZ", e, this.ignoreScheme);
    if (this.ignoreScheme === true) {
      this.allPesticides = this.allPesticidesSchemeIgnored;
      this.pestResult = this.allPesticides;
    }
    if (this.ignoreScheme === false) {
      this.allPesticides = this.allPesticidesSchemed;
      this.pestResult = this.allPesticides;
    }
    console.log("all pests", this.allPesticides, "ignored", this.allPesticidesSchemeIgnored, "schemed", this.allPesticidesSchemed)
    if (this.searchTerm) {
      this.searchPests();
    }
  }


  async refresh() {

    this.http
      .get(`https://2024.galati.sk/api-24/pesticides/?key=${this.data.vineyard.galatiKey}&lang=${this.global.lang}&diseaseManagementScheme=all`)
      .toPromise()
      .then(res => {
        this.allPesticidesSchemeIgnored = res;
      }).catch(err => {
        console.log("get pesticides error", err)
      });
    this.galati.galatiInputGet(this.data.vineyard.galatiKey).then(async (res) => {
      this.redWeekNumTransition = false;
      this.galatiInputGet = res;
      this.allPesticidesSchemed = this.galatiInputGet.pesticides;
      this.allPesticides = this.allPesticidesSchemed;
      this.pestResult = this.allPesticidesSchemed;
      this.phenoOptions = res["phenoOptions"];
      this.phenoOptions.forEach((pheno) => {
        pheno.expanded = false;
      });
      this.chosenPhenophase = null;
      this.galatiPost.m = { severity: null };
      this.galatiPost.p = { severity: null };
      this.galatiPost.b = { severity: null };


      if (res["weekNum"] === 0 && res["weekOptions"]) {
        //! ak JE prvotny tyzden galati zadavania
        this.weekOfSeason = 0;

        if (this.vineServ.weekOfYear <= 12) {
          this.weekOfSeason = this.vineServ.weekOfYear;
          // alert(this.vineServ.weekOfYear)
        } else if (this.vineServ.weekOfYear > 12) {
          this.weekOfSeason = 12;
        }

        const foundOption = res["weekOptions"].find(option => option.key == `${this.weekOfSeason}`);
        console.log(res["weekOptions"]);
        this.weekOfSeasonDates = `${format(new Date(foundOption.value), 'dd.MM.yyyy')} - ${format(addDays(new Date(foundOption.value), 7), 'dd.MM.yyyy')}`
        this.weekOfExpectedPhenophase = this.weekOfSeasonDates;
        this.datumOtazkyOsetrovania = `${format(subDays(new Date(foundOption.value), 7), 'dd.MM.yyyy')} - ${format(subDays(new Date(foundOption.value), 1), 'dd.MM.yyyy')}`

      } else {
        //! ak NIE JE prvotny tyzden galati zadavania
        this.weekOfSeason = res["weekNum"];
        this.galati.getDatesFromWeekNum(res["weekNum"]).then(result => {
          this.weekOfSeasonDates = result["weekMsg"];
        })
        this.galati.getDatesFromWeekNum(res["weekNum"] - 1).then(result => {
          this.datumOtazkyOsetrovania = result["weekMsg"];
        })
        if (this.weekOfSeason > this.vineServ.weekOfYear) {
          const sendSMS = this.fns.httpsCallable('SMSafterPheno');
          const sendMailAfterPheno = this.fns.httpsCallable('mailAfterPheno4');
          this.galati.getSeason(this.data.vineyard).then(seasonRes => {
            this.data.vineyard.galatiSeason.season = seasonRes["season"];
            const tyzden = seasonRes["season"].at(-1);
            const minulyTyzden = seasonRes["season"].at(-2);


            sendMailAfterPheno({
              vineyard: this.data.vineyard,
              lang: this.global.lang
            }).toPromise().then(mailRes => {
              console.log("mail RES", mailRes);
            }).catch(mailErr => {
              console.warn("mail ERR", mailErr);
            })


            this.afs.collection("users").doc(this.data.vineyard.ownerUID).get().toPromise().then(frstUserRes => {
              console.log("frstUserRes", frstUserRes.data());
              const firestoreUser = frstUserRes.data();
              if (firestoreUser.phone && firestoreUser.wantSMS) {
                let phoneNumber = firestoreUser.phone;
                sendSMS({
                  phoneNumber: phoneNumber,
                  SMScontent: `VinLink - ${this.data.vineyard.Alias}\n${tyzden.weekNum} .${this.translate.instant("week")}\n${this.translate.instant("Phenophase")}: ${tyzden.phenoMsg}\n\n${this.translate.instant("infect.m")}: ${tyzden.m.msg} ${tyzden.m.signalMsg}\n\n${this.translate.instant("infect.p")}: ${tyzden.p.msg} ${tyzden.p.signalMsg}\n\n${this.translate.instant("infect.b")}: ${tyzden.b.msg} ${tyzden.b.signalMsg}\n\n${this.translate.instant("Teplota a zrážky min. týždeň")}: \n${minulyTyzden.temp} C, ${minulyTyzden.rainfall} mm`
                }).toPromise().then(smsRes => {
                  console.log("SMS RES", smsRes); 
                }).catch(smsERR => {
                  console.warn("SMS ERR", smsERR);
                })
              }
            })
          })
          // weeknum v poslednom zazname v galati je vyssi ako realne prebiehajuci tyzden
          console.log("weekOfSeason > weekOfYear");
          // this.vineServ.vineyardUpdateData(this.data.vineyard)
          this.dialogRef.close(this.data.vineyard);
          console.log("pheno dialog closed");
          this.vineServ.getMyVineyards();
        }
      }

    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
  ngOnDestroy() {
    // To protect you, we'll throw an error if it doesn't exist.
  }
}
