import { Injectable } from "@angular/core";
import { Location, PopStateEvent } from "@angular/common";
import { TranslateService } from "@ngx-translate/core";
import { Router } from "@angular/router";
import { BehaviorSubject, Observable } from "rxjs";
import { format, getDay, subDays } from "date-fns";
@Injectable({
  providedIn: "root",
})
export class GlobalService {
  appVersion = "2024.004"; 
  openWeatherUpdated;
  lang: string;
  langObservable: BehaviorSubject<string>;
  owmApiKey = "bd75288a1724ebcf62760adb9d999445";
  //! 
  phenoVineyard = null;
  phenoDialog = new BehaviorSubject<any>(this.phenoVineyard);
  // !

  constructor(
    public location: Location,
    private translate: TranslateService,
    private router: Router
  ) {
    this.langObservable = new BehaviorSubject<string>(this.lang);
    this.translate.setDefaultLang("sk");
    this.lang = "sk";
    this.langObservable.next(this.lang);

  }
  nowSubDays(amount = 1) {
    const date = format(subDays(new Date(), amount), "yyyy/MM/dd HH:mm");
    return new Date(date);
  }


  todaySubDays(amount = 1) {
    var d = new Date();
    d.setHours(0, 0, 0, 0);
    const date = format(subDays(d, amount), "yyyy/MM/dd HH:mm");
    return new Date(date);
  }
  isPage(path: string) {
    let pageTitle;
    // let pageTitle = this.location.prepareExternalUrl(this.location.path());
    pageTitle = this.router.url.slice(1);
    if (path === pageTitle) {
      return true;
    } else {
      return false;
    }
  }
}
