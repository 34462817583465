import { Component, OnInit } from "@angular/core";

@Component({
  templateUrl: "./calendar-page.component.html",
  styleUrls: ["./calendar-page.component.scss"],
})
export class CalendarPageComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
