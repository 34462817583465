import { Injectable } from "@angular/core";
import echarts from "assets/js/echarts-en.min.js";
import { WeatherService } from "./weather.service";
import { registerTheme, getInstanceByDom } from "echarts";

import { BehaviorSubject } from "rxjs/BehaviorSubject";
import {
  format,
  eachDayOfInterval,
  endOfYear,
  startOfYear,
  isAfter,
  isBefore,
  subDays,
  getUnixTime,
  getDayOfYear,
  differenceInDays,
} from "date-fns";
import { TranslateService } from "@ngx-translate/core";
import { Observable } from "rxjs/Observable";

//
@Injectable({
  providedIn: "root",
})
export class ChartService {
  charts = [];
  legends = {};
  dataZoomStartDate: string;
  dataZoomEndDate: string;
  dataZoomStartDateObservable: BehaviorSubject<any>;
  chartLegendsObservable: BehaviorSubject<any>;
  color = {
    temperature: "#f44336",
    humidity: "#9cae2a",
    lux: "#fd9803",
    rain: "#2a68ae",
  };
  temperatureColor = "#f44336";
  humidityColor = "#9cae2a";
  luxColor = "#fd9803";
  rainColor = "#2a68ae";
  unit = {};

  constructor(private ws: WeatherService, private t: TranslateService) {
    window.onresize = () => {
      this.charts.forEach((obj) => {
        obj.resize();
        console.log(obj);
      });
    };
    this.dataZoomStartDate = "1970-01-01 00:00:00";
    this.dataZoomStartDateObservable = new BehaviorSubject([]);
    this.unit[this.t.instant("temperature")] = "°C";
    this.unit[this.t.instant("humidity")] = "%";
    this.unit[this.t.instant("meteoData.light")] = "W/m²";
    this.unit[this.t.instant("rain")] = "mm";
    console.log(this.unit);
    this.chartLegendsObservable = new BehaviorSubject(this.legends);
    this.legends[this.t.instant("temperature")] = true;
    this.legends[this.t.instant("humidity")] = false;
    this.legends[this.t.instant("meteoData.light")] = false;
    this.legends[this.t.instant("rain")] = true;

  }

  legendToggle(chartID, velicina) {
    const chartElement = <HTMLCanvasElement>document.getElementById(chartID);
    let chart;
    if (echarts.getInstanceByDom(chartElement)) {
      chart = echarts.getInstanceByDom(chartElement);
    } else {
      chart = echarts.init(chartElement, "essos");
      this.charts.push(chart);
    }
    chart.dispatchAction({
      type: "legendToggleSelect",
      name: this.t.instant(velicina),
    });
    console.log("THIS CHARTS", this.charts)
    chart.resize();

    this.legends[velicina] = !this.legends[velicina];
  }


  setOptions(chartID, meteoData, insert_datetime, refresh?) {
    let insert_date = format(new Date(insert_datetime), 'yyyy-MM-dd');
    let chart;
    let datesFormated;
    const chartElement = <HTMLCanvasElement>document.getElementById(chartID);

    if (echarts.getInstanceByDom(chartElement)) {
      chart = echarts.getInstanceByDom(chartElement);
    } else {
      chart = echarts.init(chartElement, "essos");
      this.charts.push(chart);
    }
    chart.on("legendselectchanged", (params) => {
      console.log("legends", params.selected);
      this.legends = params.selected;

      if (this.legends[this.t.instant("meteoData.light")]) {
        chart.setOption({
          yAxis: { id: "yAxislux", min: 0, max: 1000 },
        });
      } else {
        chart.setOption({
          yAxis: { id: "yAxislux", min: null, max: null },
        });
      }
      this.chartLegendsObservable.next(this.legends);
    });
    chart.on("datazoom", (evt) => {
      const axis = chart.getModel().option.xAxis[0];
      const starttime = axis.data[axis.rangeStart];
      const endtime = axis.data[axis.rangeEnd];
      const zoomedDates = [starttime];
      this.dataZoomStartDateObservable.next([starttime, endtime]);
    });

    if (
      isAfter(new Date(insert_datetime), new Date(meteoData[0].insert_datetime.replace(/-/g, "/")))
    ) {
      const end = new Date().getTime();
      const start = new Date(insert_datetime).getTime();
      const result = meteoData.filter((d) => {
        var time = new Date(d.insert_datetime).getTime();
        return start < time && time < end;
      });
      datesFormated = Object.keys(result).map((key) => {
        const asDate = new Date(result[key].insert_datetime.replace(/-/g, "/"));
        // return format(asDate, "dd.MM.yyyy, HH:mm");
        return asDate;
      });
    } else {
      datesFormated = Object.keys(meteoData).map((key) => {
        const asDate = new Date(
          meteoData[key].insert_datetime.replace(/-/g, "/")
        );
        return asDate;
        // return format(asDate, "dd.MM.yyyy, HH:mm");
      });
    }

    const temperature = Object.keys(meteoData).map((key) =>
      parseFloat(meteoData[key].temperature)
    );
    const humidity = Object.keys(meteoData).map(
      (key) => meteoData[key].humidity
    );
    const lux = Object.keys(meteoData).map(
      (key) => meteoData[key].lux
    );
    const rain = Object.keys(meteoData).map((key) => meteoData[key].rain);
    let rainMax = 0;
    rain.forEach((element) => {
      element = parseFloat(element);
      if (element > rainMax) {
        rainMax = element;
      }
    });
    const temperatureUnit = "°C";
    const humidityUnit = "%";
    const humidityMin = 0;
    const humidityMax = 100;
    const luxUnit = "W/m²";
    const luxMin = 0;
    const luxMax = 1000;
    const rainUnit = "mm";
    const rainMin = 0;

    if (refresh === true) {
      chart.clear();
    }

    chart.setOption({
      grid: [
        {
          show: true,
          containLabel: true,
          left: 40,
          top: 10,
          right: 260,
          bottom: 10,
          // width: 360,
        },
      ],
      responsive: true,
      baseOption: {
        legend: {
          orient: "horizontal", // 'vertical'
          x: "right", // 'center' | 'left' | {number},
          y: "top", // 'center' | 'bottom' | {number}
          selected: {
            selectedMode: "multiple",
          },
        },
        series: [
          {
            color: this.temperatureColor, // farba danej veliciny, napr. teplota cervena
            name: this.t.instant("temperature"),
            type: "line",
            data: temperature,
            yAxisIndex: 0,
            animation: true,
            lineStyle: {
              color: this.temperatureColor,
              borderColor: this.temperatureColor,
            },
            markPoint: {
              label: {
                formatter: `{b}: {c} ${temperatureUnit}`,
                position: "bottom",
                backgroundColor: "#fff",
              },
              data: [
                {
                  type: "max",
                  name: "max",
                  symbol: "arrow",
                  symbolRotate: 180,
                  symbolOffset: [0, "-100%"],
                  symbolSize: [5, 8],
                  itemStyle: {
                    color: this.temperatureColor,
                    borderColor: this.temperatureColor,
                  },
                },
                {
                  type: "min",
                  name: "min",
                  symbol: "arrow",
                  symbolOffset: [0, 10],
                  symbolSize: [5, 8],
                  itemStyle: {
                    color: this.temperatureColor,
                    borderColor: this.temperatureColor,
                  },
                },
              ],
            },
            markLine: {
              label: {
                formatter: (params) => {
                  return `${this.t.instant(
                    "meteoData.Avg"
                  )}: ${params.value.toFixed(1)}°C`;
                },
                position: "middle",
              },
              data: [
                {
                  type: "average",
                  name: this.t.instant("meteoData.Avg"),
                },
              ],
            },
          },
          {
            color: this.humidityColor,
            name: this.t.instant("humidity"),
            type: "line",
            data: humidity,
            yAxisIndex: 1,
            animation: true,
            lineStyle: {
              color: this.humidityColor,
              borderColor: this.humidityColor,
            },
            markPoint: {
              label: {
                formatter: `{b}: {c} ${humidityUnit}`,
                position: "bottom",
                backgroundColor: "#fff",
              },
              data: [
                {
                  type: "max",
                  name: "max",
                  symbol: "arrow",
                  symbolRotate: 180,
                  symbolOffset: [0, "-100%"],
                  symbolSize: [5, 8],
                  itemStyle: { color: this.humidityColor },
                },
                {
                  type: "min",
                  name: "min",
                  symbol: "arrow",
                  symbolOffset: [0, 10],
                  symbolSize: [5, 8],
                  itemStyle: { color: this.humidityColor },
                },
              ],
            },
            markLine: {
              label: {
                formatter: (params) => {
                  return `${this.t.instant(
                    "meteoData.Avg"
                  )}: ${params.value.toFixed(0)}%`;
                },
                position: "middle",
              },
              data: [
                {
                  type: "average",
                  name: this.t.instant("meteoData.Avg"),
                },
              ],
            },
          },
          {
            color: this.luxColor,
            name: this.t.instant("meteoData.light"),
            type: "line",
            data: lux,
            yAxisIndex: 2,
            animation: true,
            lineStyle: {
              color: this.luxColor,
              borderColor: this.luxColor,
            },
            markPoint: {
              label: {
                formatter: `{b}: {c} ${luxUnit}`,
                position: "bottom",
                // color: "white",
                // backgroundColor: this.luxColor,
                // padding: 3,
                // borderRadius: 3,
              },
              data: [
                {
                  type: "max",
                  name: "max",
                  symbol: "arrow",
                  symbolRotate: 180,
                  symbolOffset: [0, "-100%"],
                  symbolSize: [5, 8],
                  itemStyle: {},
                },
                {
                  type: "min",
                  name: "min",
                  symbol: "arrow",
                  symbolOffset: [0, 10],
                  symbolSize: [5, 8],
                  itemStyle: {},
                },
              ],
            },
          },
          {
            color: this.rainColor,
            name: this.t.instant("rain"),
            type: "bar",
            data: rain,
            yAxisIndex: 3,
            animation: true,
            lineStyle: {
              color: this.rainColor,
              borderColor: this.rainColor,
            },
          },
        ],
        xAxis: [
          {
            boundaryGap: false,
            data: datesFormated,
            axisLabel: {
              // rotate: 30,

              formatter: function (value, index) {
                var date = new Date(value);
                return format(date, "dd.MM.yyyy, HH:mm");
              },
            },
            type: "category",
            axisLine: {
              onZero: true,
              lineStyle: {
                color: "#999",
              },
            },
          },
        ],
        yAxis: [
          {
            id: "yAxisTemperature",
            zlevel: 9,
            axisLabel: {
              formatter: `{value} ${temperatureUnit}`,
              inside: false,
              backgroundColor: this.temperatureColor,
              color: "white",
              padding: 4,
              borderRadius: 4,
              // fontSize: 12,
            },
            // name: temperatureUnit,
            type: "value",
            position: "left",
            splitLine: {
              // show: false,
              zlevel: 0,
              lineStyle: { color: ["rgba(0,0,0,0.07)"] },
              axisLine: {
                lineStyle: {
                  color: this.temperatureColor,
                },
              },
            },
          },
          {
            id: "yAxisHumidity",
            zlevel: 9,
            axisLabel: {
              formatter: `{value} ${humidityUnit}`,
              inside: false,
              backgroundColor: this.humidityColor,
              color: "white",
              padding: 4,
              borderRadius: 4,
              // fontSize: 12,
            },
            // name: humidityUnit,
            type: "value",
            // min: Math.ceil(humidityMin),
            // max: Math.ceil(humidityMax),
            position: "left",
            offset: 50,
            splitLine: {
              // show: false,
              lineStyle: { color: ["rgba(0,0,0,0.07)"] },
            },
            axisLine: {
              lineStyle: {
                color: this.humidityColor,
              },
            },
          },
          {
            id: "yAxislux",
            zlevel: 9,
            // name: luxUnit,
            type: "value",
            position: "right",
            offset: 57,
            // min: Math.ceil(luxMin),
            // max: Math.ceil(luxMax),
            splitLine: {
              // show: false,
              lineStyle: { color: ["rgba(0,0,0,0.07)"] },
            },
            axisLabel: {
              formatter: `{value} ${luxUnit}`,
              inside: false,
              backgroundColor: this.luxColor,
              color: "white",
              padding: 4,
              borderRadius: 4,
              // fontSize: 12,
            },
            axisLine: {
              lineStyle: {
                color: this.luxColor,
              },
            },
          },
          {
            id: "yAxisRain",
            zlevel: 9,
            axisLabel: {
              formatter: `{value} ${rainUnit}`,
              inside: false,
              backgroundColor: this.rainColor,
              color: "white",
              padding: 4,
              borderRadius: 4,
              // fontSize: 12,
            },
            // name: rainUnit,
            type: "value",
            position: "right",
            offset: 0,
            min: 0,
            max: (rainMax * 1.2).toFixed(1),
            splitLine: {
              // show: false,
              lineStyle: { color: ["rgba(0,0,0,0.07)"] },
            },
            axisLine: {
              lineStyle: {
                color: this.rainColor,
              },
            },
          },
        ],
        dataZoom: [
          {
            id: "dataZoomX",
            type: "slider",
            // type: "inside",
            xAxisIndex: [0],
            filterMode: "filter",
            backgroundColor: "#fafafa",
            dataBackgroundColor: "rgba(155, 173, 40, 0.4)",
            fillerColor: "rgba(155, 173, 40, 0.2)",
            handleColor: "#9bad28",
            handleSize: "100%",
            bottom: 0,
            labelFormatter: function (value, valueStr) {
              return format(new Date(valueStr), "dd.MM.yyyy, HH:mm");
            },
            textStyle: {
              color: "#333333",
            },
          },
          // {
          //   id: "dataZoomY",
          //   type: "slider",
          //   yAxisIndex: [0],
          //   filterMode: "empty",
          // },
        ],
        tooltip: {
          // formatter: function (params) {
          //   console.log("PARAMS", params);
          //   const dateTime = format(
          //     new Date(params[0].axisValue),
          //     "dd.MM.yyyy, HH:mm"
          //   );
          formatter: (params) => {
            var colorSpan = (color) =>
              '<span style="display:inline-block;margin-right:5px;border-radius:10px;width:9px;height:9px;background-color:' +
              color +
              '"></span>';
            let rez =
              "<p>" +
              format(new Date(params[0].axisValue), "dd.MM.yyyy, HH:mm") +
              "</p>";
            //console.log(params); //quite useful for debug
            params.forEach((item) => {
              let xx =
                "" +
                colorSpan(item.color) +
                " " +
                item.seriesName +
                ": " +
                item.data +
                " " +
                this.unit[item.seriesName] +
                "</br>";
              rez += xx;
            });

            return rez;
          },
          // },
          trigger: "axis",
          // formatter: `{b} <br />{a}: {c} <br />{a1}: {c1} ${humidityUnit}`,
          axisPointer: {
            type: "cross",
            animation: false,
            label: { backgroundColor: "#505765", show: false },
          },
        },
      },
    });


    chart.dispatchAction({
      type: "legendUnSelect",
      name: this.t.instant("meteoData.light"),
    });
    chart.dispatchAction({
      type: "legendUnSelect",
      name: this.t.instant("humidity"),
    });


  }
  rainChart(chartID: string, rainData) {
    let chart;
    const rainArray = [];
    const meteoDataDays = [];
    let sortedRainData = [];
    let yearlyRainSUM = 0;
    let month;
    let monthLast;
    let sum: number;
    let maxSum = 0;
    const chartElement = <HTMLCanvasElement>document.getElementById(chartID);
    rainData = rainData.sort((a, b) => a.date - b.date);
    console.log(
      "sorted original rain data",
      rainData.sort((a, b) => a.date - b.date)
    );
    if (echarts.getInstanceByDom(chartElement)) {
      chart = echarts.getInstanceByDom(chartElement);
    } else {
      chart = echarts.init(chartElement, "essos");
      // this.charts.push(chart);
      // console.log("this.charts", this.charts);
    }
    this.charts.push(chart);
    const daysOfYear = eachDayOfInterval({
      // vytvorit array vsetkych dni tohto roka
      start: startOfYear(new Date()),
      end: endOfYear(new Date()),
    });

    daysOfYear.forEach((meteoDay) => {
      let found = rainData.find((rainDay) => {
        if (rainDay.date === format(meteoDay, "yyyy-MM-dd")) {
          return rainDay;
        }
      });
      let dayRainSUM;
      if (found) {
        dayRainSUM = found.dailyRainSum;
      } else {
        dayRainSUM = 0;
      }

      // console.log(
      //   "PUSHING, dayRainSum",
      //   dayRainSUM,
      //   "float",
      //   parseFloat(dayRainSUM)
      // );
      rainArray.push({
        date: new Date(meteoDay),
        rain: parseFloat(dayRainSUM),
      });
      yearlyRainSUM = yearlyRainSUM + parseFloat(dayRainSUM);
    });

    sortedRainData = rainArray.sort((a, b) => a.date - b.date);
    console.log("sortedRainData", sortedRainData);
    sortedRainData.forEach((e) => {
      month = new Date(e.date).getMonth();
      if (new Date(e.date) >= subDays(new Date(), 1)) {
        sum = 0;
      } else {
        // ak je datum skorsi ako dnesok
        if (month !== monthLast) {
          // .. a ak nastal prelom mesiacov
          monthLast = month;
          e.date = new Date(e.date);
          sum = 0 + parseFloat(e.rain); // ak nastal prelom mesiacov, tak vynulujeme sumu dazda
        } else {
          sum = sum + parseFloat(e.rain);
        } // ak nenastal prelom mesiacov
      }
      if (sum > maxSum) {
        maxSum = sum;
      }
      e.rainSUM = sum.toFixed(1);
    });
    console.log("sortedRainData", sortedRainData);
    const rainDates = Object.keys(sortedRainData).map(function (key) {
      return sortedRainData[key].date;
    });
    const rainSUMs = Object.keys(sortedRainData).map(function (key) {
      return sortedRainData[key].rainSUM;
    });
    console.log("rainDates", rainDates);
    console.log("rainSUMs", rainSUMs);

    chart.setOption({
      // baseOption: {
      // timeline: {
      // data: ["2020"]
      // }
      // },
      // options: [
      //   {
      // chart options
      grid: [
        {
          show: true,
          containLabel: true,
          left: 20,
          top: 20,
          right: 20,
          bottom: 20,
        },
      ],
      xAxis: [
        {
          show: false,
          data: rainDates,
        },
        {
          splitLine: { show: false, lineStyle: { color: ["#eee"] } },
          data: [
            this.t.instant("Jan"),
            this.t.instant("Feb"),
            this.t.instant("Mar"),
            this.t.instant("Apr"),
            this.t.instant("May"),
            this.t.instant("Jun"),
            this.t.instant("Jul"),
            this.t.instant("Aug"),
            this.t.instant("Sep"),
            this.t.instant("Oct"),
            this.t.instant("Nov"),
            this.t.instant("Dec"),
          ],
        },
      ],
      yAxis: {
        yAxisIndex: 1,
        type: "value",
        min: 0,
        max: Math.round(maxSum) + 5,
        axisLabel: { show: true, formatter: "{value} mm" }, //stupnica pri y osi
      },
      series: [
        {
          name: "rain",
          type: "bar",
          data: rainSUMs,
          color: "#2a68ae",
          markLine: {
            symbol: "none",
            lineStyle: {
              color: "#555",
              type: "solid",
              width: 0.75,
            },
            data: [
              {
                label: {
                  show: true,
                  opacity: 0.5,
                  position: "bottom",
                  formatter: "{b}",
                },
                name: this.t.instant("today"),
                xAxis: getDayOfYear(new Date()) - 1,
              },
            ],
          },
        },
      ],

      tooltip: {
        trigger: "axis",
        // formatter: `{b} <br />{a}: {c} mm`, //datum, nazov, dazd

        formatter: (params) => {
          const param = params[0];
          const date = new Date(param.name);
          let rainValue;
          if (isAfter(date, new Date())) {
            rainValue = "--";
          } else if (differenceInDays(date, new Date()) == 0) {
            rainValue = this.t.instant("measuring");
          } else {
            rainValue = param.value + " mm";
          }
          return (
            date.getDate() +
            "." +
            (date.getMonth() + 1) +
            "." +
            date.getFullYear() +
            " : " +
            rainValue
          );
        },
        axisPointer: {
          type: "shadow",
          animation: true,
          lineStyle: {
            type: "solid",
            opacity: 1,
            color: "black",
          },
          shadowStyle: {
            opacity: 0.3,
            shadowColor: "black",
          },
          label: {
            show: false,
          },
        },
      },
      //   }
      // ]
    });
  }

}
