export class MapPoint {
  lat: 0;
  lng: 0;
  alt: 0;
  coords(lat, lng, alt) {
    this.lat = lat;
    this.lng = lng;
    this.alt = alt;
  }
}
