export class maxMinAvg {
  max: { value: number; date: Date };
  min: { value: number; date: Date };
  avg: string;

  constructor() {
    return {
      max: { value: 0, date: new Date() },
      min: { value: 0, date: new Date() },
      avg: ""
    };
  }
}
