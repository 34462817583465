import { Component, OnInit } from "@angular/core";
import { trigger, transition, style, animate } from "@angular/animations";
import { VineyardService } from "app/_services/vineyard.service";
import { untilDestroyed } from "ngx-take-until-destroy";

@Component({
  selector: "app-devices",
  templateUrl: "./devices.component.html",
  styleUrls: ["./devices.component.scss"],
  animations: [
    trigger("inOutAnimation", [
      transition(":enter", [
        style({ opacity: 0 }),
        animate(".25s ease-out", style({ opacity: 1 })),
      ]),
      transition(":leave", [
        style({ opacity: 1 }),
        animate(".25s ease-in", style({ opacity: 0 })),
      ]),
    ]),
  ],
})
export class DevicesComponent implements OnInit {

  constructor(public vineyardService: VineyardService) {}

  ngOnInit() {

  }


  ngOnDestroy() {
    // To protect you, we'll throw an error if it doesn't exist.
  }
}
