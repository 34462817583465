<div class="main-content">
	<div class="page-content">
		<div class="container-fluid">
			<h2 class="page-heading">{{'USER' | translate}}</h2>
			<div class="row">
				<div class="col user-card" *ngIf="authService.vinlinkUser">
					<div class="card card-profile ">
						<div class="card-avatar">
							<img class="img" src="{{authService.user.photoURL || './assets/img/faces/vinohradnik.png'}}" />
						</div>
						<div class="card-body">
							<!-- <h6 class="card-category text-gray">Užívateľský balík BASIC</h6> -->
							<h3 class="card-title">{{authService.user.displayName}}</h3>
							<p>{{authService.user.email}}</p>
							<p class="card-description">{{"Sign up date" | translate}} {{authService.user.metadata.creationTime| date: 'dd.M.yyyy, H:mm':undefined:locale}}. <br> {{ "Last sign in" | translate }}: {{authService.user.metadata.lastSignInTime| date: 'dd.M.yyyy, H:mm':undefined:locale}}
							</p>
							<h5 class="card-title">{{"User Data" | translate}}</h5>
							<form>
								<div class="row" style="padding:20px auto">
									<div class="col-9 m0a">
										<mat-form-field class="example-full-width">
											<input matInput placeholder="{{'Your name' | translate}}" name="username" [(ngModel)]="newDisplayName" (focus)="mode = 'edit'" [ngModelOptions]="{standalone: true}" />
										</mat-form-field>
									</div>
							
									<div class="col-9 m0a">
										<mat-form-field class="example-full-width">
											<input matInput placeholder="{{'Notifications e-mail address' | translate}}" type="email" name="notifEmail" [value]="oldNotificationsEmail" [(ngModel)]="newNotificationsEmail" (focus)="mode = 'edit'" [ngModelOptions]="{standalone: true}" />
										</mat-form-field>
									</div>
									<div class="col-9 m0a" style=" padding-bottom: 1.25em;color:black;">
										<mat-slide-toggle (change)="smsCheckbox()" class="example-margin" style="float: left;margin-right: 7px;" color="primary" [(ngModel)]="authService.vinlinkUser.wantSMS" [ngModelOptions]="{standalone: true}"></mat-slide-toggle>{{"Chcem dostávať SMS správy o infekčnom tlaku po zadaní fenofázy"|translate}}
									</div>
										<div class="col-9 m0a">
										<!-- <p style="text-align: left;font-weight: 500;"></p> -->
										<mat-form-field class="example-full-width" *ngIf="authService.vinlinkUser.wantSMS" [@inOutAnimation]>
											<input matInput placeholder="{{'Tel.č. (s predvoľbou,bez medzier)' | translate}}" type="tel" name="phone" (focus)="mode = 'edit'" [(ngModel)]="authService.vinlinkUser.phone" [ngModelOptions]="{standalone: true}" /><mat-icon matSuffix matTooltip='{{"Tel.č. pre zasielanie sms o infekčnom tlaku a meteodátach" | translate}}' style="margin-left:5px;color:#8b8b8b;">info_outline</mat-icon>
										</mat-form-field>

									</div>
								</div>
								<div class="col-10 m0a" style="padding: 0;margin-top:10px;">
									<div class="btn btn-primary col-11" *ngIf="mode === 'edit'" (click)="userInfoChange(newDisplayName,newEmail,newNotificationsEmail)" [@inOutAnimation]>{{'Save changes' | translate}}</div>
									<div class="btn btn-outline-primary col-11 submit-btn" (click)="authService.passwordReset()">{{'Change password' | translate}}</div>
									<button type="button" class="btn btn-primary col-11" *ngIf="authService.verifNeeded" (click)="authService.sendVerificationMail()"> {{'Resend verification email' | translate}} </button>
								</div>
							</form>
						</div>
					</div>
				</div>
				
			</div>
		</div>
	</div>
</div>