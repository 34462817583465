<div class="charts">
  <div class="well mobile-only"> {{'Please rotate your phone to see the chart' | translate}}</div>
  <div class="max-w-1300">
    <!-- <div echarts [options]="chartOption" class="demo-chart"></div> -->
    <div class="chart-controls  not-mobile">
      <div class="veliciny">
        <div class="velicina temperature" [ngClass]="{'active': charts.legends[translateString('temperature')]}">
          <div class="velicina-name"> <i class="fi flaticon-thermometer-2" style="padding-right:0;"></i> {{"temperature" | translate}}</div>
          <div (click)="this.charts.legendToggle(chartID,'temperature')">
            <div class="velicina-icon">
              <div class="mat-icon-wrapper">
                <mat-icon *ngIf="charts.legends[translateString('temperature')]">clear</mat-icon>
                <mat-icon *ngIf="!charts.legends[translateString('temperature')]">add</mat-icon>
              </div>
            </div>
          </div>
        </div>
        <div class="velicina humidity" [ngClass]="{'active': charts.legends[translateString('humidity')]}">
          <div class="velicina-name"> <i class="fi flaticon-humidity-2" style="padding-right:5px;"></i> {{"humidity" | translate}} </div>
          <div (click)="this.charts.legendToggle(chartID,'humidity')">
            <div class="velicina-icon">
              <div class="mat-icon-wrapper">
                <mat-icon *ngIf="charts.legends[translateString('humidity')]">clear</mat-icon>
                <mat-icon *ngIf="!charts.legends[translateString('humidity')]">add</mat-icon>
              </div>
            </div>
          </div>
        </div>
        <div class="velicina lux" [ngClass]="{'active': charts.legends[translateString('meteoData.light')]}">
          <div class="velicina-name"> <i class="fi flaticon-gauge-2" style="padding-right:8px;"></i> {{"meteoData.light" | translate}}</div>
          <div (click)="this.charts.legendToggle(chartID,'meteoData.light')">
            <div class="velicina-icon">
              <div class="mat-icon-wrapper">
                <mat-icon *ngIf="charts.legends[translateString('meteoData.light')]">clear</mat-icon>
                <mat-icon *ngIf="!charts.legends[translateString('meteoData.light')]">add</mat-icon>
              </div>
            </div>
          </div>
        </div>
        <div class="velicina rain" [ngClass]="{'active': charts.legends[translateString('rain')]}">
          <div class="velicina-name"> <i class="fi flaticon-rain-1" style="padding-right:8px;"></i> {{"rain" | translate}}</div>
          <div (click)="this.charts.legendToggle(chartID,'rain')">
            <div class="velicina-icon">
              <div class="mat-icon-wrapper">
                <mat-icon *ngIf="charts.legends[translateString('rain')]">clear</mat-icon>
                <mat-icon *ngIf="!charts.legends[translateString('rain')]">add</mat-icon>
              </div>
            </div>
          </div>
        </div>
      </div>
      <mat-button-toggle-group #group="matButtonToggleGroup" name="chartDates" class="" value="1">
        <mat-button-toggle [ngClass]="{'visible': latestDateInDays() <= 1}" value="1" aria-label="1 {{'day' | translate}}" (click)="sinceDate = globalService.todaySubDays(1)" (click)="chartChangeDate(globalService.todaySubDays(1))"> 1 {{'Day'|translate}} </mat-button-toggle>
        <mat-button-toggle [ngClass]="{'visible': latestDateInDays() <= 7}" value="7" focus aria-label="7 {{'days' | translate}}" (click)="sinceDate = globalService.todaySubDays(7)" (click)="chartChangeDate(globalService.todaySubDays(7))">7 {{'Days'|translate}} </mat-button-toggle>
        <mat-button-toggle [ngClass]="{'visible': latestDateInDays() <= 30}" aria-label="30 {{'days' | translate}}" (click)="sinceDate = globalService.todaySubDays(30)" (click)="chartChangeDate(globalService.todaySubDays(30))">30 {{'Days'|translate}} </mat-button-toggle>
        <mat-button-toggle>
          <button mat-icon-button id="litepicker" (click)="datepickerShow()">
            <mat-icon style="color: #939393;">calendar_today</mat-icon>
          </button>
        </mat-button-toggle>
      </mat-button-toggle-group>
      <h5 style="margin-top:25px;" *ngIf="sinceDate && untilDate">{{"since"|translate}} <b>{{ sinceDate | date: 'short':undefined:globalService.lang}}</b> {{"until"|translate}} <b>{{ untilDate | date: 'short':undefined:globalService.lang}}</b></h5>
    </div>
  </div>
  <div class="mobile-no-padding">
    <div class="card max-w-1300 card--desktop-only">
      <div class="card-body">
        <app-loading [@inOutAnimation] *ngIf="!chartsLoaded">{{'LOADING_CHARTS' | translate}}</app-loading>
        <div class="chart not-mobile" #chartElement id="{{chartID}}" style="height:400px;margin-top: 25px;"></div>
      </div>
    </div> 
  </div>
  
  <div class="max-w-1300">
    <div class="card " style="margin-top: 60px;">
      <div class="card-header-primary">
        <h5 class="card-title">{{"Min, Max, Priemer"|translate}}</h5>
      </div>
      <div class="card-body">
        <div class="table-responsive">
          <table class="table table-light">
            <thead class="thead-light">
              <tr>
                <th>{{ "Sensor"     |  translate}}</th>
                <th>{{ "Min value"  |  translate}}</th>
                <th>{{ "Min Date"   |  translate}}</th>
                <th>{{ "Max value"  |  translate}}</th>
                <th>{{ "Max Date"   |  translate}}</th>
                <th>{{ "Average"    |  translate}}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <!-- <td translate="meteoData.temperature" *ngIf="maxMinAvgTemp" style="color:#f44336;font-weight:500;"></td> -->
                <td class="color--temperature fw500" translate="meteoData.temperature" *ngIf="maxMinAvgTemp"></td>
                <td class="table-value">{{ maxMinAvgTemp.min.value | number:"1.0-1" }} °C </td>
                <td>{{ maxMinAvgTemp.min.date | date: 'dd.M.yyyy, H:mm':undefined:globalService.lang }}</td>
                <td class="table-value">{{ maxMinAvgTemp.max.value | number:"1.0-1" }} °C </td>
                <td>{{ maxMinAvgTemp.max.date | date: 'dd.M.yyyy, H:mm':undefined:globalService.lang }}</td>
                <td class="table-value">{{ maxMinAvgTemp.avg | number:"1.0-1" }} °C</td>
              </tr>
              <tr>
                <!-- <td translate="meteoData.humidity" *ngIf="maxMinAvgHumid" style="color:#9cae2a;font-weight:500;"></td> -->
                <td class="color--humidity fw500" translate="meteoData.humidity" *ngIf="maxMinAvgHumid"></td>
                <td class="table-value">{{maxMinAvgHumid.min.value | number:"1.0-0"}} % </td>
                <td>{{maxMinAvgHumid.min.date | date: 'dd.M.yyyy, H:mm':undefined:globalService.lang }}</td>
                <td class="table-value">{{maxMinAvgHumid.max.value | number:"1.0-0"}} % </td>
                <td>{{maxMinAvgHumid.max.date | date: 'dd.M.yyyy, H:mm':undefined:globalService.lang }}</td>
                <td class="table-value">{{maxMinAvgHumid.avg | number:"1.0-0"}} %</td>
              </tr>
              <tr>
                <td class="color--pressure fw500" translate="meteoData.light" *ngIf="maxMinAvgLux"></td>
                <td></td>
                <td></td>
                <!-- <td class="table-value" *ngIf="maxMinAvgLux.min.value">{{maxMinAvgLux.min.value | number:"1.0-1"}} W/m²</td>
                                                  <td>{{maxMinAvgLux.min.date | date: 'dd.M.yyyy, H:mm':undefined:globalService.lang }}</td> -->
                <td class="table-value">{{maxMinAvgLux.max.value | number:"1.0-1"}} W/m² </td>
                <td>{{maxMinAvgLux.max.date | date: 'dd.M.yyyy, H:mm':undefined:globalService.lang }}</td>
                  <!-- <td></td> -->
                  <td></td>
                  <!-- <td class="table-value">{{maxMinAvgLux.avg | number:"1.0-1"}} W/m²</td> -->
              </tr>
              <tr>
                <td class="color--rain fw500">
                  {{"CHART_rain" | translate}}:
                </td>
                <td class="table-value">{{rainSUM | number:"1.0-1"}} mm</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <app-rain-chart chartID="chart2" rainYearNum="{{rainYear}}" [vineyard]="vineyard" chartSince="chartSince" [@inOutAnimation]></app-rain-chart>
  <div class="max-w-1300" style="margin-top:60px;">
    <div class="card">
      <div class="card-header  card-header-tabs card-header-primary">
        <div class="nav-tabs-navigation">
          <div class="nav-tabs-wrapper">
            <h5 class="card-title"><span class="nav-tabs-title justify-content-start">{{"Meteo dáta"|translate}}</span></h5>
            <ul class="nav nav-tabs justify-content-end desktop-only" data-tabs="tabs">
    
              <li class="nav-item" (click)="PDF()">
                <a class="nav-link" data-toggle="tab" href="#profile">
                  <mat-icon class="mat-24">picture_as_pdf</mat-icon> <span class="export-label">{{"Export as" | translate}} PDF</span>
                  <div class="ripple-container"></div>
                </a>
              </li>
              <li class="nav-item" (click)="exportAsXLSX()">
                <a class="nav-link" data-toggle="tab" href="#profile">
                  <mat-icon class="mat-24">table_chart</mat-icon> <span class="export-label">{{"Export as" | translate}} XLSX (Excel)</span>
                  <div class="ripple-container"></div>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="card-body mat-table card--desktop-only"> 
        <div class="example-container">
          <mat-table [dataSource]="dataSource" matSort style="width:100%;" id="print-section">
            <!-- Date Column -->
            <ng-container matColumnDef="datetime">
              <mat-header-cell *matHeaderCellDef translate="Date & Time"> </mat-header-cell>
              <mat-cell *matCellDef="let element"><span class="mobile-label" translate="Date & Time"> </span> <span class="mat-table-value">{{iOSDate(element.insert_datetime) | date: 'dd.M.yyyy, H:mm':undefined:globalService.lang }}</span>
              </mat-cell>
            </ng-container>
            <!-- Temperature Column -->
            <ng-container matColumnDef="temperature">
              <mat-header-cell *matHeaderCellDef translate="meteoData.temperature"></mat-header-cell>
              <mat-cell *matCellDef="let element"><span class="mobile-label" translate="meteoData.temperature"></span><span class="mat-table-value">{{element.temperature}} °C</span> </mat-cell>
            </ng-container>
            <!-- Lux Column -->
            <ng-container matColumnDef="lux">
              <mat-header-cell *matHeaderCellDef translate="meteoData.light"></mat-header-cell>
              <mat-cell *matCellDef="let element"><span class="mobile-label" translate="meteoData.light"></span><span class="mat-table-value">{{element.lux}} W/m²</span> </mat-cell>
            </ng-container>
            <!-- Lux Column -->
            <ng-container matColumnDef="humidity">
              <mat-header-cell *matHeaderCellDef translate="meteoData.humidity"></mat-header-cell>
              <mat-cell *matCellDef="let element"><span class="mobile-label" translate="meteoData.humidity"></span> <span class="mat-table-value">{{element.humidity}} %</span> </mat-cell>
            </ng-container>
            <!-- Rain Column -->
            <ng-container matColumnDef="rain">
              <mat-header-cell *matHeaderCellDef translate="rain"></mat-header-cell>
              <mat-cell *matCellDef="let element"><span class="mobile-label" translate="meteoData.rain"></span> <span class="mat-table-value">{{element.rain}} mm</span> </mat-cell>
            </ng-container>
            <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;">
            </mat-row>
          </mat-table>
          <mat-paginator [pageSizeOptions]="[5, 10, 20]" hidePageSize showFirstLastButtons></mat-paginator>
        </div>
      </div>
    </div>
  </div>
</div>