import { Component, OnInit, Inject } from "@angular/core";
import "rxjs/add/operator/filter";
import "rxjs/add/observable/fromPromise";
import "rxjs/add/operator/map";
import { VineyardService } from "../_services/vineyard.service";
import { WeatherService } from "../_services/weather.service";
import { GlobalService } from "../_services/global.service";
import { NotificationsService } from "../_services/notifications.service";
import { Vineyard } from "../_classes/vineyard";
import "leaflet-search";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { ViewChild } from "@angular/core";
import { format, lastDayOfWeek, getDay, differenceInWeeks } from "date-fns";
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { trigger, transition, style, animate } from "@angular/animations";
import { untilDestroyed } from "ngx-take-until-destroy";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { DialogDocGalatiChart } from "app/galati/galati.component";
import { AngularFirestore } from '@angular/fire/firestore';
import { AuthenticationService } from 'app/_services/authentication.service';
import { flatMap, map } from 'rxjs/operators';
import { AngularFireAuth } from '@angular/fire/auth';

declare var L: any;
declare var SMap: any;
@Component({
  selector: "app-vineyards",
  templateUrl: "./vineyards.component.html",
  styleUrls: ["./vineyards.component.scss"],
  animations: [
    trigger("inOutAnimation", [
      transition(":enter", [
        style({ opacity: 0 }),
        animate(".25s ease-out", style({ opacity: 1 })),
      ]),
      transition(":leave", [
        style({ opacity: 1 }),
        animate(".25s ease-in", style({ opacity: 0 })),
      ]),
    ]),
  ],
})
export class VineyardComponent implements OnInit {
  isLinear = false;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;

  @ViewChild("vineyardsList", { static: true }) stationList;
  wizard = false;
  position: any;
  selected: any;
  SMap: any;
  bootstrapWizardArgs;
  myVineyards = null;
  success = false;
  wizardWrapper;
  stationWizard = false;
  newVineyard: Vineyard;
  stationsMessage: string;
  messageForm: FormGroup;

  mapInit = false;
  map;
  map2;
  test;
  filteredVineyard = "all";
  constructor(
    private fireAuth: AngularFireAuth,
    public authService: AuthenticationService,
    private _formBuilder: FormBuilder,
    public vineyardService: VineyardService,
    public notif: NotificationsService,
    public ws: WeatherService,
    public global: GlobalService,
    public dialog: MatDialog
  ) {
    this.vineyardService.getMyVineyards();


  }

  /* Toto je Drag n Drop event pre Device Card */
  drop(event: CdkDragDrop<string[]>) {
    console.log(event);
    moveItemInArray(
      this.vineyardService.myVineyards,
      event.previousIndex,
      event.currentIndex
    );
    console.log("prev index", event.previousIndex);
    console.log("current index", event.currentIndex);
  }

  scroll(el: HTMLElement) {

    el.scrollIntoView({ behavior: 'smooth' });
  }

  ngOnInit() {
    this.firstFormGroup = this._formBuilder.group({
      firstCtrl: ["", Validators.required],
    });
    this.secondFormGroup = this._formBuilder.group({
      secondCtrl: ["", Validators.required],
    });
    this.success = false;
    const thisScope = this;
    this.newVineyard = new Vineyard();
    // if (!this.vineyardService.myVineyards) {
    //   this.vineyardService.getMyVineyards();
    // }

  }

  openDocsVideo() { 
    const dialogRef = this.dialog.open(DialogDocAddVineyard);
    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
    });
  }
  ngOnDestroy() {
    // To protect you, we'll throw an error if it doesn't exist.
  }
}

@Component({
  selector: "dialog-doc-galatiChart",
  template: `
    <h3 mat-dialog-title>Vytvorenie vinohradu / registrácia stanice</h3>
    <mat-dialog-content class="mat-typography">
      <div class="video-container">
        <iframe
          width="853"
          height="480"
          src="https://www.youtube.com/embed/L-th1dpPFHE"
          frameborder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
      <button mat-button mat-dialog-close style="margin-top:15px;">
        {{ "Close" | translate }}
      </button>
    </mat-dialog-actions>
  `,
  styles: [
    `
      .video-container {
        position: relative;
        padding-bottom: 56.25%;
        padding-top: 30px;
        height: 0;
        overflow: hidden;
      }
      .mat-dialog-container {
        max-width: none !important;
      }
      .video-container iframe,
      .video-container object,
      .video-container embed {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
      @media screen and (min-width: 900px) {
        .video-container {
          width: 853px;
        }
      }
    `,
  ],
})
export class DialogDocAddVineyard {
  constructor(public dialogRef: MatDialogRef<DialogDocAddVineyard>) { }
  onNoClick(): void {
    this.dialogRef.close();
  }
}
