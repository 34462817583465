<div class="forecast-days" *ngIf="forecast && forecast.daily">
  <div class="forecast-day" *ngFor="let day of forecast.daily">
    <div class="forecast-day__head">
      <img class="svg " style="max-width: 63px;" src="./assets/img/weather-icons-master/svg/{{ weatherServ.weatherIcon(day.weather[0].id) }}.svg" *ngIf="day.weather" alt="" [@inOutAnimation] />
      <div class="forecast-weekday">{{unixToTime(day.dt) | date: 'EEEE':undefined:locale }}</div>
      <div class="weahter-description">{{ day.weather[0].description | translate}}</div>
    </div>
    <div class="vineyard-meteo__item forecast-meteo" *ngIf="day" [ngClass]="{w100p: !vineyard.galatiSeason}" style="margin-top: 7px;">
      <div class="forecast-meteo" *ngIf="day.temp && day.temp.min">
        <div class="label" style="color:#f44336;">{{"meteoData.temperature" | translate}}</div>
        <div class="value">{{ day.temp.min | number : '1.0-0'}} - <b>{{ day.temp.max | number : '1.0-0'}}</b> °C</div>
      </div>
      <div class="vineyard-meteo__item forecast-meteo" style="margin-top: 7px;">
        <div class="label" style="color:#2a68ae;">{{"meteoData.rain" | translate}}</div>
        <!-- <div class=" value" *ngIf="day.rain">{{ day.rain  }} mm</div> -->
        <div class="value" *ngIf="day.rain">{{ day.rain | number : '1.0-1' }} mm</div>
        <!-- <div class=" value" *ngIf="day.rain">{{ day.rain | number : '1.0-0' }} mm</div> -->
        <div class="value" *ngIf="!day.rain">0 mm</div>
      </div>
    </div>
  </div>
</div>