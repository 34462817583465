<h1 mat-dialog-title>{{ "E-mail Change" | translate}}</h1>
<div mat-dialog-content>
  <p>{{"You need to enter your password to change your email address." | translate}}</p>
  <mat-form-field>
    <mat-label>{{"Password"|translate}}</mat-label>
    <input matInput type="password" [(ngModel)]="passwd" placeholder="{{'Password' | translate}}">
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">{{"Cancel"|translate}}</button>
  <button mat-button [mat-dialog-close]="passwd" cdkFocusInitial>{{"Submit"|translate}}</button>
</div>