<div class="container loading">
  <div class="tac m0a">
    <mat-spinner *ngIf="!loadingError" class="m0a" [diameter]="50"></mat-spinner>
    <h3 style="margin:50px auto;">
      <ng-content *ngIf="!loadingError"></ng-content>
      <div *ngIf="loadingError">
        <mat-icon>clear</mat-icon>{{"Loading failed" | translate}}<br>
        <h4 (click)="refresh()" style="text-decoration: underline; cursor: pointer;">{{"Try again" | translate}}?</h4>
      </div>
    </h3>
  </div>
</div>