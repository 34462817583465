
    <h3 mat-dialog-title>{{ "Infection Chart Hint" | translate }}</h3>
    <mat-dialog-content class="mat-typography">
      <img
        src="/assets/img/docs/popis-galati-grafu_{{ global.lang }}.png"
        style="max-width:800px;width: 100%;"
        alt=""
      />
    </mat-dialog-content>
    <mat-dialog-actions align="end">
      <button mat-button mat-dialog-close style="margin-top:15px;">
        {{ "Close" | translate }}
      </button>
    </mat-dialog-actions>
  